import { Controller } from "stimulus";
import updatesHistoryChart from './../charts/updates-history-chart'

export default class extends Controller {
  connect() {
    const chart = window['HistoryChart']
    const obj = new updatesHistoryChart(chart)
    obj.init()
  }
}

