import { Controller } from "stimulus";
import axios from "../packs/setupCSRFToken";

export default class extends Controller {
  static targets = ["search", "clearFilter", "submit"];

  searchByName(e) {
    e.preventDefault()

    const textToSearch = this.searchTarget.value
    if (textToSearch.length == 0) return
    this.submitTarget.disabled = true
    this.sendRequest(textToSearch, e.target.form)
    this.submitTarget.disabled = false
  }

  clearFilter(e) {
    e.preventDefault()

    this.searchTarget.value = ""
    this.sendRequest("", e.target.closest("form"))
  }

  sendRequest(textToSearch, form) {
    let params = {}
    let operationalUserUrl = form.action

    this.searchTarget.parentNode.classList.add("is-loading")

    params["search"] = textToSearch
    console.log(params)
    axios.get(operationalUserUrl, { params: params })
      .then(({ data }) => {
        console.log(data)
        let resultList = document.getElementById("operational-users-result")

        resultList.innerHTML = data.html_content

        this.searchTarget.parentNode.classList.remove("is-loading")
        this.clearFilterTarget.text = `Eliminar filtro: ${textToSearch}`
        this.clearFilterTarget.classList.toggle("hidden", textToSearch.length == 0)
      })
  }
}
