import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["input", "digitBox"];

  connect() {
    this.digitBoxes[0].focus();
  }

  handleInput(event) {
    const currentValue = event.target.value.trim();
    const currentIndex = parseInt(event.target.dataset.index);
    if (currentValue.length > 1) {
      const limit = currentValue.length + currentIndex;
      for (let i = currentIndex; i < limit; i++) {
        if (currentValue.charAt(i - currentIndex)) {
          this.digitBoxes[i].value = currentValue.charAt(i - currentIndex);
        }
      }

      let lastNonEmptyIndex = this.findLastNonEmptyIndex();
      if (lastNonEmptyIndex !== -1) {
        this.digitBoxes[lastNonEmptyIndex].focus();
      }
    } else {
      event.target.value = currentValue;
      this.focusNextDigitBox(event.target);
    }
    this.updateAuthToken();
  }

  onFocus(event) {
    const target = event.target
    
    if (target.value.length != 0) {
      target.select();
    }
  }

  handleKeyDown(event) {
    if (!this.isAllowedKey(event)) {
      event.preventDefault();
      return
    }

    if (event.key === "Backspace") {
      this.handleBackspace(event.target);
    }

    if (event.key === "ArrowLeft") {
      this.handleArrowLeft(event.target);
    }

    if (event.key === "ArrowRight") {
      this.handleArrowRight(event.target);
    }
  }

  updateAuthToken() {
    let inputValue = "";
    this.digitBoxes.forEach(box => {
      let value = box.value.trim().charAt(0);
      box.value = value; 
      inputValue += value || '';
    });
    this.inputTarget.value = inputValue;
  }

  handleBackspace(target) {
    let index = this.digitBoxes.indexOf(target);
    if (index > 0) {
      if (this.digitBoxes[index].value === "") {
        this.digitBoxes[index - 1].value = "";
        this.digitBoxes[index - 1].focus();
      }
    }
    this.updateAuthToken();
  }

  handleArrowLeft(target) {
    const index = this.digitBoxes.indexOf(target);
    let currentBox = null;
    if (0 < index) {
      currentBox = this.digitBoxes[index - 1];
    } else {
      currentBox = this.digitBoxes[0];
    }

    if (target.value.length != 0) {
      currentBox.select();
    } else {
      currentBox.focus();
    }
  }

  handleArrowRight(target) {
    let index = this.digitBoxes.indexOf(target);
    let currentBox = null;
    if (index < this.digitBoxes.length - 1) {
      currentBox = this.digitBoxes[index + 1];
    } else {
      currentBox = this.digitBoxes[this.digitBoxes.length - 1];
    }

    if (target.value.length != 0) {
      currentBox.select();
    } else {
      currentBox.focus();
    }
  }

  focusNextDigitBox(currentBox) {
    let index = this.digitBoxes.indexOf(currentBox);
    if (index < this.digitBoxes.length - 1 && currentBox.value !== "") {
      this.digitBoxes[index + 1].focus();
    }
  }

  findLastNonEmptyIndex() {
    for (let i = this.digitBoxes.length - 1; i >= 0; i--) {
      if (this.digitBoxes[i].value.trim() !== "") {
        return i;
      }
    }
    return -1;
  }

  isAllowedKey(event) {
    if (event.key === "Backspace" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return true;
    }
    return /^[a-zA-Z0-9]$/i.test(event.key);
  }

  get digitBoxes() {
    return this.digitBoxTargets;
  }
}
