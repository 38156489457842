import { Controller } from "stimulus"
import ColumnsChart from './../charts/columns-chart'

export default class extends Controller {
  connect() {
    const ColumnsCharts = document.querySelectorAll('.columns-chart')
    ColumnsCharts.forEach(function (columnsChart) {
      const id = columnsChart.getAttribute('id');
      const chart = window[id];
      var obj = new ColumnsChart(chart);
      obj.init();
    })
  }
}