import DataRow from './data-row'
import Pill from './pill'
import Tab from './tab'
import PieChart from './pie-chart'
import BarChart from './bar-chart'

export function createReportTables(initialstate) {
  const state = {
    charts: [],
    dataRows: [],
    pills: [],
    tabs: [],
    target: "",
    ...initialstate
  }

  function init() {
    // Data Rows
    const dataRows = state.target.querySelectorAll('.main-data-row')
    dataRows.forEach(function (dataRow) {
      const obj = new DataRow(dataRow)
      state.dataRows.push(obj)
    })

    // Pills
    const pills = state.target.querySelectorAll('.pill')
    pills.forEach(function (pill) {
      const obj = new Pill(pill)
      state.pills.push(obj)
    })

    // Tabs
    const tabs = state.target.querySelectorAll('#report .tabs')
    tabs.forEach(function (tab) {
      const obj = new Tab(tab)
      state.tabs.push(obj)
    })

    initBarCharts()
    initPieCharts()
  }

  function initBarCharts() {
    // Bar Charts
    const barCharts = state.target.querySelectorAll('.bar-chart')

    barCharts.forEach(function (barChart) {
      const id = barChart.getAttribute('id');
      const chart = window[id];
      const obj = new BarChart(chart);

      state.charts.push(obj)
      state.dataRows.forEach(function (dataRow) {
        if (dataRow.charts.includes(id)) {
          dataRow.addObserver(obj)
          obj.subscribe(dataRow)
        }
      })

      state.pills.forEach(function (pill) {
        if (pill.charts.includes(id)) {
          pill.addObserver(obj)
          obj.subscribe(pill)
        }
      })

      state.tabs.forEach(function (tab) {
        if (tab.charts.includes(id)) {
          tab.addObserver(obj)
          obj.subscribe(tab)
        }
      })
    })
  }

  function initPieCharts() {
    // Pie Charts
    const pieCharts = state.target.querySelectorAll('.pie-chart')
    pieCharts.forEach(function (pieChart) {
      const id = pieChart.getAttribute('id')
      const chart = window[id]
      const obj = new PieChart(chart)
      state.charts.push(obj)
      state.dataRows.forEach(function (dataRow) {
        if (dataRow.charts.includes(id)) {
          dataRow.addObserver(obj)
          obj.subscribe(dataRow)
        }
      })

      state.pills.forEach(function (pill) {
        if (pill.charts.includes(id)) {
          pill.addObserver(obj)
          obj.subscribe(pill)
        }
      })

      state.tabs.forEach(function (tab) {
        if (tab.charts.includes(id)) {
          tab.addObserver(obj)
          obj.subscribe(tab)
        }
      })
    })
  }

  return {
    init,
  }
}

