import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class CashflowChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
    this.seriesMapping = chart.seriesMapping
    this.colorMapping = chart.colorMapping
  }

  init () {
    let _this = this;
    var chart = am4core.create(this.id, am4charts.XYChart)
    chart.data = this.data
    var scrollbarX = new am4charts.XYChartScrollbar();

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 50

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.numberFormatter = new am4core.NumberFormatter()
    valueAxis.numberFormatter.numberFormat = '$#,###.##'

    this.series.forEach((serieName) => {
      const columnSerie = chart.series.push(new am4charts.ColumnSeries())
      columnSerie.dataFields.valueY = serieName
      columnSerie.dataFields.dateX = "date"

      if(this.seriesMapping) {
        columnSerie.name = this.seriesMapping[serieName];
      } else {
        columnSerie.name = serieName;
      }

      if(this.colorMapping) {
        columnSerie.columns.template.fill = am4core.color(this.colorMapping[serieName])
      }

      columnSerie.strokeWidth = 0
      columnSerie.stacked = true
      columnSerie.tooltipText = "{name}: [bold]{valueY.formatNumber('$#,###.##')}[/]";

      scrollbarX.series.push(columnSerie)
    })

    chart.scrollbarX = scrollbarX;
    chart.scrollbarY = new am4core.Scrollbar();

    var lineSeries = chart.series.push(new am4charts.LineSeries())
    lineSeries.dataFields.valueY = 'cumulative'
    lineSeries.dataFields.dateX = 'date'
    lineSeries.tooltipText = `Acumulado histórico: [bold]{valueY.formatNumber('$#,###.##')}[/]`
    lineSeries.fillOpacity = 0.5
    lineSeries.strokeWidth = 0
    lineSeries.propertyFields.stroke = "lineColor"
    lineSeries.propertyFields.fill = "lineColor"
    lineSeries.fill = "#66ccff"
    lineSeries.name = 'Acumulado'
    lineSeries.zIndex = -1

    /* Configure axis tooltip */
    var axisTooltip = dateAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#66ccff");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.label.fill = am4core.color("#000000");
    axisTooltip.dy = -30;

    /* Decorate axis tooltip content */
    dateAxis.adapter.add("getTooltipText", (text) => {
      var totalValue = 0;
      chart.series.each(function(series) {
        if (series.name !== 'Acumulado') {
          if(series.name == 'Cobrado Inmediato' || series.name == 'Cobrado Diferido' || series.name == 'Nómina externa') {
            totalValue += series.tooltipDataItem.valueY;
          } else {
            totalValue -= Math.abs(series.tooltipDataItem.valueY);
          }
        }
      });
      return `Acumulado Mensual: [bold]${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(totalValue)}[/]`;
    });

    // Make up for the top x axis renderer not actually occupying any space
    chart.topAxesContainer.paddingTop = 30;

    // Add legend
    let legend = document.getElementById('cashflow-legend');
    let total = 0;
    let seriesColor = '';
    let totals = {};
    let externalPayroll = 0;

    chart.events.on('ready', function(event) {
      let chartSeries = chart.series.values;

      chartSeries.forEach((series, index) => {
        seriesColor = series.columns ? series.columns.template.fill.hex : series.fill.hex;

        series.dataItems.each((row, i) => { if (row.dateX !== undefined) { total += row.valueY } })
        totals[series.name] = total;
        if (series.name === 'Acumulado') {
          externalPayroll = totals['Nómina externa'] || 0;
          total = totals['Cobrado Inmediato'] + totals['Cobrado Diferido'] + externalPayroll - Math.abs(totals['Nómina']) - Math.abs(totals['Pagado Diferido']) - Math.abs(totals['Pagado Inmediato']);
        }

        legend.innerHTML += `
          <div class='legend-item' id='legend-item-${index}'>
            <div class='legend-marker' style='background: ${seriesColor}'></div>
            <div class='legend-value is-flex'>
              <span>${series.name}:</span>
              <span class='has-text-weight-bold'>${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(total)}</span>
            </div>
          </div>
        `;

        total = 0;
      });
    })

    // Add cursor
    chart.cursor = new am4charts.XYCursor()

    dateAxis.events.on("startchanged", _this.dateAxisChanged);
    dateAxis.events.on("endchanged", _this.dateAxisChanged);
  }

   dateAxisChanged(e) {
    let legend = document.getElementById('cashflow-legend');
    let total = 0;
    let seriesColor = '';
    let chartSeries = e.target.series.values;
    let start = new Date(e.target.minZoomed);
    let end = new Date(e.target.maxZoomed);
    let startDate = new Date(start.getFullYear(), start.getMonth(), 1)
    let endDate = new Date(end.getFullYear(), end.getMonth(), 0)
    let totals = {};
    let externalPayroll = 0;

    legend.innerHTML = '';

    chartSeries.forEach((series, index) => {
      seriesColor = series.columns ? series.columns.template.fill.hex : series.fill.hex;
      series.dataItems.each((row, i) => {
        if (row.dateX !== undefined && new Date(row.dateX) >= startDate && new Date(row.dateX) <= endDate) {
          total += row.valueY
        }
      });

      totals[series.name] = total;
      if (series.name === 'Acumulado') {
        externalPayroll = totals['Nómina externa'] || 0;
        total = totals['Cobrado Inmediato'] + totals['Cobrado Diferido'] + externalPayroll - Math.abs(totals['Nómina']) - Math.abs(totals['Pagado Diferido']) - Math.abs(totals['Pagado Inmediato']);
      }

      legend.innerHTML += `
        <div class='legend-item' id='legend-item-${index}'>
          <div class='legend-marker' style='background: ${seriesColor}'></div>
          <div class='legend-value is-flex'>
            <span>${series.name}:</span>
            <span class='has-text-weight-bold'>${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(total)}</span>
          </div>
        </div>
      `;

      total = 0;
    });
  }

}

export default CashflowChart;
