import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class CreditRepairProvidersChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data
    var scrollbarX = new am4charts.XYChartScrollbar();

    const monthsAxis = this.chart.xAxes.push(new am4charts.DateAxis())
    monthsAxis.renderer.minGridDistance = 50

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.extraMin = 0.1;

    this.series.forEach((serie) => {
      const paymentSeries = this.chart.series.push(new am4charts.ColumnSeries());
      paymentSeries.dataFields.valueY = serie
      paymentSeries.dataFields.dateX = 'date'
      paymentSeries.name = serie
      paymentSeries.tooltipText = "{name}: {valueY.formatNumber('$#,###.##')}"

      // Add scrollbar
      scrollbarX.series.push(paymentSeries)
    })


    this.chart.scrollbarX = scrollbarX;

    // Add legend
    this.chart.legend = new am4charts.Legend()

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }
}

export default CreditRepairProvidersChart
