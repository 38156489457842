import { Controller } from "stimulus"

export default class extends Controller {
  selectRange(e) {
    const changeEvent = new Event("change");
    let $dateRange = e.target.parentElement.previousElementSibling;

    document.querySelectorAll("[data-date-range-label]").forEach((label) => { label.classList.remove("selected") });
    e.target.classList.add("selected");

    $dateRange.value = e.target.dataset.rangeValue;
    $dateRange.dispatchEvent(changeEvent);
  }
}