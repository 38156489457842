import { Controller } from "stimulus";

export default class extends Controller {
  
  static targets = ["credentialsModal"]

  connect(){
    const modal = document.querySelector("[data-required-show]")
    if(modal != undefined) this.showCredentialsModal()
  }

  showCredentialsModal(){
    this.credentialsModalTarget.classList.toggle('is-active')

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === "class") {
          const classList = mutation.target.classList;
          if (!classList.contains("is-active")) {
           this.clearParams()
          }
        }
      }
    });

    observer.observe(this.credentialsModalTarget, { attributes: true });
  }

  clearParams(){
    const currentURL = new URL(window.location.href);
    currentURL.search = "";
    window.history.replaceState({}, document.title, currentURL.href);
  }
}