import { Controller } from "stimulus"
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';

export default class extends Controller {
  static targets = ["clientRfc", "selectExternalServices"]
  legalEntityLength = 12
  individualEntityLength = 13

  connect() {
    this.bindSelector()
  }

  disconnect() {
    this.clearDropdown()
  }

  removeSelectMargin(element){
    const classArray = element.classList

    classArray.forEach(classunit => {
      if(classunit.startsWith('mb-')){
        element.classList.remove(classunit)
      }
    })
  }

  bindSelector() { 
    if (!this.hasSelectExternalServicesTarget) return 
     
    const serviceInput = this.selectExternalServicesTarget

    this.tagInput = new BulmaTagsInput(serviceInput, { noResultsLabel: "Debes ingresar un RFC" })
    this.bulmaTag = this.tagInput
    
    this.tagInput.dropdown.classList.add('py-0')
    this.tagInput.dropdown.setAttribute('id', 'dropdown-services')

    let _this = this 
    const externalsDropdownfield = this.tagInput.dropdown.closest('.field')

    externalsDropdownfield.addEventListener('focusout', function(event) {
      _this.removeSelectMargin(externalsDropdownfield)
      _this.tagInput.dropdown.closest('.tags-input').classList.remove('is-active')
    })
    
    this.tagInput.dropdown.addEventListener('mousedown', function(event) {
      if(event.target.matches('a.dropdown-item')){
        event.target.click()
        _this.tagInput.dropdown.closest('.tags-input').classList.remove('is-active')
        _this.removeSelectMargin(externalsDropdownfield)
      }
    })

    externalsDropdownfield.addEventListener('click', function(event) {
      if(event.target.matches('div.delete')){
        _this.tagInput.dropdown.closest('.tags-input').classList.remove('is-active')
        _this.removeSelectMargin(externalsDropdownfield)
      }
    })
    

    this.tagInput.on('after.add', function(data) {
      
      if (!_this.isIndividualRfc()) return

      if (data.item.value == 'bureau_pf') {
        _this.tagInput._emptyDropdown()
        const individualOptions = _this.selectExternalServicesTarget.dataset.individual
        _this.createDropdownItems(individualOptions, "bureau_pm")
      }
      
      if (data.item.value == 'bureau_pm') {
        _this.tagInput._emptyDropdown()
        const individualOptions = _this.selectExternalServicesTarget.dataset.individual
        _this.createDropdownItems(individualOptions, "bureau_pf")
      }
    });

    this.tagInput.on('before.remove', function(_data) {
      if (!_this.isIndividualRfc()) return true

      _this.tagInput._emptyDropdown()
      const individualOptions = _this.selectExternalServicesTarget.dataset.individual
      _this.createDropdownItems(individualOptions, null)

      return true
    })
  }

  isIndividualRfc() {
    if (this.getRfcLength() == this.individualEntityLength ) {
      return true
    } 
    return false
  }

  getRfcLength() {
    if (this.hasClientRfcTarget && this.clientRfcTarget.value && this.clientRfcTarget.value.length) {
      return this.clientRfcTarget.value.length
    }

    if (this.hasClientRfcTarget && this.clientRfcTarget.innerText && this.clientRfcTarget.innerText.length) {
      return this.clientRfcTarget.innerText.length
    }
  }
  
  inputRFC(e){
    const value = e.target.value

    this.clearDropdown()

    if (!this.hasSelectExternalServicesTarget) return

    if (value.length == 12){
      const legalOptions = this.selectExternalServicesTarget.dataset.legal
      this.createDropdownItems(legalOptions, null)
    }
    
    if (value.length ==  13) {
      const individualOptions = this.selectExternalServicesTarget.dataset.individual
      this.createDropdownItems(individualOptions, null)
    }
  }

  clearDropdown() {
    if (!this.tagInput) return
    
    this.tagInput.removeAll()
    this.tagInput._emptyDropdown()
    this.selectExternalServicesTarget.setAttribute('disabled', '')
  }

  createDropdownItems(options, except) {
    this.selectExternalServicesTarget.removeAttribute('disabled')
    JSON.parse(options).forEach(element => {
      if (element.service_key == except) return

      this.tagInput._createDropdownItem({
        text: element.name,
        value: element.service_key
      })
    });
  }

  enableService(e){
    const target = e.currentTarget
    const checked = target.checked

    target.form.submitBtn.toggleAttribute('disabled')
  }

  externalServicesInputfocused(e){
    const dropdown = e.target.parentElement.querySelector(':first-child.dropdown-content')
    const childElements = dropdown.querySelectorAll(':not([style*="display: none"])')
    const marginClass = `mb-${(childElements.length*2)}`

    if(e.target.parentElement.classList.contains('is-focused')){
      e.target.closest('.field').classList.add(marginClass)
    }
  }
}

