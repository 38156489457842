import { Controller } from "stimulus";
import axios from "../packs/setupCSRFToken";

export default class extends Controller {
  connect() {
    this.callToAction()
    this.showTargetsOnLoad()
  }

  disconnect() {
    document.documentElement.style.overflowY = ''
  }

  callToAction(){
    const purchaseAction = this.data.get("purchase")

    if(purchaseAction && purchaseAction != ""){
      const purchaseElement = document.getElementById(purchaseAction)

      if(purchaseElement){
        purchaseElement.click()
      }
    }
  }

  open(e) {
    e.preventDefault()
    const target = e.target
    const sidebarId = target.dataset.sidebarId
    const actionPath = target.dataset.actionPath
    const sidebarElement = document.getElementById(sidebarId)
    const sidebarBackground = document.getElementById(`${sidebarId}-background`)

    if (!sidebarElement && !sidebarBackground) return;

    document.documentElement.style.overflowY = 'hidden'
    sidebarElement.classList.add("is-active")
    if(!e.target.hasAttribute('data-hide-background')) sidebarBackground.classList.add("is-active")
    
    this.fetchPage(actionPath, sidebarId)

    this.eventClose = sidebarBackground.addEventListener("click", (e)=>{
      if(e.target == sidebarBackground){
        this.close(e)
      }
    })
  }


  close(e) {
    e.preventDefault()
    const sidebarId = e.currentTarget.dataset.sidebarId
    const sidebarElement = document.getElementById(sidebarId)
    const sidebarBackground = document.getElementById(`${sidebarId}-background`)
 
    if (!sidebarElement && !sidebarBackground) return;
    document.documentElement.style.overflowY = ''
    sidebarElement.classList.remove("is-active")
    sidebarBackground.classList.remove("is-active")
    sidebarBackground.removeEventListener("click", this.eventClose)
  }

  fetchPage(actionPath, sidebarId) {
    const sidebarWrapper = document.getElementById(`${sidebarId}-wrapper`)
    
    axios.get(actionPath)
    .then(({ data }) => {
      sidebarWrapper.innerHTML = data.html_content
    }).catch(error=>{
      console.log(error)
    })

  }

  showTargetsOnLoad(){
    document.querySelectorAll("[data-open-on-load]").forEach(element => {
      if(element.dataset.openOnLoad == 'true'){
        element.click()
      }
    });
  }
}
