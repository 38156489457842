
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";

class TimelineChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.icon = chart.icon
  }

  init () {
    var dollar = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMi4xIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIyIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMTYwIDBjMTcuNyAwIDMyIDE0LjMgMzIgMzJWNjcuN2MxLjYgLjIgMy4xIC40IDQuNyAuN2MxMC42IDEuNiA0Mi4xIDYuNyA1NS4xIDEwYzE3LjEgNC4zIDI3LjUgMjEuNyAyMy4yIDM4LjlzLTIxLjcgMjcuNS0zOC45IDIzLjJjLTkuMy0yLjQtMzcuNi03LTQ4LjktOC43Yy0zMi4xLTQuOC01OS42LTIuNC03OC41IDQuOWMtMTguMyA3LTI1LjkgMTYuOS0yNy45IDI4Yy0xLjkgMTAuNy0uNSAxNi44IDEuMyAyMC42YzEuOSA0IDUuNiA4LjUgMTIuOSAxMy40YzE2LjIgMTAuOCA0MS4xIDE3LjkgNzMuMyAyNi43bDIuOCAuOGMyOC40IDcuNyA2My4yIDE3LjIgODkgMzQuM2MxNC4xIDkuNCAyNy4zIDIyLjEgMzUuNSAzOS43YzguMyAxNy44IDEwLjEgMzcuOCA2LjMgNTkuMWMtNi45IDM4LTMzLjEgNjMuNC02NS42IDc2LjdjLTEzLjcgNS42LTI4LjYgOS4yLTQ0LjQgMTFWNDgwYzAgMTcuNy0xNC4zIDMyLTMyIDMycy0zMi0xNC4zLTMyLTMyVjQ0NS4xYy0uNC0uMS0uOS0uMS0xLjMtLjJsLS4yIDAgMCAwYy0yNC40LTMuOC02NC41LTE0LjMtOTEuNS0yNi4zYy0xNi4yLTcuMi0yMy40LTI2LjEtMTYuMi00Mi4yczI2LjEtMjMuNCA0Mi4yLTE2LjJjMjAuOSA5LjMgNTUuMyAxOC40IDc1LjIgMjEuNmMzMS45IDQuNyA1OC4yIDIgNzYtNS4zYzE2LjktNi45IDI0LjYtMTYuOSAyNi44LTI4LjljMS45LTEwLjcgLjUtMTYuOC0xLjMtMjAuNmMtMS45LTQtNS42LTguNS0xMi45LTEzLjRjLTE2LjItMTAuOC00MS4xLTE3LjktNzMuMy0yNi43bC0yLjgtLjhjLTI4LjQtNy43LTYzLjItMTcuMi04OS0zNC4zYy0xNC4xLTkuNC0yNy4zLTIyLjEtMzUuNS0zOS43Yy04LjMtMTcuOC0xMC4xLTM3LjgtNi4zLTU5LjFDMjUgMTE0LjEgNTMgODkuMyA4NiA3Ni43YzEzLTUgMjcuMi04LjIgNDItMTBWMzJjMC0xNy43IDE0LjMtMzIgMzItMzJ6Ii8+PC9zdmc+"
    var search = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNNDE2IDIwOGMwIDQ1LjktMTQuOSA4OC4zLTQwIDEyMi43TDUwMi42IDQ1Ny40YzEyLjUgMTIuNSAxMi41IDMyLjggMCA0NS4zcy0zMi44IDEyLjUtNDUuMyAwTDMzMC43IDM3NmMtMzQuNCAyNS4yLTc2LjggNDAtMTIyLjcgNDBDOTMuMSA0MTYgMCAzMjIuOSAwIDIwOFM5My4xIDAgMjA4IDBTNDE2IDkzLjEgNDE2IDIwOHpNMjA4IDM1MmExNDQgMTQ0IDAgMSAwIDAtMjg4IDE0NCAxNDQgMCAxIDAgMCAyODh6Ii8+PC9zdmc+"
    var chart = am4core.create(this.id, am4plugins_timeline.CurveChart);
    chart.logo.disabled = true

    try {
      var dollar = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMi4xIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIyIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMTYwIDBjMTcuNyAwIDMyIDE0LjMgMzIgMzJWNjcuN2MxLjYgLjIgMy4xIC40IDQuNyAuN2MxMC42IDEuNiA0Mi4xIDYuNyA1NS4xIDEwYzE3LjEgNC4zIDI3LjUgMjEuNyAyMy4yIDM4LjlzLTIxLjcgMjcuNS0zOC45IDIzLjJjLTkuMy0yLjQtMzcuNi03LTQ4LjktOC43Yy0zMi4xLTQuOC01OS42LTIuNC03OC41IDQuOWMtMTguMyA3LTI1LjkgMTYuOS0yNy45IDI4Yy0xLjkgMTAuNy0uNSAxNi44IDEuMyAyMC42YzEuOSA0IDUuNiA4LjUgMTIuOSAxMy40YzE2LjIgMTAuOCA0MS4xIDE3LjkgNzMuMyAyNi43bDIuOCAuOGMyOC40IDcuNyA2My4yIDE3LjIgODkgMzQuM2MxNC4xIDkuNCAyNy4zIDIyLjEgMzUuNSAzOS43YzguMyAxNy44IDEwLjEgMzcuOCA2LjMgNTkuMWMtNi45IDM4LTMzLjEgNjMuNC02NS42IDc2LjdjLTEzLjcgNS42LTI4LjYgOS4yLTQ0LjQgMTFWNDgwYzAgMTcuNy0xNC4zIDMyLTMyIDMycy0zMi0xNC4zLTMyLTMyVjQ0NS4xYy0uNC0uMS0uOS0uMS0xLjMtLjJsLS4yIDAgMCAwYy0yNC40LTMuOC02NC41LTE0LjMtOTEuNS0yNi4zYy0xNi4yLTcuMi0yMy40LTI2LjEtMTYuMi00Mi4yczI2LjEtMjMuNCA0Mi4yLTE2LjJjMjAuOSA5LjMgNTUuMyAxOC40IDc1LjIgMjEuNmMzMS45IDQuNyA1OC4yIDIgNzYtNS4zYzE2LjktNi45IDI0LjYtMTYuOSAyNi44LTI4LjljMS45LTEwLjcgLjUtMTYuOC0xLjMtMjAuNmMtMS45LTQtNS42LTguNS0xMi45LTEzLjRjLTE2LjItMTAuOC00MS4xLTE3LjktNzMuMy0yNi43bC0yLjgtLjhjLTI4LjQtNy43LTYzLjItMTcuMi04OS0zNC4zYy0xNC4xLTkuNC0yNy4zLTIyLjEtMzUuNS0zOS43Yy04LjMtMTcuOC0xMC4xLTM3LjgtNi4zLTU5LjFDMjUgMTE0LjEgNTMgODkuMyA4NiA3Ni43YzEzLTUgMjcuMi04LjIgNDItMTBWMzJjMC0xNy43IDE0LjMtMzIgMzItMzJ6Ii8+PC9zdmc+"
      let data =  this.data;
      const startDate = new Date(data[0].start)
      const endDate = new Date(data.slice(-1)[0].start)

      chart.maskBullets = false;
      data.forEach((e) => {
        e.color = am4core.color(e.color)
        e.icon = this.icon == 'search' ? search : dollar
      })


      var colorSet = new am4core.ColorSet();

      chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";
      chart.dateFormatter.dateFormat = "HH";
      chart.data = data;

      chart.fontSize = 10;

      var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.paddingRight = 25;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.innerRadius = 10;
      categoryAxis.renderer.radius = 30;

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = { count: 5, timeUnit: "minute" };
      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.renderer.line.strokeDasharray = "1,4";
      dateAxis.renderer.line.strokeOpacity = 0.5;
      dateAxis.tooltip.background.fillOpacity = 0.2;
      dateAxis.tooltip.background.cornerRadius = 5;
      dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
      dateAxis.tooltip.label.paddingTop = 7;
      dateAxis.endLocation = 0;
      dateAxis.startLocation = -0.5;
      dateAxis.min = new Date(startDate.getFullYear() - 1, 0, 1, 23, 59).getTime();
      dateAxis.max = new Date(endDate.getFullYear() + 1, 11, 31, 23, 59).getTime();

      var series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
      series.columns.template.height = am4core.percent(30);

      series.dataFields.openDateX = "start";
      series.dataFields.dateX = "end";
      series.dataFields.categoryY = "category";
      series.baseAxis = categoryAxis;
      series.columns.template.propertyFields.fill = "color"; // get color from data
      series.columns.template.propertyFields.stroke = colorSet.getIndex(1);
      series.columns.template.strokeOpacity = 0;
      series.columns.template.fillOpacity = 0.6;

      var imageBullet1 = series.bullets.push(new am4plugins_bullets.PinBullet());
      imageBullet1.background.radius = 10;
      imageBullet1.locationX = 1;
      imageBullet1.propertyFields.stroke = "color";
      imageBullet1.background.propertyFields.fill = "color";
      imageBullet1.image = new am4core.Image();
      imageBullet1.image.propertyFields.href = "icon";
      imageBullet1.image.scale = 0.5;
      imageBullet1.circle.radius = am4core.percent(100);
      imageBullet1.background.fillOpacity = 0.8;
      imageBullet1.background.strokeOpacity = 0;
      imageBullet1.dy = -2;
      imageBullet1.background.pointerBaseWidth = 8;
      imageBullet1.background.pointe

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.align = "center"
      chart.scrollbarX.width = am4core.percent(85);
    } catch(error) {
      console.error('Credit Bureau timeline chart error: ', error);
    }
  }
}

export default TimelineChart;