import { Controller } from "stimulus"
import TopFivePieChart from '../charts/top-five-pie-chart'

export default class extends Controller {
  connect() {
    const TopFivePieCharts = document.querySelectorAll('.customers-pie-chart')
    TopFivePieCharts.forEach(function (topFivePieChart) {
      const id = topFivePieChart.getAttribute('id')
      if(!window[`${id}_obj`]) {
        const chart = window[id]
        var obj = new TopFivePieChart(chart)
        window[`${id}_obj`] = obj;
        obj.init()
      }
    })
  }
}