import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'termsCheck', 
    'termsForm',
    'contactCheck', 
    'contactForm', 
    'termsUrl',
    'privacyUrl',
    'contactEmail',
    'contactPhone',
];

  connect() {
    this.toggleTermsForm();
    this.toggleContactForm();
  }

  toggleTermsForm() {
    if(this.termsCheckTarget.checked){
      this.termsFormTarget.classList.remove('is-hidden')
    } else {
      this.termsUrlTarget.value = '';
      this.privacyUrlTarget.value = '';
      this.termsFormTarget.classList.add('is-hidden')
    }
  }

  toggleContactForm() {
    if(this.contactCheckTarget.checked){
      this.contactFormTarget.classList.remove('is-hidden')
    } else {
      this.contactEmailTarget.value = '';
      this.contactPhoneTarget.value = '';
      this.contactFormTarget.classList.add('is-hidden')
    }
  }

  submit(event){
    event.preventDefault();
    this.resetValidation();
    
    if(this.validInputs()){
      Rails.fire(event.target.form, 'submit');
    }
  }

  resetValidation(){
    document.querySelectorAll('p.help.is-danger').forEach(element => element.remove());
  }

  validInputs(){
    return this.validateInput(this.termsCheckTarget, this.termsUrlTarget) &&
    this.validateInput(this.termsCheckTarget, this.privacyUrlTarget) &&
    this.validateInput(this.contactCheckTarget, this.contactPhoneTarget) &&
    this.validateInput(this.contactCheckTarget, this.contactEmailTarget);
  }

  validateInput(checkTarget, inputTarget){
    inputTarget.classList.remove('is-danger');

    if(checkTarget.checked && !inputTarget.value){
      inputTarget.classList.add('is-danger');
      return false;
    }
    return true;
  }
}