
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["showFullList"];j

  showIncomeTableByRange(e) {
    const dateRange = document.querySelector(`[data-range-value='${e.target.value}']`).dataset.dateRange;
    const section = e.target.closest('.card-body').dataset.section;
    const incomeData = document.querySelector(`[data-for='${section}'] tbody[data-date-range='${dateRange}']`);
    const pieDataset = JSON.parse(document.querySelector(`[data-for='${section}'] #${dateRange}`).value);
    const chartContainer = document.querySelector(`[data-for='${section}'] [data-chart='customers-chart-container']`);
    const chartElement = chartContainer.querySelector(".customers-pie-chart");
    const noDataMsg = document.querySelectorAll(`[data-for='${section}'] tbody[data-date-range="${dateRange}"] [data-empty-report]`);

    (document.querySelectorAll(`[data-for='${section}'] tbody[data-date-range]`) || []).forEach(element => {
      element.classList.add("is-hidden");
    });
    e.target.parentElement.querySelectorAll("[data-date-range-label]").forEach((label) => { label.classList.remove("selected") });
    e.target.parentElement.querySelector(`[data-range-value="${e.target.value}"]`).classList.add("selected");

    incomeData.classList.remove("is-hidden");
    window[`${chartElement.id}_obj`].chart.data = pieDataset;

    this.handleShowFullListLink(noDataMsg.length == 1, dateRange)
  }

  handleShowFullListLink(hideLink, dateRange) {
    const linkLocation = this.showFullListTarget.href.substring(0, this.showFullListTarget.href.lastIndexOf("/"));
    this.showFullListTarget.href = `${linkLocation}/${dateRange}`;
    this.showFullListTarget.classList.toggle("is-hidden", hideLink);
  }
}
