import { Controller } from "stimulus";
export default class extends Controller {
  CopyToClipboard(e){     
    let object = e.target.closest('a')
    let data = object.getAttribute('data-to-copy')
    let input = document.createElement('input')
    input.setAttribute('value', data)
  
    object.parentElement.appendChild(input)
    input.select()
    
    if(document.execCommand("copy")){
        input.remove()
        document.querySelector("#messages_copy").classList.remove("is-invisible")
        setTimeout(() => {document.querySelector("#messages_copy").classList.add("is-invisible")}, 2000);
    }
  }
}
