import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class SeasonalityChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.years = chart.years
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data
    this.chart.fontSize = 11
    this.chart.padding(30, 0, 30, 0)

    // create category axis for months
    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'month'
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 1
    categoryAxis.renderer.grid.template.disabled = true

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.grid.template.disabled = true

    const accumulatedAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    accumulatedAxis.renderer.opposite = true
    accumulatedAxis.renderer.grid.template.disabled = true
    accumulatedAxis.min = 0;
    accumulatedAxis.max = 2;

    const colors = new am4core.ColorSet()
    const self = this
    this.years.forEach(function (year) {
      const series = self.chart.series.push(new am4charts.LineSeries())
      series.fillOpacity = 0.1
      series.fill = colors.next()
      series.dataFields.valueY = year
      series.dataFields.categoryX = 'month'
      series.name = year.toString()
      series.yAxis = valueAxis
      series.strokeWidth = 0.5
      series.tooltipText = `${year}: {valueY}`
    })

    const series = this.chart.series.push(new am4charts.LineSeries())
    series.dataFields.valueY = 'accumulated'
    series.dataFields.categoryX = 'month'
    series.strokeWidth = 2
    series.strokeDasharray = 6

    series.tooltipText = 'Índice: {valueY}'
    series.name = 'Índice'
    series.yAxis = accumulatedAxis

    // Add legend
    this.chart.legend = new am4charts.Legend()

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }
}

export default SeasonalityChart
