import { Controller } from "stimulus"
import DsoDpoAmountChart from './../charts/dso-dpo-amount-chart'
import DsoDpoTimeChart from './../charts/dso-dpo-time-chart'

export default class extends Controller {

  connect() {
    this.init();
  }

  async init(){
    await this.initTimeCharts();
    await this.initAmountCharts();
    this.tabsWithContent();
  }

  tabsWithContent() {

    const paneTabs = document.querySelectorAll('.tabs-with-content')
    paneTabs.forEach(function(pane) {

      let tabs = pane.querySelectorAll('.panel-tabs li');
      let tabsContent = pane.querySelectorAll('.tab-content');
    
      let deactvateAllTabs = function () {
        tabs.forEach(function (tab) {
          tab.firstChild.classList.remove('is-active');
        });
      };
    
      let hideTabsContent = function () {
        tabsContent.forEach(function (tabContent) {
          tabContent.classList.remove('is-active');
        });
      };
    
      let activateTabsContent = function (tab) {
        tabsContent[getIndex(tab)].classList.add('is-active');
      };
    
      let getIndex = function (el) {
        return [...el.parentElement.children].indexOf(el);
      };
    
      tabs.forEach(function (tab) {
        tab.addEventListener('click', function () {
          deactvateAllTabs();
          hideTabsContent();
          tab.firstChild.classList.add('is-active');
          activateTabsContent(tab);
        });
      })

      tabs[0].click();

    });
  }

  initAmountCharts() {
    return new Promise( (resolve, reject) => {
      const LineAmountCharts = document.querySelectorAll('.line-amount-charts')
      LineAmountCharts.forEach(function(chartDpo) {
        const id = chartDpo.getAttribute('id')
        const chart = window[id]
        var obj = new DsoDpoAmountChart(chart)
        resolve(obj.init());
      });
    }); 
  }

  initTimeCharts() {
    return new Promise( (resolve, reject) => {
    const LineTimeCharts = document.querySelectorAll('.line-time-charts')
      LineTimeCharts.forEach(function(chartDpo) {
        const id = chartDpo.getAttribute('id')
        const chart = window[id]
        var obj = new DsoDpoTimeChart(chart)
        resolve(obj.init())
      })
    });
  }
}