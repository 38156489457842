import { Controller } from 'stimulus';
import GaugeChart from '../charts/gauge-chart';
import LiquityChart from '../charts/liquity-chart';


export default class extends Controller {
  static targets = ["form", "container", "selectedYear"]

  connect() {
    this.initCharts()
  }

  modalHelp(e){
    const modalInfo = document.getElementById("modal-info")
    const title = e.currentTarget.getAttribute("data-title");
    const content = e.currentTarget.getAttribute("data-content");
    const titleElement = modalInfo.querySelector("#modal-info-title")
    const contentElement = modalInfo.querySelector("#modal-info-content")

    titleElement.innerHTML = title;
    contentElement.innerHTML = content;

    modalInfo.classList.add("is-active")
  }

  async initCharts(){
    await this.initGaugeCharts();
    await this.initLiquityCharts();
  }

  initGaugeCharts() {
    return new Promise( (resolve, reject) => {
      const GaugeCharts = document.querySelectorAll('.gauge-charts')
      GaugeCharts.forEach(function(chartGauge) {

        const chartData = JSON.parse(chartGauge.dataset.chart);

        const chart = {
          id: chartGauge.getAttribute('id'),
          data: chartData
        }
        var obj = new GaugeChart(chart);
        resolve(obj.init());
      });
    });
  }

  initLiquityCharts() {
    return new Promise( (resolve, reject) => {
      const LiquityCharts = document.querySelectorAll('.liquity-charts')
      LiquityCharts.forEach(function(liquityChart) {

        const chartData = JSON.parse(liquityChart.dataset.chart);

        const chart = {
          id: liquityChart.getAttribute('id'),
          data: chartData,
          currentYear: liquityChart.dataset.selectedYear,
          firstYear: liquityChart.dataset.firstYear,
          secondYear: liquityChart.dataset.secondYear,
          thirdYear: liquityChart.dataset.thirdYear,
          fourthYear: liquityChart.dataset.fourthYear
        }
        var obj = new LiquityChart(chart);
        resolve(obj.init());
      });
    });
  }
}