import { Controller } from "stimulus";
import axios from 'axios';

export default class ReportForm extends Controller {
  static targets = ["tof", "submit", "search", "skipSubmit"]

  connect() {
  }

  submit(e) {
    e.preventDefault()
    const container = document.getElementById("report-by-rfc-ciec")
    let errors = container.querySelectorAll(".help.is-danger");
    let rfcInput = document.getElementById("report_form_rfc")
    let ciecInput = document.getElementById("report_form_ciec")

    if (ciecInput == null) ciecInput = document.getElementById("external_report_update_form_ciec")
    if (rfcInput == null) rfcInput = document.getElementById("external_report_update_form_rfc")
    e.target.classList.add("is-loading")

    if(errors.length || rfcInput.value.trim().length == 0 || ciecInput.value.trim().length == 0) {
      e.stopPropagation();
      if (rfcInput) {
        rfcInput.focus();
        rfcInput.blur();
      }

      if (ciecInput) {
        ciecInput.focus();
        ciecInput.blur();
      }

      if (rfcInput) rfcInput.focus();
      e.target.classList.remove("is-loading")
      return;
    }

    const step = e.target.dataset.step;
    let $currentStep = document.querySelector("[data-current-step")

    if(this.tofTarget.checked == false) {
      this.termsAndConditionsAlertTarget.classList.remove("is-hidden")
      return
    }

    if(step !== "client_credentials_step" && step !== "client_ciec_step") {
      this.sendForm() // NOTA*
    }

    $currentStep.value = "processing_step";

    this.sendForm()
  }

  termsOFServiceSelected(e) {
    if(e.target.checked) {
      this.validateRfcAndCiecPresence()
    } else {
      this.submitTarget.disabled = true
    }
  }

  validateRfcAndCiecPresence() {
    const rfcInput = document.getElementById("report_form_rfc");
    const ciecInput = document.getElementById("report_form_ciec");
    const container = document.getElementById("report-by-rfc-ciec")

    if(rfcInput) {
      rfcInput.focus();
      rfcInput.blur();
    }

    if (ciecInput) {
      ciecInput.focus();
      ciecInput.blur();
    }

    let errors = container.querySelectorAll("input.is-danger");
    if (errors.length > 0) {
      errors[0].focus()
      this.tofTarget.checked = false
      this.submitTarget.setAttribute("disabled", "disabled")
    } else {
      this.submitTarget.removeAttribute("disabled", false)
    }
  }

  legalRfcInput(e) {
    const target = e.target
    let errorElement = target.parentElement.parentElement.lastElementChild
    let rfcInput = document.getElementById("report_form_rfc")
    const rfcRegex = /(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})/

    if (target.value.length <= 0){
      target.classList.add("is-danger")
      errorElement.classList.add("help", "is-danger")
      errorElement.textContent = "No puede estar vacío"
      return
    }

    if(!rfcRegex.test(target.value)) {
      target.classList.add("is-danger")
      errorElement.classList.add("help", "is-danger")
      errorElement.textContent = "formato de RFC no válido"
      return;
    }

    if(target.value == rfcInput.value) {
      target.classList.add("is-danger")
      errorElement.classList.add("help", "is-danger")
      errorElement.textContent = "El RFC del representante legal no puede ser el mismo"
      return;
    }

    target.classList.remove("is-danger")
    errorElement.classList.remove("help", "is-danger")
    errorElement.textContent = ""
  }

  legalInput(e) {
    const target = e.target
    let errorElement = target.parentElement.parentElement.lastElementChild

    if (target.value.length <= 0){
      target.classList.add("is-danger")
      errorElement.classList.add("help", "is-danger")
      errorElement.textContent = "No puede estar vacío"
      return
    }

    target.classList.remove("is-danger")
    errorElement.classList.remove("help", "is-danger")
    errorElement.textContent = ""
  }

  cancel(){
    this.validateFundsforReports("/v1/reports/new")
  }

  validateShareholders(e) {
    e.preventDefault()
    const container = document.getElementById("report-by-rfc-ciec")

    const ShareholdersValid = this.searchShareholders(container)
    if(!ShareholdersValid) return

    this.sendShareholders(container)
  }

  searchShareholders(container) {
    const businessNameInputs = container.querySelectorAll('.legal-entity:not(.d-none)');
    const individualInputs = container.querySelectorAll('.individual:not(.d-none)');
    const focusInput = new CustomEvent("focus");
    const blurInput = new CustomEvent("blur");

    let namesBreak = false;
    (businessNameInputs || []).forEach(element => {
      const inputs = element.querySelectorAll("[data-field='business-name']");

      if(namesBreak) { return }

      (inputs || []).forEach((element) => {
        element.dispatchEvent(focusInput);
        element.dispatchEvent(blurInput);
        namesBreak = element.classList.contains("is-danger");
      })
    });

    let individualNameBreak = false;
    (individualInputs || []).forEach((item) => {
      const inputs = item.querySelectorAll('input[type="text"]')

      if(individualNameBreak) { return }

      (inputs || []).forEach((element) => {
        element.dispatchEvent(focusInput);
        element.dispatchEvent(blurInput);
        individualNameBreak = element.classList.contains("is-danger");
      })
    })

    let rfcsBreak = false;
    const rfcs = container.querySelectorAll("[data-field='rfc']")
    rfcs.forEach(element => {

      if(rfcsBreak) {return }

      element.focus();
      element.blur();
      rfcsBreak = element.classList.contains("is-danger");
    });

    let pctBreak = false;
    const participations = container.querySelectorAll("[data-name='participation']")
    participations.forEach(element => {

      if(pctBreak) { return }

      element.focus();
      element.blur();
      pctBreak = element.classList.contains("is-danger");
    });

    const errors = container.querySelectorAll("input.is-danger");
    if (errors.length > 0) {
      errors[0].focus();
      return false;
    }

    return true;
  }

  sendForm() {
    const reportForm = document.getElementById("report-form")
    const formData = new FormData(reportForm)
    const data = Object.fromEntries(formData.entries())
    data["report_form[ciec]"] = btoa(data["report_form[ciec]"])
    const externalServiceSelect = reportForm.querySelector('[id="external-services"')

    const body = {
      user_id: data["report_form[user_id]"],
      ciec: data["report_form[ciec]"],
      current_step: data["report_form[current_step]"],
      form_id: data["report_form[form_id]"],
      rfc: data["report_form[rfc]"],
      external_services: this.parseExternalServices(externalServiceSelect),
      base_64: true
    }

    axios.post("/v1/reports", body).then(response=>{
      if(body.rfc.length == 13){
        this.notification(response.data.message, true)
        this.submitTarget.classList.remove("is-loading")
        this.validateFundsforReports("/v1/reports/new.json")
        return
      }

      const container = document.getElementById("report-container")
      container.innerHTML = response.data
      this.submitTarget.classList.remove("is-loading")
    }).catch(error=>{
      this.notification(error.response.data.message, false)
      this.submitTarget.classList.remove("is-loading")
    })
  }

  skipShareholders(e) {
    e.preventDefault()
    const reportForm = document.getElementById("report-form")
    const formData = new FormData(reportForm)
    const data = Object.fromEntries(formData.entries())

    const body = {
      client_id: data["report_form[client_id]"],
      user_id: data["report_form[user_id]"],
      form_id: data["report_form[form_id]"],
      ciec: data["report_form[ciec]"],
      current_step: data["report_form[current_step]"],
      rfc: data["report_form[rfc]"],
      skip_shareholder: true,
      encrypted: true,
      shareholders_attributes: []
    }
    this.sendRequest(body, this.skipSubmitTarget, this.submitTarget)
  }

  sendShareholders() {
    const reportForm = document.getElementById("report-form")
    const shareholders = reportForm.querySelectorAll("[data-form-id]")
    const formData = new FormData(reportForm)
    const data = Object.fromEntries(formData.entries())

    const shareholdersData = this.mapShareholders(shareholders)

    const body = {
      client_id: data["report_form[client_id]"],
      user_id: data["report_form[user_id]"],
      form_id: data["report_form[form_id]"],
      ciec: data["report_form[ciec]"],
      current_step: data["report_form[current_step]"],
      rfc: data["report_form[rfc]"],
      skip_shareholder: false,
      encrypted: true,
      shareholders_attributes: shareholdersData
    }
    this.sendRequest(body, this.submitTarget, this.skipSubmitTarget)
  }

  mapShareholders(shareholders) {
    return Array.from(shareholders).map(shareholder=>{
      let fullName = '';
      const participationInput = shareholder.querySelector('[data-name="participation"]');
      const isLegalRepresentativeInput = shareholder.querySelector('[data-name="legal-representative"');
      const rfcInput = shareholder.querySelector('[data-field="rfc"]');
      if (rfcInput.value.length === 12) {
        fullName = shareholder.querySelector('[data-field="business-name"]').value
      } else {
        const firstNameInput = shareholder.querySelector("[data-field='first-name']")
        const lastNameInput = shareholder.querySelector("[data-field='last-name']")
        const mothersLastNameInput = shareholder.querySelector("[data-field='mothers-last-name']")
        fullName = `${firstNameInput.value} ${lastNameInput.value} ${mothersLastNameInput.value}`
      }

      return {
        full_name: fullName,
        rfc: rfcInput.value,
        participation: participationInput.value,
        is_legal_representative: isLegalRepresentativeInput.checked
      }
    })
  }

  sendRequest(body, target, inactiveTarget){
    target.classList.add("is-loading")
    inactiveTarget.disabled = true
    target.disabled = true

    axios.post("/v1/reports", body).then(response=>{
      this.notification(response.data.message, true)
      target.classList.remove("is-loading")

      setTimeout(() => {
        this.validateFundsforReports("/v1/reports/new.json")
      }, 300)

    }).catch(error=>{
      target.classList.remove("is-loading")
      this.notification(error.response.data.message, false)
    })

  }

  validateFundsforReports(url){
    const reportSection = document.getElementById("new-report-wrapper")
    const errorSection = document.getElementById("new-report-error")

    axios.get(url).then(response => {
      reportSection.innerHTML = response.data;
    }).catch(error=>{
      console.error(error)
      reportSection.innerHTML = ""
      errorSection.style.display = "block"
      if(error.response){
        errorSection.innerHTML = error.response.data
      }
    })
  }

  parseExternalServices(select) {
    if(!select ) return []

    return Array.from(select.selectedOptions).map(o=>{
      return o.value
    })
  }

  closeAccordions(){
    const ciec = document.getElementById("report-by-rfc-ciec")
    const external = document.getElementById("report-by-email-rfc")
    // const fiel = document.getElementById("report-by-e-signature")

    ciec.classList.remove("is-active")
    external.classList.remove("is-active")
    // fiel.classList.remove("is-active")
  }

  notification(message, success){
    const addClass = (success ? "is-success" : "is-danger")
    const removeClass = (success ? "is-danger" : "is-success")
    const iconClass = (success ? "excellent" : "alert")

    const notification = document.getElementById("sidebar-notification")
    const icon = document.getElementById("sidebar-notification-icon")
    icon.classList.add(iconClass)
    notification.parentElement.classList.remove(removeClass)
    notification.parentElement.classList.add(addClass)
    notification.parentElement.classList.remove(removeClass)
    notification.innerHTML = message
    notification.parentElement.classList.remove("is-hidden")

    setTimeout(()=>{
      notification.parentElement.classList.add("is-hidden")
    }, 5000)
  }

  search(e){
    e.preventDefault()
    const textToSearch = this.searchTarget.value
    const url = e.target.dataset.value

    this.searchTarget.parentNode.classList.add("is-loading")

    axios.get(url, { params: { search: textToSearch } }).then((response)=>{
      const resultWrapper = document.getElementById("result-search-report")
      const cleanElement = document.getElementById("clean-search-report")

      cleanElement.classList.remove("is-hidden")
      resultWrapper.innerHTML = response.data
      this.searchTarget.parentNode.classList.remove("is-loading")
    }).catch(_error=>{
      this.searchTarget.parentNode.classList.remove("is-loading")
    })
  }

  clearFilter(e) {
    e.preventDefault()

    this.searchTarget.value = ""
    const resultWrapper = document.getElementById("result-search-report")
    resultWrapper.innerHTML = ""
    e.target.classList.add("is-hidden")
  }
}
