import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.redirect();
  }

  redirect() {
    const website = this.data.get('url');

    if(!website || website === "") return;

    const redirect = document.getElementById('redirect');
    const linkTo = document.getElementById('link_to');
    const timer = document.getElementById('timer');
    
    let timeleft = 10;

    redirect.style.display = 'block'
    linkTo.innerHTML = website;
    linkTo.href= website;
    timer.innerHTML = timeleft;

    this.setupInterval(timeleft, timer, website)
  }

  setupInterval(timeleft, timerElement, website){
    const time = setInterval(()=>{
      if(timeleft <= 0){
        clearInterval(time);
        window.location.replace(website);
      }
      timerElement.innerHTML = timeleft;
      timeleft--;
    },1000);

    return time;
  }
}
