import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class CustomerMetricsChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data

    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 50

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.extraMin = 0.1;

    const lineSeries = this.chart.series.push(new am4charts.LineSeries())
    lineSeries.dataFields.valueY = 'accumulated'
    lineSeries.dataFields.dateX = 'date'
    lineSeries.tooltipText = "{valueY.value}"
    lineSeries.fillOpacity = 0.5
    lineSeries.strokeWidth = 0
    lineSeries.propertyFields.stroke = "lineColor"
    lineSeries.propertyFields.fill = "lineColor"
    lineSeries.fill = "#66ccff"
    lineSeries.name = 'Clientes Activos'

    const hireSeries = this.chart.series.push(new am4charts.ColumnSeries());
    hireSeries.dataFields.valueY = "new_customers"
    hireSeries.dataFields.dateX = 'date'
    hireSeries.fill = "#00cc00"
    hireSeries.stroke = "#00cc00"
    hireSeries.name = "Clientes Nuevos"

    const hireBullet = hireSeries.bullets.push(new am4charts.LabelBullet())
    hireBullet.label.text = "{valueY}"
    hireBullet.dy = -10
    hireBullet.fontSize = 10

    const unhireSeries = this.chart.series.push(new am4charts.ColumnSeries());
    unhireSeries.dataFields.valueY = "lost_customers"
    unhireSeries.dataFields.dateX = 'date'
    unhireSeries.fill = "#ff0000"
    unhireSeries.stroke = "#ff0000"
    unhireSeries.name = "Clientes Perdidos"

    const unhireBullet = unhireSeries.bullets.push(new am4charts.LabelBullet())
    unhireBullet.label.text = "{valueY}"
    unhireBullet.dy = 10
    unhireBullet.fontSize = 10


    // Add legend
    this.chart.legend = new am4charts.Legend()

    // Add scrollbar
    this.chart.scrollbarX = new am4charts.XYChartScrollbar();
    this.chart.scrollbarX.series.push(lineSeries);

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }

  generateChartData() {
    var chartData = [];
    var firstDate = new Date();
    firstDate.setDate(firstDate.getDate() - 1000);
    var visits = 1200;
    for (var i = 0; i < 5; i++) {
        // we create date objects here. In your data, you can have date strings
        // and then set format of your dates using chart.dataDateFormat property,
        // however when possible, use date objects, as this will speed up chart rendering.
        var newDate = new Date(firstDate);
        newDate.setDate(newDate.getDate() + i);

        visits += Math.round((Math.random()<0.5?1:-1)*Math.random()*10);

        chartData.push({
            date: newDate,
            visits: visits
        });
    }
    return chartData;
  }
}

export default CustomerMetricsChart
