import { Controller } from "stimulus";
import axios from "axios"

export default class extends Controller {
  static targets = ["bussinesName", "rfc", "address", "state", "city", "zipCode", "constancyKey", "regime", "invalidRegimesDisclaimer"]

  connect() {}

  async parseDocument(e) {
    
    const fileName = document.getElementById("document_preview")
    const userId = document.getElementById("user_id").value
    const modal = document.querySelector("#modal-loading")
    
    if(e.target.files.length == 0){
      fileName.innerHTML = "Subir constancia de situación fiscal"
    }

    const file = e.target.files[0]
    fileName.innerHTML = `${file.name} seleccionado`
    modal.classList.add("is-active")

    const formData = new FormData();
    formData.append("document", file);
    formData.append("user_id", userId);
    const response = await axios.post("/v1/billing_informations", formData)
    const data = response.data
    modal.classList.remove("is-active")
    this.setValues(data.result)  
  }

  setValues(result){
    
    const general = result.general_data || {};
    const location = result.location_data || {};
    const bussinesName = result.general_data ? general.company_name || `${general.names} ${general.first_lastname} ${general.second_lastname}` : "";
    const address = result.location_data ? `${location.road_name} ${location.outdoor_number}` : "";

    this.bussinesNameTarget.value = bussinesName;
    this.rfcTarget.value = general.rfc || "";
    this.addressTarget.value = address;
    this.stateTarget.value = location.state || "";
    this.cityTarget.value = location.city || "";
    this.zipCodeTarget.value = location.zip_code || "";
    this.constancyKeyTarget.value = result.constancy_key || "";
    this.clearRegimeOptions();
    this.setOptions(result.regimen_data || []);
  }

  setOptions(data) {
    const regimeSelect = this.regimeTarget;
    let invalidOptions = []
    
    if(data.length > 0) {
      regimeSelect.disabled = false;
    }else{
      regimeSelect.disabled = true;
    }

    data.forEach(element => {
      let option = new Option(element.description, element.description);

      if(!element.valid) {
        invalidOptions.push(element.description)
        option.setAttribute('disabled', '')
        option.setAttribute('title', 'Régimen inválido para facturar con "Gastos en general"')
      }

      if(invalidOptions.length == data.length){
        this.invalidRegimesDisclaimerTarget.classList.remove('is-hidden')
        document.querySelector('#invalid_regime').textContent = `(${invalidOptions.map(string => string)})`
      }else{
        this.invalidRegimesDisclaimerTarget.classList.add('is-hidden')
      }

      regimeSelect.add(option,undefined);
    });
  }

  clearRegimeOptions() {
    while (this.regimeTarget.options.length > 0) {
      this.regimeTarget.remove(0);
    }
  }

  sendForm(e){
    const form = e.target.form
    const inputs = form.querySelectorAll("input, select, checkbox")

    inputs.forEach(function(input) { input.disabled = false; })
    form.submit()
    inputs.forEach(function(input) { input.disabled = true; })
  }
}