import axios from 'axios';

const csrfToken = document.querySelector('meta[name=csrf-token]').content;

export default axios.create({
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'X-CSRF-Token': csrfToken,
  },
});
