document.addEventListener('DOMContentLoaded', function () {
  window.addEventListener('scroll', function () {
    const navBar = document.querySelector('#nav-home')
    const windowPageYOffset = window.pageYOffset
    if (windowPageYOffset > 48 && navBar) {
      navBar.classList.add('sticky')
    } else if (windowPageYOffset === 0 && navBar) {
      navBar.classList.remove('sticky') 
    }
  })
})
