
import { Controller } from "stimulus";

export default class extends Controller {

  displayLoading(event) {
    const $reportContainer = event.target.closest('[data="report-container"]');

    $reportContainer.querySelector('[data="content"]').innerHTML = '<div class="skeleton-card"></div>'
  }
}