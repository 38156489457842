import BaseValidator from "./base_validator";

export default class FormatValidator extends BaseValidator {
  validate() {
    if(this.options.allow_blank && this.value.trim() == "") {
      return { valid: true, message: "" };
    }

    let valid = false;
    let errorMessage = "";

    if(this.value) {

      if(this.options.with){
        valid = this.validateRegex("with")
        if(!valid){
          errorMessage = this.errorMessages["invalid"];
        }
      }

      if(this.options.without){
        valid = !this.validateRegex("without")
        if(!valid){
          errorMessage = this.errorMessages["without"]; 
        }
      }
    }

    return { valid: valid, message: errorMessage };
  }

  validateRegex(regexType){
    let reg = this.options[regexType].replace(/(\(\?-mix\:\\A)|\\z\)/g, "");

    if(reg.includes("mix")){
      reg = reg.replace(/(\(\?-mix\:)|\)$/g, "");
    }

    const regex = new RegExp(`^${reg}$`);
    return regex.test(this.value);
  }
}
