import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class ProjectionChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data

    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.ticks.template.disabled = true
    categoryAxis.renderer.line.opacity = 0
    categoryAxis.renderer.grid.template.disabled = true
    categoryAxis.renderer.minGridDistance = 40
    categoryAxis.dataFields.category = 'label'
    categoryAxis.startLocation = 0.4
    categoryAxis.endLocation = 0.6

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.tooltip.disabled = true
    valueAxis.renderer.line.opacity = 0
    valueAxis.renderer.ticks.template.disabled = true
    valueAxis.min = 0

    const self = this
    this.series.forEach(function (serie) {
      self.createSeries(serie[0], serie[1])
    })

    // Add legend
    this.chart.legend = new am4charts.Legend()

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }

  createSeries (field, name) {
    const series = this.chart.series.push(new am4charts.LineSeries())
    series.fillOpacity = 0.1
    series.dataFields.valueY = field
    series.dataFields.categoryX = 'label'
    series.name = name
    series.tooltipText = `${name}: {valueY}`
  }
}

export default ProjectionChart
