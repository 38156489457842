import { Controller } from "stimulus"
import TaxBreakdownChart from './../charts/tax-breakdown-chart'

export default class extends Controller {
  connect() {
    const TaxBreakdownCharts = document.querySelectorAll('.tax-breakdown-chart')
    TaxBreakdownCharts.forEach(function (taxBreakdownChart) {
      const id = taxBreakdownChart.getAttribute('id');
      const chart = window[id];
      var obj = new TaxBreakdownChart(chart);
      window[`${id}_obj`] = obj;
      obj.init();
    })
  }
}