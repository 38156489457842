import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class AreaChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
  }

  init () {
    try {
      var chart = am4core.create(this.id, am4charts.XYChart);

      chart.data = this.data
      chart.legend = new am4charts.Legend()
      chart.legend.useDefaultMarker = true;

      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.height = 20
      marker.width = 20

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
      dateAxis.renderer.minGridDistance = 50

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.numberFormatter = new am4core.NumberFormatter()
      valueAxis.numberFormatter.numberFormat = '$#,###.##'

      this.series.forEach((serieName) => {
        const lineSeries = chart.series.push(new am4charts.LineSeries())
        lineSeries.dataFields.valueY = serieName[0]
        lineSeries.dataFields.dateX = "year"

        lineSeries.name = serieName[1];
        lineSeries.fill = am4core.color(serieName[2])

        lineSeries.strokeWidth = 0
        lineSeries.fillOpacity = 0.85;
        lineSeries.stacked = true
      })

      // Add cursor
      chart.cursor = new am4charts.XYCursor()
    } catch(error) {
      console.error('Credit Bureau historical chart error: ', error);
    }
  }
}

export default AreaChart;