import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.removeNotification();
  }

  removeNotification() {
    const $notification = document.querySelector(".notification");
    setTimeout(() => {
      $notification.remove();
    }, 10000);
  }
}
