import { Controller } from "stimulus";

export default class extends Controller {
  showResults(e) {
    e.preventDefault()
    let section = e.target.dataset.section;
    let keyword = e.target.dataset.keyword;
    let entity = e.target.dataset.entity;
    let totalMatches = document.querySelector(`.data-header .${section}`);
    let resultsSection = document.querySelector(`.data-container[data-section='${section}'][data-entity='${entity}']`).parentElement
    let toggleShowResults = e.target.closest(".reputation-results").querySelector(".more")

    resultsSection.classList.remove("hidden");
    toggleShowResults.classList.add("opened");

    (document.querySelectorAll(`.data-container[data-section='${section}'][data-entity='${entity}'] > [data-keyword]`) || []).forEach(element => {
      element.classList.add("hidden")
    });

    (document.querySelectorAll(`.data-container[data-section='${section}'][data-entity='${entity}'] > [data-keyword='${keyword}']`) || []).forEach(element => {
      element.classList.remove("hidden");
      (element.querySelectorAll(".detailed-data") || []).forEach((dataRow) => {
        dataRow.style.height = `calc(${dataRow.offsetHeight}px + 3.5em)`;
      })
    });

    totalMatches.innerText = `${e.target.dataset.total} Resultados encontrados`;
  }

  toggleResults(e) {
    let section = e.target.dataset.section;
    let entity = e.target.dataset.entity;
    let dataSectionContainer = document.querySelector(`.data-container[data-section='${section}'][data-entity='${entity}']`)
    let resultsSection = dataSectionContainer.parentElement

    e.target.classList.toggle("opened")
    resultsSection.classList.toggle('hidden');

    if(e.target.classList.contains("opened")) {
      (dataSectionContainer.querySelectorAll(`[data-keyword='${e.target.dataset.firstKeyword}']`) || []).forEach((row) => {
        (row.querySelectorAll(".detailed-data") || []).forEach(detailedRow => detailedRow.style.height = `calc(${detailedRow.offsetHeight}px + 3.5em)`)
      })
    }
  }
}