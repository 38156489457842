import Validations from "./validations.js.erb";
import BaseValidator from "./base_validator";
import PresenceValidator from "./presence_validator";
import FormatValidator from "./format_validator";
import NumericalityValidator from "./numericality_validator";
import LengthValidator from "./length_validator";
import ConfirmationValidator from "./confirmation_validator";

let validators = {
  presence: PresenceValidator,
  format: FormatValidator,
  numericality: NumericalityValidator,
  length: LengthValidator,
  confirmation: ConfirmationValidator
}

export class Validation {
  validatable(input_id) {
    return Validations[input_id] !== undefined
  }

  validate(value, id) {
    let validations = Validations[id];
    let results = validations.map((validation) => {
      let validator = new validators[validation.validator](value, validation.options, validation.error_messages)
      return { validator: validation.validator, result: validator.validate() }
    });

    return results;
  }
}
