import { Controller } from "stimulus";
import axios from "../packs/setupCSRFToken"

export default class extends Controller {
  static targets = ["title", "menuReport", "menuPreverification", "availableReports", "availablePreverifications", "reportTags", "reportFormContainer"]

  open(event) {
    const sidebar = document.getElementById("sidebar")
    const sidebarBackground = document.getElementById("sidebar-background")
    if (!sidebar && !sidebarBackground) return;

    const [reportApiURL, preverificationApiURL] = event.currentTarget.value.split(",")
    document.documentElement.style.overflowY = 'hidden'

    axios.get("/active").then(response => {
      const inactive = response.data === false || response.data === "false" || response.data === null

      if (response.status == 200 && inactive) {
        window.location.href = '/timeout'
      } else {
        this.validateFundsforReports(reportApiURL)
        this.validateFundsforPrevetification(preverificationApiURL)

        sidebar.classList.add("is-active")
        sidebarBackground.classList.add("is-active")

        this.eventClose = sidebarBackground.addEventListener("click", (e) => {
          if (e.target == sidebarBackground) {
            this.close(event)
          }
        })
      }
    })
  }

  showSideBar(e) {
    const sidebar = document.getElementById("sidebar")
    const sidebarBackground = document.getElementById("sidebar-background")
    if(!sidebar && !sidebarBackground) return;
    sidebar.classList.add("is-active")
    sidebarBackground.classList.add("is-active")
    document.documentElement.style.overflowY = 'hidden'
    this.eventClose = sidebarBackground.addEventListener("click", (e)=>{
      if(e.target == sidebarBackground){
        this.close(e)
      }
    })
  }

  openWithoutRequest(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showSideBar(e);
  }

  fetchSubscriptions(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showSideBar(e);

    axios.get(e.target.dataset.subscriptionsUrl).
    then(({ data }) => {
      const subscriptionsContainer = document.getElementById('subscriptionsContainer');
      subscriptionsContainer.innerHTML = data.html_content;
    })
  }

  ShowInsufficientFundsModal(e){
    e.target.classList.add("is-loading")
    const modal = document.getElementById("insufficient-funds-sidebar-modal")
    if (modal) modal.classList.add("is-active")
    this.close(e)
  }

  close(e){
    e.preventDefault();
    e.stopPropagation();

    const sidebar = document.getElementById("sidebar")
    const sidebarBackground = document.getElementById("sidebar-background")
    if (!sidebar && !sidebarBackground) return;

    document.documentElement.style.overflowY = ''
    sidebar.classList.remove("is-active")
    sidebarBackground.classList.remove("is-active")
    sidebarBackground.removeEventListener("click", this.eventClose)
  }

  openAccordion(event) {
    event.target.classList.toggle("is-active")
    const value = event.target.dataset.value;
    const element = document.getElementById(value)

    element.classList.toggle("is-active")
  }

  showSection(event){
    const target = event.currentTarget
    const value = target.dataset.value;
    const reportSection = document.getElementById("new-report-section")
    const preVerificationSection = document.getElementById("new-pre-verification-section")

    if (value == "new-pre-verification") {
      reportSection.classList.add("is-hidden")
      preVerificationSection.classList.remove("is-hidden")
      this.menuPreverificationTarget.classList.remove("is-hidden")
      this.menuReportTarget.classList.add("is-hidden")
      this.availablePreverificationsTarget.classList.remove("is-hidden")
      this.availableReportsTarget.classList.add("is-hidden")
    }

    if (value == "new-report") {
      reportSection.classList.remove("is-hidden")
      preVerificationSection.classList.add("is-hidden")
      this.menuReportTarget.classList.remove("is-hidden")
      this.menuPreverificationTarget.classList.add("is-hidden")
      this.availableReportsTarget.classList.remove("is-hidden")
      this.availablePreverificationsTarget.classList.add("is-hidden")
    }

    this.titleTarget.textContent = target.dataset.title
  }

  showCurrentReportForm(e) {
    const currentValue = e.target.dataset.value
    const target = e.target
    const reportTags = this.reportTagsTargets || []
    const reportFormContainers = this.reportFormContainerTargets || []
    reportTags.forEach((tag) => {
      tag.parentElement.classList.remove("is-active")
    })

    reportFormContainers.forEach(container => {
      if( currentValue == container.id ) {
        container.classList.remove("is-hidden")
      } else {
        container.classList.add("is-hidden")
      }
    })

    target.parentElement.classList.add("is-active")
  }

  validateFundsforReports(url) {
    const errorSection = document.getElementById("new-report-error")
    const reportSection = document.getElementById("new-report-wrapper")
    if (!reportSection && !errorSection) return
    axios.get(url, { headers: { Accept: 'text/html'} }).then(response => {
      reportSection.innerHTML = response.data;
      errorSection.style.display = "none"
    }).catch(error => {
      reportSection.innerHTML = ""
      errorSection.style.display = "block"
      if (error.response) {
        errorSection.innerHTML = error.response.data
      }
    })
  }

  validateFundsforPrevetification(url) {
    const errorSection = document.getElementById("new-pre-verification-error")
    const preverificationSection = document.getElementById("pre-verification-wrapper")
    if (!preverificationSection && !errorSection) return
    axios.get(url, { headers: { Accept: 'text/html' } }).then(response => {
      preverificationSection.innerHTML = response.data;
      errorSection.style.display = "none"
    }).catch(error => {
      preverificationSection.innerHTML = ""
      errorSection.style.display = "block"
      if (error.response) {
        errorSection.innerHTML = error.response.data
      }
    })
  }

  credits_purchase(e) {
    e.target.classList.add("is-loading")
  }
}
