import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class HistoricalPayrollBehaviorChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data

    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 50

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = "Nómina"

    const hiringAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    hiringAxis.title.text = "Altas/Bajas"
    hiringAxis.renderer.opposite = true;
    hiringAxis.renderer.grid.template.disabled = true;
    hiringAxis.extraMin = 0.2

    const lineSeries = this.chart.series.push(new am4charts.LineSeries())
    lineSeries.dataFields.valueY = 'amount'
    lineSeries.dataFields.dateX = 'date'
    lineSeries.name = "Nómina"
    lineSeries.tooltipText = "{valueY.formatNumber('$#,###.##')}"
    lineSeries.fillOpacity = 0.5
    lineSeries.strokeWidth = 0
    lineSeries.propertyFields.stroke = "lineColor"
    lineSeries.propertyFields.fill = "lineColor"
    lineSeries.fill = "#66ccff"

    const hireSeries = this.chart.series.push(new am4charts.ColumnSeries());
    hireSeries.dataFields.valueY = "hired"
    hireSeries.dataFields.dateX = 'date'
    hireSeries.name = "Altas"
    hireSeries.yAxis = hiringAxis
    hireSeries.fill = "#00cc00"
    hireSeries.stroke = "#00cc00"

    const unhireSeries = this.chart.series.push(new am4charts.ColumnSeries());
    unhireSeries.dataFields.valueY = "unhired"
    unhireSeries.dataFields.dateX = 'date'
    unhireSeries.name = "Bajas"
    unhireSeries.yAxis = hiringAxis
    unhireSeries.fill = "#ff0000"
    unhireSeries.stroke = "#ff0000"

    // Add legend
    this.chart.legend = new am4charts.Legend()

    // Add scrollbar
    this.chart.scrollbarX = new am4charts.XYChartScrollbar();
    this.chart.scrollbarX.series.push(lineSeries);

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }
}

export default HistoricalPayrollBehaviorChart
