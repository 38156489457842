import { Controller } from "stimulus";
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import axios from 'axios';

export default class extends Controller {
  static targets = [ "submit", "submitWhatsapp", "tof","tofWhatsapp", "mandatoryShareholders", "wrapperShareholder", "mandatoryShareholdersWhatsapp", "wrapperShareholderWhatsapp"]

  connect() {
    this.bindEmailInput()
    this.rfcRegex = /(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})/
    this.emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }

  bindEmailInput() {
    if(!document.querySelector('#sidebar.is-active')) return false

    const emailInput = document.getElementById("external-email")
    const parent = emailInput.closest('.bind-input-wrapper')
    const tagInput = new BulmaTagsInput(emailInput)
    this.bulmaTag = tagInput
    const bindContainer = parent.querySelector('.tags-input')

    let _this = this


    tagInput.input.onblur = function () {
      if(_this.validateEmailFormat(tagInput, tagInput.input.value)) {
        tagInput.add(tagInput.input.value)
        tagInput.input.value = ""
        _this.validateEmailPresence(tagInput)
      }
    };

    tagInput.input.onkeydown = function (e) {
      if(e.key === "Enter") {
        if(!_this.validateEmailFormat(tagInput, tagInput.input.value)) {
          tagInput.remove(tagInput.input.value);
          tagInput.input.value = "";
        }
      }
    }

    parent.querySelectorAll('.tags-input').forEach(element => {
      if (element != bindContainer) element.classList.add('is-hidden')
    });

  }

  bindPhoneNumbers(e){
    if (e.key === "Enter" && this.validatePhoneNumberFormat(e)) {
        const parent = e.target.parentElement.parentElement.parentElement

      let numbers_wrapper = ''
      let select_numbers = ''

      if(document.querySelector('#sidebar.is-active')){
        numbers_wrapper = document.querySelector('#phone-numbers-wrapper')
        select_numbers = document.querySelector('#select_numbers')
      }else{
        numbers_wrapper = document.querySelector('#phone-numbers-wrapper-resend-notification')
        select_numbers = document.querySelector('#select_numbers_resend_notification')
      }

      let select_options = numbers_wrapper.querySelectorAll('option')
      const option = document.createElement('option')
      let cloned  = false

      select_options.forEach(element => {
        if(element.textContent == e.target.value){
          e.target.value = ''
          cloned = true
        }
      });

      if(!cloned){
        option.textContent = e.target.value
        option.classList.add('option_phone_number')

        numbers_wrapper.appendChild(option)
        e.target.value = ''
        select_numbers.classList.remove('is-hidden')

          this.showSuccess(parent, 'Número telefónico agregado')
          setTimeout(() => {
            this.removeSuccess(parent)
          }, 2000);
      }
    }
  }

  validateEmailPresence(target) {
    let input = target.input
    let $parent = input.parentNode.parentNode;
    let invalidIcon = $parent.querySelector("span.icon")
    let invalidMsg = $parent.querySelector("p.is-danger")

    input.classList.remove("is-danger");
    if(invalidIcon) { invalidIcon.classList.add("is-hidden") }

    if(invalidMsg) { invalidMsg.remove() }

    if(target.items.length) { return }

    input.classList.add("is-danger");
    let help = document.createElement("p")
    let error = "Email no puede estar vacío"
    this.tofTarget.checked = false
    this.submitTarget.disabled = true;
    help.setAttribute("class", "help is-danger")
    help.innerHTML = error;
    if(invalidIcon) { invalidIcon.classList.remove("is-hidden") }
    $parent.appendChild(help);
  }

  validateEmailFormat(target, email) {
    if(!email.trim().length) { return true }

    let input = target.input
    let $parent = input.parentNode.parentNode;
    let invalidIcon = $parent.querySelector("span.icon")
    let invalidMsg = $parent.querySelector("p.is-danger")

    input.classList.remove("is-danger");
    if(invalidIcon) { invalidIcon.classList.add("is-hidden") }

    if(invalidMsg) { invalidMsg.remove() }

    if(this.emailRegex.test(email)) { return true }

    this.showInvalidEmailMsg(input, $parent, invalidIcon);

    return false;
  }

  showInvalidEmailMsg(input, $parent, invalidIcon) {
    input.classList.add("is-danger");
    let help = document.createElement("p")
    let error = "Formato de email no es valido"
    help.setAttribute("class", "help is-danger")
    help.innerHTML = error;
    this.tofTarget.checked = false
    this.submitTarget.disabled = true;
    if(invalidIcon) { invalidIcon.classList.remove("is-hidden") }
    $parent.appendChild(help);
  }

  changeMediumOfContact(e){
    const btn_submit = document.querySelector('#ex-submit-whatsapp-sms')
    const whatsapp_disclaimer = document.querySelector('#whatsapp-disclaimer')

    if(e.target.selectedIndex==0){
      btn_submit.setAttribute('data-contact-medium','whatsapp')

      whatsapp_disclaimer.classList.remove('is-hidden')
      whatsapp_disclaimer.classList.add('slideFadeTopIn')
      whatsapp_disclaimer.classList.remove('slideFadeTopOut')
    }else{
      btn_submit.setAttribute('data-contact-medium','sms')

      whatsapp_disclaimer.classList.remove('slideFadeTopIn')
      whatsapp_disclaimer.classList.add('slideFadeTopOut')
      setTimeout(() => {whatsapp_disclaimer.classList.add('is-hidden')}, 500);
    }
  }

  changePhoneNUmber(e){
    let option = ''
    let input = ''

    if(document.querySelector('#sidebar.is-active')){
      input = document.querySelector("#external-whatsapp")
      option = document.querySelector('#phone-numbers-wrapper')[e.target.selectedIndex]
    }else{
      input = document.querySelector("#phone_number_resend-notification")
      option = document.querySelector('#phone-numbers-wrapper-resend-notification')[e.target.selectedIndex]
    }

    const val = option.textContent
    input.value = val
    option.remove()
  }

  inputRFC(e){
    const value = e.target.value
    this.validateRFCformat(e.target, value)
  }

  validateRFCformat(target, value){
    let type = target.getAttribute('data-target')

    if (value.trim().length === 0 ){
      switch (type) {
        case 'whatsapp':
          this.tofWhatsappTarget.checked = false
          this.submitWhatsappTarget.disabled = true;
          break;

        default:
          this.tofTarget.checked = false
          this.submitTarget.disabled = true;
          break;
      }
      return this.showError(target, "El RFC no puede estar vacío")
    }

    if(!this.rfcRegex.test(value)) {
      switch (type) {
        case 'whatsapp':
          this.tofWhatsappTarget.checked = false
          this.submitWhatsappTarget.disabled = true;
          break;

        default:
          this.tofTarget.checked = false
          this.submitTarget.disabled = true;
          break;
      }
      return this.showError(target, "El RFC no es valido")
    }

    if(value.length === 12) {
      switch (type) {
        case 'whatsapp':
          this.wrapperShareholderWhatsappTarget.classList.remove("hidden")
          break;

        default:
          this.wrapperShareholderTarget.classList.remove("hidden")
          break;
      }
    }else{
      switch (type) {
        case 'whatsapp':
          this.mandatoryShareholdersWhatsappTarget.checked = false;
          this.wrapperShareholderWhatsappTarget.classList.add("hidden")
          break;

        default:
          this.mandatoryShareholdersTarget.checked = false;
          this.wrapperShareholderTarget.classList.add("hidden")
          break;
      }
    }
    this.removeError(target)
  }

  validatePhoneNumber(e){
    e.target.value = e.target.value.trim()
    let val = Array.from(e.target.value)
    let clean_val = ''
    let sidebar_existence = document.querySelector('#sidebar.is-active')
    const parent = e.target.parentElement
    const target_error = parent.parentElement.parentElement.querySelector('p.help')
    const icon_alert = parent.parentElement.querySelector('span.icon')
    const checkbox_terms = document.querySelector("#report-by-whatsapp #external-report-tof-whatsapp")

    if(checkbox_terms)
      if (checkbox_terms.checked != false) checkbox_terms.click()

    val.forEach(char => {
      if (!isNaN(char))
        clean_val += val[val.indexOf(char)]
      else
        if(char == ' ')
          e.target.value = val.substring(0, val.length - 1)
    })
    e.target.value = clean_val
    if(val.length == 10){
      e.target.classList.remove('is-danger')
      icon_alert.classList.add("is-hidden")
      this.removeError(parent)
    }
  }

  validatePhoneNumberFormat(e){
    const parent = e.target.parentElement.parentElement.parentElement
    const _this = this
    const val = e.target.value.trim()
    const icon_alert = parent.parentElement.querySelector('span.icon')
    const target_error = document.querySelector("#report-by-whatsapp input[data-id='external-whatsapp']")
    let numbers_wrapper = ''

    if(document.querySelector('#sidebar.is-active'))
      numbers_wrapper = document.querySelectorAll('#phone-numbers-wrapper option:not(.fixed)')
    else
      numbers_wrapper = document.querySelectorAll('#phone-numbers-wrapper-resend-notification option:not(.fixed)')

    if (val.length > 0) {
      if (val.length != 10){
        e.target.classList.add('is-danger')
        icon_alert.classList.remove("is-hidden")
        _this.showError(parent, 'Número telefónico debe ser a 10 dígitos')
        return false
      }else{
        e.target.classList.remove('is-danger')
        icon_alert.classList.add("is-hidden")
        _this.removeError(parent)
      }
    }else if(numbers_wrapper.length == 0){
      e.target.classList.add('is-danger')
      icon_alert.classList.remove("is-hidden")
      _this.showError(parent, "Debes ingresar al menos un número")
      return false
    }

    if(numbers_wrapper.length > 0){
      e.target.classList.remove('is-danger')
      icon_alert.classList.add("is-hidden")
      _this.removeError(parent)
    }

    return val != '' ? true : false
  }

  removeError(target){
    target.classList.remove("is-danger")
    const parent = target.parentElement
    const messageElement = parent.lastChild

    if(messageElement.tagName != "P"){
      return
    }

    messageElement.classList.remove("help")
    messageElement.classList.remove("is-danger")
    messageElement.classList.add("is-hidden")
  }

  showError(target, message){
    target.classList.add("is-danger")
    const parent = target.parentElement
    const messageElement = parent.lastChild
    if(messageElement.tagName == "P"){
      messageElement.classList.add("help")
      messageElement.classList.add("is-danger")
      messageElement.classList.remove("is-hidden")
      parent.lastChild.innerHTML = message
      return
    }
    const help = document.createElement("p")
    help.setAttribute("class", "help is-danger")
    help.innerHTML = message
    parent.appendChild(help)
  }

  removeSuccess(target){
    target.classList.remove("is-success")
    const parent = target.parentElement
    const messageElement = parent.lastChild

    if(messageElement.tagName != "P"){
      return
    }

    messageElement.classList.remove("help")
    messageElement.classList.remove("is-success")
    messageElement.classList.add("is-hidden")
  }

  showSuccess(target, message){
    target.classList.add("is-success")
    const parent = target.parentElement
    const messageElement = parent.lastChild
    if(messageElement.tagName == "P"){
      messageElement.classList.add("help")
      messageElement.classList.add("is-success")
      messageElement.classList.remove("is-hidden")
      parent.lastChild.innerHTML = message
      return
    }
    const help = document.createElement("p")
    help.setAttribute("class", "help is-success")
    help.innerHTML = message
    parent.appendChild(help)
  }


  termsOFServiceSelected(e){
    let type = e.target.getAttribute('data-target')

    switch (type) {
      case 'external-report-form.tofWhatsapp':
          if(e.target.checked) {
            this.validateRfcAndPhoneNumberPresence()
          } else {
            this.submitWhatsappTarget.disabled = true;
          }
        break;

      default:
          if(e.target.checked) {
            this.validateRfcAndCiecPresence()
          } else {
            this.submitTarget.disabled = true;
          }
        break;
    }
  }

  validateRfcAndPhoneNumberPresence(){
    const container = document.getElementById("report-by-whatsapp")
    const rfcInput = container.querySelector("input#external-rfc-whatsapp")
    const checkbox = container.querySelector("#external-report-tof-whatsapp")
    const phoneNumberinput = document.querySelector('#external-whatsapp')
    phoneNumberinput.focus()
    phoneNumberinput.blur()

    if(rfcInput) {
      rfcInput.focus();
      rfcInput.blur();
    }

    const errors = container.querySelectorAll("input.is-danger")
    if (errors.length > 0) {
      errors[0].focus();
      this.submitWhatsappTarget.disabled = true;
      checkbox.checked = false
    } else {
      this.submitWhatsappTarget.disabled = false;
    }
  }

  validateRfcAndCiecPresence() {
    const rfcInput = document.getElementById("external-rfc");
    const emailInput = document.querySelector("[data-id='external-email']")
    const container = document.getElementById("report-by-email-rfc")

    if(rfcInput) {
      rfcInput.focus();
      rfcInput.blur();
    }

    if(emailInput) {
      emailInput.focus();
      emailInput.blur();
    }

    const errors = container.querySelectorAll("input.is-danger");
    if (errors.length > 0) {
      errors[0].focus();
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }

  submitForm(e){
    e.preventDefault();
    let typeReport = e.target.getAttribute('data-contact-medium')
    let rfcInput = ''
    let requestBy = ''
    let externalServices = []
    let externalServicesSelect = null
    const userId = document.getElementById("external-user-id")
    let mandatoryShareholder = false

    switch (typeReport) {

      case 'sms':
      case 'whatsapp':
          let InputVal = document.querySelector('#external-whatsapp').value.trim()
          let options = document.querySelectorAll('#select_numbers option:not(.fixed)')
          let opt_counter = 0
          rfcInput = document.getElementById("external-rfc-whatsapp")

          requestBy = InputVal
          if(InputVal.length != 0 && options.length != 0) requestBy += ','

          options.forEach(number => {
            opt_counter++
            requestBy += number.textContent
            if(opt_counter != options.length) requestBy += ','
          });

          if (rfcInput.value.length === 12)
          mandatoryShareholder = this.mandatoryShareholdersWhatsappTarget.checked

          externalServicesSelect = document.getElementById("sms-external-services")
          externalServices = this.parseExternalServices(externalServicesSelect)
        break;

      case 'sms':
        typeReport = 'SMS'
        break;

      case 'whatsapp':
        typeReport = 'whatsapp'
        break;

      default:
        rfcInput = document.getElementById("external-rfc")
        requestBy = document.getElementById("external-email").value
        externalServicesSelect = document.getElementById("report-external-services")
        externalServices = this.parseExternalServices(externalServicesSelect)
        typeReport = 'email'
        if (rfcInput.value.length === 12)
          mandatoryShareholder = this.mandatoryShareholdersTarget.checked
        break;
    }

    const body = {
      rfc: rfcInput.value,
      request_data: requestBy,
      mandatory_shareholder: mandatoryShareholder,
      external_services: externalServices,
      user_id: userId.value,
      type_report: typeReport
    }

    switch (typeReport) {
      case 'whatsapp':
      case 'sms':
        this.submitWhatsappTarget.classList.add("is-loading")
        break;

      default:
        this.submitTarget.classList.add("is-loading")
        break;
    }

    this.cleanForm()

    axios.post('/v1/reports/external', body).then(response=>{
      this.notification(response.data.message, true)

      if (typeReport == 'whatsapp' || typeReport == 'sms')
        this.submitWhatsappTarget.classList.remove("is-loading")
      else
        this.submitTarget.classList.remove("is-loading")

      this.cleanForm()
    }).catch(error=>{
      this.notification(error.response.data.message)
    }).finally(()=>{
      if (typeReport == 'whatsapp' || typeReport == 'sms')
        this.submitWhatsappTarget.classList.remove("is-loading")
      else
        this.submitTarget.classList.remove("is-loading")
    })
  }

  parseExternalServices(select) {
    if(!select ) return []

    return Array.from(select.selectedOptions).map(o=>{
      return o.value
    })
  }

  cleanForm(){
    const sidebar_buttons = document.querySelectorAll("#sidebar button[type='submit']")
    const sidebar_wrappers = document.querySelectorAll("#sidebar div.wrapperShareholder")
    const sidebar_span_nodes = document.querySelectorAll("#sidebar div.tags-input span")
    const sidebar_inputs = document.querySelectorAll("#sidebar input")
    const sidebar_dropdown_numbers = document.querySelector("#select_numbers")
    this.bulmaTag.removeAll()
    document.querySelector("#report-form-continue").disabled =  "disabled";

    sidebar_inputs.forEach(element => {
      if (element.getAttribute('type') == 'text')
        element.value = ''
      else (element.getAttribute('type') == 'checkbox')
        element.checked = false
    });

    sidebar_dropdown_numbers.classList.add('is-hidden')
    sidebar_dropdown_numbers.querySelectorAll('option:not(.fixed)').forEach(element => {
      element.remove()
    });

    sidebar_span_nodes.forEach(element => {
      element.remove()
    });

    sidebar_buttons.forEach(element => {
      element.disabled = "disabled";
    });

    sidebar_wrappers.forEach(element => {
      element.classList.add("hidden")
    });

  }

  notification(message, success){
    const addClass = (success ? "is-success" : "is-danger")
    const removeClass = (success ? "is-danger" : "is-success")
    const iconClass = (success ? "excellent" : "alert")

    const notification = document.getElementById("sidebar-notification")
    const icon = document.getElementById("sidebar-notification-icon")
    icon.classList.add(iconClass)
    notification.parentElement.classList.remove(removeClass)
    notification.parentElement.classList.add(addClass)
    notification.parentElement.classList.remove(removeClass)
    notification.innerHTML = message
    notification.parentElement.classList.remove("is-hidden")

    setTimeout(()=>{
      notification.parentElement.classList.add("is-hidden")
    }, 5000)
  }
}
