import PreVerificationBaseController from "./pre_verification_base_controller";

export default class PreVerification extends PreVerificationBaseController {

  entityTypeHandler(e) {
    let entityTypeSelector = e.target;

    const container = document.getElementById("new-pre-verification-form")

    let rfc = container.querySelector("[data-field='rfc']");
    let entityName = container.querySelector("[data-field='entity-name']");

    let rfcContainer = container.querySelector("[data-container='rfc']");
    let entityNameContainer = container.querySelector("[data-container='entity-name']");
    let button = container.querySelector("#submit-prev");

    rfc.value = "";
    entityName.value = "";

    rfcContainer.classList.toggle("hidden", entityTypeSelector.value === "entityName")
    entityNameContainer.classList.toggle("hidden", entityTypeSelector.value === "rfc")

    button.disabled = false;
  }

  submitForm(e) {
    e.preventDefault();

    const container = document.getElementById("new-pre-verification-form")
    const showModal = e.target.getAttribute("data-showmodal")

    let rfc = container.querySelector("[data-field='rfc']");
    let entityName = container.querySelector("[data-field='entity-name']");
    let button = container.querySelector("#submit-prev");

    this.removeError(rfc);
    this.removeError(entityName);

    if(this.validateRfcAndName()) return

    if(showModal == "true") {
      this.showModalDiscount(e)
      return
    }

    button.disabled = true;
    e.target.form.submit()
  }

  hideModalDiscount(){
    const continueBtn = document.querySelector("#submitBtn")
    const modalDiscount = document.querySelector("#discount_credits_modal")

    continueBtn.classList.add("is-loading")
    modalDiscount.classList.remove("is-active")
  }

  validateRfcAndName(){
    const container = document.getElementById("new-pre-verification-form")
    let rfcContainer = container.querySelector("[data-container='rfc']");
    let entityNameContainer = container.querySelector("[data-container='entity-name']");
    let rfc = container.querySelector("[data-field='rfc']");
    let entityName = container.querySelector("[data-field='entity-name']");

    if(!rfcContainer.classList.contains("hidden") && rfc.value.trim().length == 0) {
      this.showError("Longitud de RFC no valido", rfc);
      return true
    }

    if(!entityNameContainer.classList.contains("hidden") && entityName.value.trim().length == 0) {
      this.showError("Favor de usar por lo menos Nombre y Apellido", entityName);
      return true
    }

    if(!entityNameContainer.classList.contains("hidden") && 50 < entityName.value.trim().length) {
      this.showError("Favor de usar un nombre menor a 50 caracteres", entityName);
      return true
    }
  }

  showModalDiscount(e){
    const modals = document.querySelectorAll("#discount_credits_modal")
    modals.forEach(element => {
      element.classList.add('is-active')
    });
  }
}
