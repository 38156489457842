import * as am4core from '@amcharts/amcharts4/core'

// Ekatena charts them
function am4themesEkatena (target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color('#db3a66'),
      am4core.color('#0099f8'),
      am4core.color('#efbe48'),
      am4core.color('#86da83'),
      am4core.color('#8ed1fc'),
      am4core.color('#ff6666'),
      am4core.color('#cc3300'),
      am4core.color('#ffcc00'),
      am4core.color('#33cc33'),
      am4core.color('#ff8000'),
      am4core.color('#ffbf00'),
      am4core.color('#cc6600'),
      am4core.color('#cccc00'),
      am4core.color('#cc3359'),
      am4core.color('#a633cc')
    ]
  }
}

const Report = {
  init: function () {
    // am4core.disposeAllCharts();
    am4core.ready(function () {
      am4core.useTheme(am4themesEkatena)
    })
  }
}

document.addEventListener('DOMContentLoaded', function () {
  Report.init()
})
