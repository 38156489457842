class Pill {
  constructor (element) {
    this.observers = []
    this.element = element
    this.links = this.element.querySelectorAll('a')
    this.contents = this.element.parentNode.querySelectorAll('.pill-content')
    this.setEvents()
    this.charts = []
    this.registerCharts()
    // Wait until window is loaded for dom dimensions and set State
    const self = this

    setTimeout(function () {
      self.setState(element.getAttribute('data-state'))
    }, 1000)
  }

  registerCharts () {
    const self = this
    this.contents.forEach(function (content) {
      content.querySelectorAll('.bar-chart, .pie-chart').forEach(function (chart) {
        self.charts.push(chart.getAttribute('id'))
      })
    })
  }

  setEvents () {
    // Link Events
    const self = this
    this.links.forEach(function (anchor) {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        self.setState(anchor.getAttribute('data-state'))
      })
    })
  }

  notifyLinks () {
    const self = this
    this.links.forEach(function (link) {
      if (link.getAttribute('data-state') === self.state) {
        if (!link.classList.contains('active')) {
          link.classList.add('active')
        }
      } else {
        link.classList.remove('active')
      }
    })
  }

  notifyContents () {
    const self = this
    this.contents.forEach(function (content) {
      if (self.state === content.getAttribute('data-state')) {
        if (!content.classList.contains('active')) {
          content.classList.add('active')
          content.parentNode.style.height = `calc(${content.offsetHeight}px + 3.5em)`
        }
      } else {
        content.classList.remove('active')
      }
    })
  }

  setState (state) {
    this.state = state
    this.notifyLinks()
    this.notifyContents()
    this.notifyObservers()
  }

  addObserver (observer) {
    this.observers.push(observer)
  }

  notifyObservers () {
    for (const o of this.observers) {
      o.update(this)
    }
  }
}

export default Pill
