import PreVerificationBaseController from "./pre_verification_base_controller";
import axios from "axios"

export default class PreVerificationForm extends PreVerificationBaseController {
  static targets = ["search", "clearFilter", "submitBtn"];

  entityTypeHandler(e) {
    let entityTypeSelector = e.target;

    const rfc = document.getElementById("rfc-field")
    const entity = document.getElementById("entity-field")

    const rfcContainer = document.getElementById("rfc-container")
    const entityNameContainer = document.getElementById("entity-container")

    rfc.value = "";
    entity.value = "";

    const entitiesTabs = document.getElementsByName("entity_selector")
    if(entitiesTabs.length > 0){
      entitiesTabs.forEach((tab)=>{
        tab.parentElement.classList.remove("is-active")
      })
    }
    entityTypeSelector.parentElement.classList.add("is-active")

    rfcContainer.classList.toggle("hidden", entityTypeSelector.dataset.target === "entityName")
    entityNameContainer.classList.toggle("hidden", entityTypeSelector.dataset.target === "rfc")

    this.submitBtnTarget.disabled = false;
  }

  submitForm(e) {
    e.preventDefault();

    const rfc = document.getElementById("rfc-field")
    const entity = document.getElementById("entity-field")
    const userId = document.getElementById("pre_verification_form_user_id")
    const showModal = e.target.getAttribute("data-showmodal")

    this.removeError(rfc);
    this.removeError(entity);

    if(!this.validateRfcAndName()) return

    if(showModal == "true") {
      this.showModalDiscount(e)
      return
    }

    this.submitBtnTarget.disabled = true;

    this.sendPostRequest({
      name: entity.value,
      rfc: rfc.value,
      user_id: userId.value
    })
  }

  validateRfcAndName(){
    const entityNameContainer = document.getElementById("entity-container")
    const rfc = document.getElementById("rfc-field")
    const entity = document.getElementById("entity-field")

    const rfcEmpty = rfc.value.trim().length == 0
    const entityEmpty = entity.value.trim().length == 0

    if (rfcEmpty && entityEmpty) {
      this.showError("Longitud de RFC no valido", rfc)
      this.showError("Favor de usar por lo menos Nombre y Apellido", entity)
      return false
    }

    return true
  }

  showModalDiscount(e){
    const modal = document.querySelector("#discount_credits_modal")
    modal.classList.add('is-active')
  }

  closeModal(){
    const modal = document.querySelector("#discount_credits_modal")
    modal.classList.remove('is-active')
  }

  sendModalDiscount(e){
    const modalDiscount = document.querySelector("#discount_credits_modal")

    e.target.classList.add("is-loading")
    modalDiscount.classList.remove("is-active")

    this.submitBtnTarget.removeAttribute("showmodal")
    this.submitBtnTarget.form.submit()
  }

  sendPostRequest(params){
    const body = {
      pre_verification_form: params
    }
    
    axios.post('/v1/pre_verifications', params).then(response=>{
      this.notification(response.data.message, true)
      this.validateFundsforPrevetification("/v1/pre_verifications/new")

    }).catch(error=>{
      if (error.response.status != 402) {
        this.notification("Ha ocurrido un error al generar la pre-verificación", false)
        this.submitBtnTarget.disabled = false
      } else {
        this.notification(error.response.data.message, error.response.status)
        this.submitBtnTarget.disabled = false
      }
    })
  }

  cleanForm(){
    const rfc = document.getElementById("rfc-field")
    const entity = document.getElementById("entity-field")
    const cleanButtonRFC = document.getElementById("clear-rfc") 
    const cleanButtonName = document.getElementById("clear-entity-name")
  
    cleanButtonRFC.classList.add("is-hidden")
    cleanButtonName.classList.add("is-hidden")
    rfc.value = "";
    entity.value = "";
    this.submitBtnTarget.disabled = false
  }

  entityNameOnChangeHandler(e){
    let value = e.target.value;
    const iconId = e.target.dataset.value;
    const iconElement = document.getElementById(iconId)
    value ? iconElement.classList.remove("is-hidden") : iconElement.classList.add("is-hidden")
  }
  
  clearField(e){
    const button = e.target;
    const inputFieldTarget = button.dataset.value
    const remainingInputTarget = inputFieldTarget.includes('rfc') ? 'entity-field' : 'rfc-field'

    const inputField = document.getElementById(inputFieldTarget)
    const remainingInputField = document.getElementById(remainingInputTarget)
    const submit = document.getElementById("submit-pre-verification")
    
    inputField.value = ""
    this.removeError(inputField)

    const alerts = submit.form.querySelectorAll('.help.is-danger')
    
    if(!remainingInputField.value.trim() || alerts.length){
      submit.disabled = true
    }else{
      submit.disabled = false
    }

    button.classList.add("is-hidden")
  }

  searchByName(e) {
    e.preventDefault()
    const textToSearch = this.searchTarget.value
    const url = e.target.dataset.value

    this.searchTarget.parentNode.classList.add("is-loading")
    this.sendRequest(textToSearch, url);
  }

  clearFilter(e) {
    e.preventDefault()

    this.searchTarget.value = ""
    const resultWrapper = document.getElementById("result-search-prev")
    resultWrapper.innerHTML = ""
    e.target.classList.add("is-hidden")
  }

  sendRequest(textToSearch, url) {
    axios.get(url, { params: { search: textToSearch } }).then((response)=>{
      const resultWrapper = document.getElementById("result-search-prev")
      const cleanElement = document.getElementById("clean-search")
      
      cleanElement.classList.remove("is-hidden")
      resultWrapper.innerHTML = response.data
      this.searchTarget.parentNode.classList.remove("is-loading")
    }).catch(error=>{
      this.searchTarget.parentNode.classList.remove("is-loading")
    })
  }

  validateFundsforPrevetification(url){
    const errorSection = document.getElementById("new-pre-verification-error")
    const preverificationSection = document.getElementById("pre-verification-wrapper")
    axios.get(url).then(response => {
      preverificationSection.innerHTML = response.data;
      errorSection.style.display = "none"
    }).catch(error => {
      preverificationSection.innerHTML=""
      errorSection.style.display = "block"
      if(error.response){
        errorSection.innerHTML = error.response.data
      }
    })
  }

  notification(message, success){
    let addClass = ""
    const iconClass = (success == true ? "excellent" : "alert")
    
    if (success != true && success != false) {
      addClass = "is-warning"
    } else {
      addClass = (success ? "is-success" : "is-danger")
    }

    const notification = document.getElementById("sidebar-notification")
    const icon = document.getElementById("sidebar-notification-icon")
  
    icon.classList.remove("alert", "excellent")
    notification.parentElement.classList.remove("is-success", "is-danger", "is-warning")
    notification.parentElement.classList.add(addClass)
    icon.classList.add(iconClass)

    notification.innerHTML = message
    notification.parentElement.classList.remove("is-hidden")

    setTimeout(()=>{
      notification.parentElement.classList.add("is-hidden")
    }, 5000)
  }
}
