import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class  TopFivePieChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.PieChart)
    this.chart.data = this.data
    this.chart.fontSize = 11

    // Add and configure Series
    const pieSeries = this.chart.series.push(new am4charts.PieSeries())
    pieSeries.dataFields.value = 'amount'
    pieSeries.dataFields.category = 'name'
    pieSeries.slices.template.stroke = am4core.color('#FFFFFF')
    pieSeries.slices.template.strokeWidth = 2
    pieSeries.slices.template.strokeOpacity = 1
    pieSeries.labels.template.maxWidth = 140;
    pieSeries.labels.template.truncate = true;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1
    pieSeries.hiddenState.properties.endAngle = -90
    pieSeries.hiddenState.properties.startAngle = -90

    // Tooltip
    pieSeries.slices.template.tooltipText = "{category}: {value}";
  }
}

export default TopFivePieChart
