import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table"]

  filterRows(e) {
    if(e.target.value == "") {
      (this.tableTarget.querySelectorAll("[data-name]") || []).forEach((row) => {
        row.classList.remove("is-hidden")
      });

      return
    }

    (this.tableTarget.querySelectorAll("[data-name]") || []).forEach((row) => {
      row.classList.add("is-hidden")
    });

    (this.tableTarget.querySelectorAll(`[data-name^='${e.target.value.toUpperCase()}']`) || []).forEach((row) => {
      row.classList.remove("is-hidden")
    });
  }
}