import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { svgContainers } from '@amcharts/amcharts4/.internal/core/rendering/SVGContainer'

class PayrollDistributionChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
  }

  init () {
    const _this = this
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data

    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 50

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())

    const lineSeries = this.chart.series.push(new am4charts.LineSeries())
    lineSeries.dataFields.valueY = "accumulated_of_month"
    lineSeries.dataFields.dateX = "date"
    lineSeries.name = "Total de nómina"
    lineSeries.tooltipText = "{valueY.formatNumber('$#,###.##')}"
    lineSeries.fillOpacity = 0.5
    lineSeries.propertyFields.stroke = "lineColor"
    lineSeries.propertyFields.fill = "lineColor"
    lineSeries.fill = am4core.color("#66ccff")
    lineSeries.strokeWidth = 0

    const columnSeries = this.chart.series.push(new am4charts.ColumnSeries())
    columnSeries.dataFields.valueY = "amount"
    columnSeries.dataFields.dateX = "date"
    columnSeries.name = "Accionista"
    columnSeries.strokeWidth = 0

    columnSeries.adapter.add("tooltipText", function(text, target) {
      const dataItem = target.tooltipDataItem
      if(dataItem) {
        return Math.round(dataItem.dataContext.percentage) + "%"
      } else {
        text
      }
    })

    const avgSeries = this.chart.series.push(new am4charts.LineSeries())
    avgSeries.dataFields.valueY = "avg_of_month"
    avgSeries.dataFields.dateX = "date"
    avgSeries.name = "Promedio de nómina"
    avgSeries.tooltipText = "{valueY.formatNumber('$#,###.##')}"
    avgSeries.strokeWidth = 2

    // Add legend
    this.chart.legend = new am4charts.Legend()

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }
}

export default PayrollDistributionChart
