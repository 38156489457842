import { Controller } from "stimulus"
import ProjectionChart from './../charts/projection-chart'


export default class extends Controller {
  connect() {
    // Projection Chart
    const projectionCharts = document.querySelectorAll('.projection-chart')
    projectionCharts.forEach(function (projectionChart) {
      const id = projectionChart.getAttribute('id')
      const chart = window[id]
      const obj = new ProjectionChart(chart)
      obj.init()
    })
  }
}