import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import Chart from './chart'

class PieChart extends Chart {
  init () {
    this.chart = am4core.create(this.id, am4charts.PieChart)
    this.chart.data = this.data
    this.chart.fontSize = 11

    // Add and configure Series
    const pieSeries = this.chart.series.push(new am4charts.PieSeries())

    if(this.colorMapping) {
      pieSeries.colors.list = this.colorMapping.map(function(color) { return new am4core.color(color) })
    }

    pieSeries.dataFields.value = 'value'
    pieSeries.dataFields.category = 'label'
    pieSeries.slices.template.stroke = am4core.color('#FFFFFF')
    pieSeries.slices.template.strokeWidth = 2
    pieSeries.slices.template.strokeOpacity = 1
    pieSeries.labels.template.maxWidth = 100;
    pieSeries.labels.template.wrap = true;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1
    pieSeries.hiddenState.properties.endAngle = -90
    pieSeries.hiddenState.properties.startAngle = -90

    // Add legend
    this.chart.legend = new am4charts.Legend()
  }
}

export default PieChart
