import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["state",
                    "municipality",
                    "latitude",
                    "latitude",
                    "stateTab",
                    "municipalityTab"
                   ]
  connect() {
    this.renderMunicipalityMap()
  }

  renderStateMap() {
    this.stateTabTarget.classList.add("is-active")

    if(this.data.get("municipality").length === 0){
      this.municipalityTabTarget.classList.add("is-hidden")      
    }
    
    this.municipalityTabTarget.classList.remove("is-active")
    
    this.renderMap({
      zoom: 4.8,
      lat: 22.9546,
      lng: -102.4101,
      json_url: this.data.get("state")
    })
  }

  renderMunicipalityMap() {
    if (this.data.get("municipality").length == 0){
      return this.renderStateMap()
    }
    
    this.municipalityTabTarget.classList.add("is-active")
    this.stateTabTarget.classList.remove("is-active")

  
    this.renderMap({
      zoom: 9,
      lat: parseFloat(this.data.get("latitude")),
      lng: parseFloat(this.data.get("longitude")),
      json_url: this.data.get("municipality")
    })
  }

  renderMap(object) {
    const mapElement = document.getElementById('polygon-physic-map')

    let map = new google.maps.Map(mapElement, { 
      zoom: object.zoom,
      disableDefaultUI: true,
      center: {
        lat: object.lat,
        lng: object.lng
      }
    });

    map.data.setStyle(function() {
      return {
        fillColor: "green",
        strokeWeight: 1
      }
    })
    
    map.data.loadGeoJson(object.json_url,{}, function(_features){
      let bounds = new google.maps.LatLngBounds();

      map.data.forEach(function(feature) {
        let geo = feature.getGeometry();
  
        geo.forEachLatLng(function(LatLng) {
          bounds.extend(LatLng);
        });
      });
  
      map.fitBounds(bounds);
    });

  }
}
