// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;

window.onscroll = function (event) {
    didScroll = true;
};

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function documentVerticalScrollPosition() {
    if (self.pageYOffset) return self.pageYOffset; // Firefox, Chrome, Opera, Safari.
    if (document.documentElement && document.documentElement.scrollTop) return document.documentElement.scrollTop; // Internet Explorer 6 (standards mode).
    if (document.body.scrollTop) return document.body.scrollTop; // Internet Explorer 6, 7 and 8.
    return 0; // None of the above.
}

function hasScrolled() {
    let st = documentVerticalScrollPosition();
    let footerElement = document.getElementById('report-footer');
    let dropup_assign = document.querySelector('#dropup-assign-report')
    if(!footerElement) { return }

    let navbarHeight = footerElement.offsetHeight;

    if (Math.abs(lastScrollTop - st) <= delta)
        return;
    if (st > lastScrollTop && st > navbarHeight) {
        // Scroll Down
        footerElement.classList.remove('nav-down')
        footerElement.classList.add('nav-up');
    } else {
        // Scroll Up
        if (st == 0) {
            footerElement.classList.remove('nav-up')
            footerElement.classList.add('nav-down');
            if (dropup_assign) {
                dropup_assign.classList.remove('is-active')
            }
        }
    }
    lastScrollTop = st;
}
