class DataRow {
  constructor (element) {
    this.element = element
    this.observers = []
    this.target = this.element.nextElementSibling
    this.charts = []
    this.registerCharts()
    this.setEvents()
    this.setState('closed')
  }

  registerCharts () {
    const self = this

    if (this.target) {
      this.target.querySelectorAll('.bar-chart, .pie-chart').forEach(function (chart) {
        self.charts.push(chart.getAttribute('id'))
      })
    }
  }

  setEvents () {
    const self = this
    this.element.addEventListener('click', function (e) {
      e.preventDefault()
      if (self.state === 'opened') {
        self.close()
      } else {
        self.open()
      }
    })
  }

  close () {
    if (this.state === 'opened') {
      this.element.classList.remove('opened')
      this.target.classList.remove('opened')
      this.setState('closed')
    }
  }

  open () {
    if (this.state === 'closed') {
      this.element.classList.add('opened')
      this.target.classList.add('opened')
      this.setState('opened')
    }
  }

  setState (state) {
    this.state = state
    this.notifyObservers()
  }

  addObserver (observer) {
    this.observers.push(observer)
  }

  notifyObservers () {
    for (const o of this.observers) {
      o.update(this)
    }
  }
}

export default DataRow
