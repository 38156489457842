import { Controller } from "stimulus"
import LineSeriesChart from './../charts/line-series-chart'

export default class extends Controller {
  connect() {
    const LineSeriesCharts = document.querySelectorAll('.line-series-chart')
    LineSeriesCharts.forEach(function(finantialProductChart) {
      const id = finantialProductChart.getAttribute('id')
      const chart = window[id]
      var obj = new LineSeriesChart(chart)
      obj.init()
    })
  }
}