import { Controller } from "stimulus"
import DiversificationOfIncomesChart from './../charts/diversification-of-incomes-chart'

export default class extends Controller {
  connect() {
    // Diversification of incomes chart
    const DiversificationOfIncomesCharts = document.querySelectorAll('.diversification-of-incomes-chart')
    DiversificationOfIncomesCharts.forEach(function (diversificationOfIncomesChart) {
      const id = diversificationOfIncomesChart.getAttribute('id')
      const chart = window[id]
      chart.range = "twelve_months"
      var obj = new DiversificationOfIncomesChart(chart)
      window.diversificationOfIncomesChart = obj;
      obj.init()
    })
  }
}