import { Controller } from "stimulus";
import addonsManagementResources from './utils/addons-management-resources.js';

export default class extends Controller {
  static targets = ['selectorRow', 'managementSubmitBtn', 'sidebarNotification', 'credits', 'discount', 'cost', 'addonsItem'];

  connect(){
    const currentAddon = this.addonsItemTargets.find(i => i.checked == true)
    this.selectorRowTarget.dataset.actualOption = currentAddon.id

    this.setAddon(true);
  }
  
  submit(e) {
   e.preventDefault();
    e.target.classList.add("is-loading");
    e.target.disabled = true;
    e.target.form.requestSubmit();
  }

  setAddon(isOnLoad){
    const currentAddon = document.querySelector('input[name="addons_credits_update[id]"]:checked')
    const disableSubmitBtn = isOnLoad && this.selectorRowTarget.dataset.actualOption == currentAddon.id

    if(currentAddon){
      const addon = JSON.parse(currentAddon.dataset.addon)
      this.creditsTarget.textContent = addon.quantity
      this.discountTarget.textContent = `${addon.discount} %`
      this.costTarget.textContent = `$ ${addon.cost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    }

    this.managementSubmitBtnTarget.disabled = disableSubmitBtn
  }

  sendManagmentForm(e){ 
    addonsManagementResources.sendManagmentForm(e, 
      'credits', 
      this.sidebarNotificationTarget, 
      this.selectorRowTarget
    ) 
  }
}
