import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'rfcInput', 'ciecInput', 'termsCheckbox', 'submitButton' ]

  connect(){
    this.rfcRegex = /(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})/

    this.validateCredentialsForm()
  }


  validateRfc(){
    let rfc = this.rfcInputTarget.value

    if (rfc.match(this.rfcRegex)){
      this.rfcInputTarget.dataset.valid = true;
      this.rfcInputTarget.classList.remove("is-danger")
    } else {
      this.rfcInputTarget.dataset.valid = false;
      this.rfcInputTarget.classList.add("is-danger")
    }

    this.validateCredentialsForm()
  }

  validateCiec(){
    let ciec = this.ciecInputTarget.value

    if (ciec.length >= 8){
      this.ciecInputTarget.dataset.valid = true;
      this.ciecInputTarget.classList.remove("is-danger")
    } else {
      this.ciecInputTarget.dataset.valid = false;
      this.ciecInputTarget.classList.add("is-danger")
    }

    this.validateCredentialsForm()
  }

  validateTerms(){
    let terms = this.termsCheckboxTarget

    if (terms.checked){
      this.termsCheckboxTarget.dataset.valid = true;
    } else {
      this.termsCheckboxTarget.dataset.valid = false;
    }

    this.validateCredentialsForm()
  }

  validateRecaptcha() {
    let recaptchaResponse = document.getElementById('g-recaptcha-response')

    return recaptchaResponse.value.length > 0
  }

  validateCredentialsForm(){
    let rfc = this.rfcInputTarget.dataset.valid
    let ciec = this.ciecInputTarget.dataset.valid
    let terms = this.termsCheckboxTarget.dataset.valid

    if (rfc == "true" && ciec == "true" && terms == "true"){
      if (this.validateRecaptcha()){
        this.submitButtonTarget.disabled = false
      } else {
        this.submitButtonTarget.disabled = true
      }
    }
  }

  uppercaseValue(event){
    event.target.value = event.target.value.toUpperCase()
  }
}
