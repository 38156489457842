import { Controller } from 'stimulus';
import axios from "../packs/setupCSRFToken";

export default class extends Controller {
  static targets = ["subscription", "liveScores", "balance"]
  connect () {
    this.fetchReportsCount()
  }
  
  fetchReportsCount() {
    const reportsCountElements = document.querySelectorAll("[data-reports-count-for]")
    reportsCountElements.forEach((element) => {
      axios.get(element.dataset.reportsCountUrl)
      .then(({ data }) => {
        element.innerText = data.report_count
        let userId = element.dataset.reportsCountFor
        let preverificationElement = document.querySelector(`[data-pre-verifications-count-for='${userId}']`)
        if (preverificationElement) {
          preverificationElement.innerText = data.pre_verification_count
        } 
      })
    })
  }

  showSubscriptionInfo(e) {
    const selectedOption = e.target.value;
    document.querySelectorAll("[data-subscription-type]").forEach((e) => { e.classList.add("is-hidden") });
    document.querySelectorAll(`[data-subscription-type="${selectedOption}"]`).forEach((e) => { e.classList.remove("is-hidden")});
  }

  fetchOperationalUserWalletData() {
    let counter = 0;

    const intervalId = setInterval(() => {
      counter += 1;
      (document.querySelectorAll("[data-user-wallet-row='fetch']") || []).forEach((row) => {
        axios.get(row.dataset.userWalletUrl)
        .then(({ data }) => {
          if( data.user_id ) {
            row.dataset.userWalletRow = ""
            let subscriptionName = document.querySelector(`[data-subscription-name-for="${data.user_id}"]`)
            subscriptionName.classList.remove("is-loading")
            subscriptionName.innerText = data.subscription_name

            let liveScores = document.querySelector(`[data-live-scores-for="${data.user_id}"]`)
            liveScores.classList.remove("is-loading")
            liveScores.innerText = data.live_scores

            let balance = document.querySelector(`[data-balance-for="${data.user_id}"]`)
            balance.classList.remove("is-loading")
            balance.innerText = data.balance

            let next_plan = document.querySelector(`[data-next_plan-for="${data.user_id}"]`)
            next_plan.classList.remove("is-loading")
            next_plan.innerText = data.next_plan

            clearInterval(intervalId);
          }
        })
      })

      if(counter >= 10 ) {
        clearInterval(intervalId);
        (document.querySelectorAll(".is-loading") || []).forEach((e) => {
          e.classList.remove("is-loading")
        })
      }
    }, 3000);
  }
}
