import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "website",
    "externalReport",
    "defaultAlert"
  ]

  static values = {
    url: String
  }

  connect() {
    this.websiteValue = document.getElementById("preview_website")
    this.externalReportValue = document.getElementById("preview_external_report")
    this.addEvents()
    this.activeSection()
  }

  website(){
    this.websiteValue.classList.remove("is-hidden")
    this.externalReportValue.classList.add("is-hidden")
  }

  externalReport(){
    this.externalReportValue.classList.remove("is-hidden")
    if (this.websiteValue) this.websiteValue.classList.add("is-hidden")
  }

  changeCover(){
    const checkbox = document.getElementById("branding_cover_hero")
    const banner = document.getElementById("preview-banner")
    const defaultBanner = banner.getAttribute('src').includes("slogan")

    if(defaultBanner){
      this.defaultAlertTarget.textContent = 'Debes usar una imagen diferente a la predeterminada.'
      
      setTimeout(() => {
        this.defaultAlertTarget.textContent = ''
      }, 3000);

      checkbox.checked = false 
      return
    }

    if (checkbox.checked){
      banner.style.width = "100%"
      banner.style.height = "100%"
      banner.style.marginTop = "0"
    }else{
      banner.style.width = "28%"
      banner.style.height = "60px"
      banner.style.marginTop = "1.9rem"
    }
  }

  activeSection(){
    const urlParams = new URLSearchParams(window.location.search);
    const section = urlParams.get('section');
    const preview = urlParams.get('preview');

    if (!section) return;

    if(section === "redirect" && preview === "website"){
      this.website()
    }

    let options = document.querySelectorAll("[data-option]");
    this.resetCurrentSection(options)
    this.showCurrentSection(section)
  }

  addEvents(){
    let options = document.querySelectorAll("[data-option]");
    options.forEach((element) => {
      element.addEventListener("click", (e) => {
        let section = e.target.dataset.section
        this.resetCurrentSection(options)
        this.showCurrentSection(section)
      });
    });
  }

  showCurrentSection(section){
    let sectionElement = document.getElementById(section);
    sectionElement.classList.add("is-active")
    sectionElement.children[0].classList.add("has-text-link")

    let currentSection = document.querySelector(`div[data-section='${section}']`);

    document.querySelectorAll("div[data-section]").forEach((section) => section.classList.add("is-hidden"))
    currentSection.classList.remove("is-hidden")
  }

  resetCurrentSection(options){
    options.forEach((option) => {
      option.classList.remove("is-active")
      option.children[0].classList.remove("has-text-link")
    });
  }
  submitForm(e) {
    const form = e.target.closest('form');
    Rails.fire(e.target.form, 'submit');
  }

  enableMadatoryShareholdersOpenUrl(){
    const subtmitBtn = document.getElementById("regenerate_submit_btn")
    const confirmValue = subtmitBtn.getAttribute("data-confirm") || subtmitBtn.getAttribute("data-confirm-disabled")

    if (subtmitBtn.value == "Guardar"){
      subtmitBtn.value = "Regenerar token"
      subtmitBtn.removeAttribute("data-confirm-disabled")
      subtmitBtn.setAttribute("data-confirm", confirmValue)
    }else{
      subtmitBtn.value = "Guardar"
      subtmitBtn.removeAttribute("data-confirm")
      subtmitBtn.setAttribute("data-confirm-disabled", confirmValue)
    }
  }

  setOpenUrlConfirm(e){
    if(e.target.dataset.tokenPresent == 'true') return
    e.target.removeAttribute('data-confirm')
  }
}