import { FormValidationController } from "./form_validation_controller";

export default  class BulmaFormValidation extends FormValidationController {
  render(target, validation) {
    let validator = validation.find((validator) => !validator.result.valid );

    this.clearValidation(target);

    if(validator) {
      this.renderValidation(target, validator);
    }
  }

  clearValidation(target) {
    target.classList.remove("is-danger");
    let $parent = target.parentNode;
    let help = $parent.querySelector("p.help");
    if(help) {
      $parent.removeChild(help);
    }
  }

  renderValidation(target, validator) {
    if(target.classList.contains("is-danger")) { return }

    target.classList.add("is-danger");
    if(target.nodeName == "SELECT") { target.parentNode.classList.add("is-danger") }
    let help = document.createElement("p")
    let error = validator.result.message
    help.setAttribute("class", "help is-danger")
    help.innerHTML = error;

    let $parent = target.parentNode;
    $parent.appendChild(help);
  }
}
