import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class LiquityChart {

  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.firstYear = parseInt(chart.firstYear)
    this.secondYear = parseInt(chart.secondYear)
    this.thirdYear = parseInt(chart.thirdYear)
    this.fourthYear = parseInt(chart.fourthYear)
  }

  init () {

    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.colors.step = 2;

    this.chart.legend = new am4charts.Legend()
    this.chart.legend.position = 'top'
    this.chart.legend.paddingBottom = 20
    this.chart.legend.labels.template.maxWidth = 95

    var xAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'category'
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;

    var yAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.extraMax = 0.2
    //yAxis.renderer.labels.template.tooltipText = "{category}";

    this.chart.data = this.data

    if(this.data[0]["first_year_data"] != null) this.createSeries('first_year_data', this.firstYear.toString());
    if(this.data[0]["second_year_data"] != null) this.createSeries('second_year_data', this.secondYear.toString());
    if(this.data[0]["third_year_data"] != null) this.createSeries('third_year_data', this.thirdYear.toString());
    if(this.data[0]["fourth_year_data"] != null) this.createSeries('fourth_year_data', this.fourthYear.toString());
  }

  createSeries(value, name) {
    var series = this.chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = value
    series.dataFields.categoryX = 'category'
    series.name = name
    series.columns.template.tooltipText = "{categoryX.formatNumber('#,###.##')}: [bold]{valueY.formatNumber('#,###.##')}[/]";

    series.events.on("hidden", this.arrangeColumns);
    series.events.on("shown", this.arrangeColumns);

    var bullet = series.bullets.push(new am4charts.LabelBullet())
    bullet.interactionsEnabled = false
    bullet.dy = -7;
    bullet.label.text = "{valueY.formatNumber('#,###.##')}"
    bullet.label.fill = am4core.color('#000000')

    return series;
  }

  arrangeColumns() {
    if(this == undefined) return
    var series = this.chart.series.getIndex(0);

    var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / this.chart.series.length) * w;
        if (am4core.isNumber(delta)) {
            var middle = this.chart.series.length / 2;

            var newIndex = 0;
            this.chart.series.each(function(series) {
                if (!series.isHidden && !series.isHiding) {
                    series.dummyData = newIndex;
                    newIndex++;
                }
                else {
                    series.dummyData = this.chart.series.indexOf(series);
                }
            })
            var visibleCount = newIndex;
            var newMiddle = visibleCount / 2;

            this.chart.series.each(function(series) {
                var trueIndex = this.chart.series.indexOf(series);
                var newIndex = series.dummyData;

                var dx = (newIndex - trueIndex + middle - newMiddle) * delta

                series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            })
        }
    }
  }

}

export default LiquityChart;

