
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal",
                    "modalTitle",
                    "modalMessage",
                    "actions",
                    "updateBtn",
                    "activateReportBtn",
                    "updateActivateReportForm",
                    "insufficientFundsModal",
                    "updateReportForm",
                    "activateReportForm",
                    "buyLiveScoresModal",
                    "cancelBtn"]

  showModal(e) {
    e.preventDefault()
    e.stopPropagation()
    const form = e.target.closest('form')
    let totalLiveScores = +e.target.dataset.liveScores
    let walletBalance = +form.dataset.balance
    let walletPostPayment = form.dataset.postPayment === "true"
    e.target.classList.add("is-loading")
    this.cancelBtnTarget.disabled = false

    if (walletBalance < 50 && !walletPostPayment) {
      this.insufficientFundsModalTarget.classList.add("is-active")
      return
    }

    this.modalTitleTarget.innerText = e.target.dataset.modalTitle
    this.modalMessageTarget.innerText = e.target.dataset.modalMessage
    this.updateBtnTarget.classList.remove("is-hidden")
    this.updateBtnTarget.innerText = e.target.dataset.modalButtonText
    this.updateBtnTarget.dataset["formAction"] = e.target.dataset.formAction

    if(totalLiveScores == 0) {
      this.buyLiveScoresModalTarget.classList.add("is-active")
      return
    }

    this.modalTarget.classList.add("is-active")
  }

  showUpdateAndActivateModal(e) {
    e.preventDefault()
    const form = e.target.closest('form')

    let totalLiveScores = +form.dataset.liveScores
    let walletBalance = +form.dataset.balance
    let walletPostPayment = form.dataset.postPayment === "true"
    e.target.classList.add("is-loading")
    this.cancelBtnTarget.disabled = false

    if (walletBalance < 50 && !walletPostPayment) {
      this.insufficientFundsModalTarget.classList.add("is-active")
      e.target.classList.remove("is-loading")
      return
    }

    this.modalTitleTarget.innerText = e.target.dataset.modalTitle
    this.modalMessageTarget.innerText = e.target.dataset.modalMessage
    this.updateBtnTarget.classList.remove("is-hidden")
    this.updateBtnTarget.innerText = e.target.dataset.modalButtonText
    this.updateBtnTarget.dataset["formAction"] = e.target.dataset.formAction


    if(totalLiveScores == 0) {
      this.buyLiveScoresModalTarget.classList.add("is-active")
      return
    }

    this.modalTarget.classList.add("is-active")
  }

  submitRequest(e) {
    e.target.classList.add("is-loading")
    this.cancelBtnTarget.disabled = true
    this[e.target.dataset.formAction]();
  }

  activateReport() {
    this.modalTarget.classList.remove("is-active");
    this.activateReportFormTarget.submit();
    this.actionsTarget.innerHTML = "";
  }

  updateReport() {
    this.updateReportFormTarget.submit()
  }

  updateAndActivateReport() {
    this.updateActivateReportFormTarget.submit()
  }

  cancelAction(e) {
    e.preventDefault()
    let modal = e.target.closest(".modal")
    let btn = document.querySelector(".reports-footer .button.is-loading")
    if (btn) { btn.classList.remove("is-loading") }

    modal.classList.remove("is-active")
  }

  showInsufficientFundsModal(e) {
    e.preventDefault()
    this.insufficientFundsModalTarget.classList.add("is-active")
  }
}
