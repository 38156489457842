import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "addNewFieldsLink", "shareholderForm", "shareholderDisabledSection", "addShareholderSection"]

  connect(){
    this.rfcRegex = /(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})/
    this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }

  validateForm(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    let continueButton = document.querySelector("button#continue");
    let submitButton = document.querySelector("input[type='submit']");

    this.validateCiecAndShareholdersDataPresence(e)
    const errors = document.querySelectorAll(".help.is-danger");

    if(errors.length) return

    if(this.activate_inputs(true)) {
      let shareholdersList = document.querySelector("[data-shareholders-list]");

      if (shareholdersList == null) {
        let $currentStep = document.querySelector("[data-current-step");
        $currentStep.value = "processing_step";
      }

      if (continueButton) continueButton.setAttribute("disabled", "disabled");
      if (submitButton) submitButton.setAttribute("disabled", "disabled");

      e.target.form.submit();
    }
  }

  activate_inputs(opt){
    const inputs = document.querySelectorAll("input.input-shareholder-form")
    const rfcInput = document.getElementById("external_report_new_form_rfc")
    const ciecInput = document.getElementById("external_report_update_form_ciec") || document.getElementById("external_report_new_form_ciec");

    if(opt){
      if(rfcInput) rfcInput.removeAttribute("disabled")
      if(ciecInput) ciecInput.removeAttribute("disabled")
      inputs.forEach(element => {element.removeAttribute("disabled")});
    }else{
      if(ciecInput) ciecInput.setAttribute("disabled", true)
      if(rfcInput) rfcInput.setAttribute("disabled", true)
      inputs.forEach(element => {element.setAttribute("disabled", true)});
    }
    return true
  }

  addNew(e) {
    e.preventDefault()
    let externalSubmitBtn = document.querySelector("button#continue")
    if (externalSubmitBtn != undefined)
      externalSubmitBtn.setAttribute("disabled", "disabled")

    const shareholdersList = document.querySelector("[data-shareholders-list]");
    const shareholderCount = shareholdersList.childElementCount;
    let shareholderId = +shareholdersList.lastElementChild.dataset.formId + 1;

    if(shareholderCount >= 10) {
      const addShareholder = document.querySelector("[data-link='add-shareholder']");
      addShareholder.classList.add("is-hidden");
      return
    }
    const regexp = new RegExp(e.target.dataset.id, "g");
    const element = document.createElement('div')

    element.setAttribute("data-target", "shareholders-form.shareholderForm")
    element.setAttribute("data-form-id", shareholderId)
    element.innerHTML = e.target.dataset.fields.replace(regexp, shareholderId)
    shareholdersList.appendChild(element);

  }

  closeForm(e) {
    e.preventDefault()
    const shareholdersList = document.querySelector("[data-shareholders-list]");
    const shareholderFormCount  = shareholdersList.childElementCount;
    const shareholderForm = e.target.closest("[data-target='shareholders-form.shareholderForm']")
    shareholderForm.remove()

    if(shareholderFormCount <= 10) {
      const addShareholder = document.querySelector("[data-link='add-shareholder']");
      addShareholder.classList.remove("is-hidden");
    }
  }

  validatePercent(e) {
    let totalParticipation = 0;
    if(parseInt(e.target.value) > 100 ) { e.target.value = 100; }
    if(parseInt(e.target.value) < 0 ) { e.target.value = 0; }

    document.querySelectorAll("[data-name='participation']").forEach(element => {
      this.removeErrorMsg(element);
      totalParticipation += Number(element.value);
    });

    if(totalParticipation > 100) {
      e.target.classList.add("is-danger");
      const participationAvailable = 100 - (totalParticipation - Number(e.target.value));
      const message = `La suma de participaciones es mayor al 100%. Disponible: ${participationAvailable}%`
      this.showErrorMsg(message, e);
      return false
    }
    return true
  }

  cleanShareholderName(name){
    return name.trim().normalize("NFD").replaceAll(/[\u0300-\u036f]/g, "").replaceAll(' ','').replaceAll(',','').replaceAll('.','').toUpperCase()
  }

  validatesDuplicatedNAME(e) {
    const parentElement = e.target.closest('.shareholderFormContainer')
    const rfc = parentElement.querySelector("[data-field='rfc']")

    if (rfc.value.length === 12) {
      const businessName = this.cleanShareholderName(e.target.value)
      this.validateDuplicateBusinessName(businessName, e)
    } else {
      const parentElement = e.target.parentElement.parentElement.parentElement
      const firstNameInput = parentElement.querySelector("[data-field='first-name']")
      const lastNameInput = parentElement.querySelector("[data-field='last-name']")
      const mothersLastNameInput = parentElement.querySelector("[data-field='mothers-last-name']")
      const fullName = `${firstNameInput.value} ${lastNameInput.value} ${mothersLastNameInput.value}`
      const cleanFullName =  this.cleanShareholderName(fullName)

      this.validateDuplicateFullName(cleanFullName, firstNameInput);
    }

  }

  validateDuplicateBusinessName(businessName, e) {
    const businessNameInput = e.target
    const businessNameInputs = document.querySelectorAll("[data-field='business-name']")
    let duplicated_name = []

    businessNameInputs.forEach(element => {
      if(this.cleanShareholderName(element.value) == businessName){
        duplicated_name.push(element)
      }
    });

    if(duplicated_name.length > 1 && businessName.trim() != '') {
      businessNameInput.classList.add("is-danger");
      this.showErrorMsg("Ya existe un accionista con ese nombre.", e);
      return true
    }else{
      businessNameInputs.forEach(element => {
        element.target = element
        if(element.value.trim() != '') this.removeErrorMsg(element)
      });
      return false
    }
  }

  validateDuplicateFullName(fullName, e) {
    const firstNameInput = e.target
    const firstNameInputs = document.querySelectorAll("[data-field='first-name']")
    let duplicated_name = []

    firstNameInputs.forEach((item) => {
      const parentElement = item.parentElement.parentElement.parentElement
      const lastNameInput = parentElement.querySelector("[data-field='last-name']")
      const mothersLastNameInput = parentElement.querySelector("[data-field='mothers-last-name']")
      const existingFullName = `${item.value} ${lastNameInput.value} ${mothersLastNameInput.value}`

      if(this.cleanShareholderName(existingFullName) === fullName){
        duplicated_name.push(item)
      }
    })

    if(duplicated_name.length > 1 && fullName.trim() != '') {
      firstNameInput.classList.add("is-danger");
      this.showErrorMsg("Ya existe un accionista con ese nombre.", e);
      return true
    }else{
      firstNameInputs.forEach(element => {
        element.target = element
        if(element.value.trim() != '') this.removeErrorMsg(element)
      });
      return false
    }
  }

  validatesDuplicatedRFC(e) {
    e.target.setAttribute("value", e.target.value.toUpperCase());

    let duplicated_rfc = []

    const inputs = document.querySelectorAll(`[data-field='rfc']`)

    inputs.forEach(element => {
      if(element.value == e.target.value)
        duplicated_rfc.push(element)
    });

    if(duplicated_rfc.length > 1 && e.target.value.toUpperCase().trim() != '') {
      e.target.classList.add("is-danger");
      this.showErrorMsg(`Ya existe un accionista con el RFC: ${e.target.value.toUpperCase()}`, e);
      return false
    }

    return true
  }

  termsOFServiceSelected(e) {
    this.validateCiecAndShareholdersDataPresence(e)
    if(!e.target.checked){
      document.querySelector('#continue').setAttribute("disabled", "disabled")
      this.activate_inputs(true)
    }
  }

  validateMandatoryShareholder(e){
    const mandatory_shareholder = e.target.getAttribute('data-mandatory-shareholder') == 'true'
    const nameInputs = document.querySelectorAll(".shareholder-name-input")

    if(mandatory_shareholder && nameInputs.length == 0){
      document.querySelector("a[data-link = 'add-shareholder']").click()
    }
  }

  validateCiecAndShareholdersDataPresence(e) {
    this.validateMandatoryShareholder(e)

    const rfcInput = document.getElementById("external_report_new_form_rfc");
    const ciecInput = document.getElementById("external_report_update_form_ciec") || document.getElementById("external_report_new_form_ciec");
    const nameInputs = document.querySelectorAll(".shareholder-name-input")
    const rfcInputs = document.querySelectorAll(".shareholder-rfc-input")
    const participationInputs = document.querySelectorAll(".shareholder-participation-input")
    const continueBtn = document.querySelector('#continue')

    if (rfcInput) {
      rfcInput.focus();
      rfcInput.blur();
    }

    if (ciecInput) {
      ciecInput.focus();
      ciecInput.blur();
    }

    this.validateRecaptcha()

    participationInputs.forEach(element => {
      element.target = element
      this.validateField(element)
    });

    let namesBreak = false
    nameInputs.forEach(element => {
      if(namesBreak) return
      element.target = element
      if(element.parentElement.parentElement.classList.contains("d-none")) return

      if(element.value.trim() == ''){
        this.showErrorMsg("El campo no puede estar vacio", element)
      }
      namesBreak = this.validatesDuplicatedNAME(element)
    });

    rfcInputs.forEach(element => {
      element.target = element
      if(element.value.trim() == ''){
        this.showErrorMsg("El campo no puede estar vacio", element)
      }
      this.validatesDuplicatedRFC(element)
    });

    let errors = document.querySelectorAll(".help.is-danger");

    if (errors.length > 0) {
      const termsInput = document.querySelector('#terms-of-service')

      if(ciecInput) continueBtn.setAttribute("disabled", "disabled")
      if(termsInput && termsInput.checked) termsInput.checked = false
    } else {
      if(ciecInput) continueBtn.removeAttribute("disabled", false)
      this.activate_inputs(false)
    }
  }

  validateField(e) {
    if(e.target.value.trim() == "") {
      if (e.target.dataset.field === 'rfc') {
        this.hideShareholderNameForm(e.target)
      }
      return this.showErrorMsg("El campo no puede estar vacio", e)
    }

    if (e.target.dataset.field == "rfc") {
     if(!this.validateRFC(e)) return
     if(!this.validatesDuplicatedRFC(e)) return
     if(!this.validatesClientRFC(e)) return

     this.showShareholderNameForm(e.target);
    }

    if(e.target.getAttribute("name").includes("participation")){
      if(!this.validatePercent(e)) return
    }

    this.removeErrorMsg(e.target)
  }

  hideShareholderNameForm(rfcInput) {
    const parentElement = rfcInput.closest('.shareholderFormContainer');
    const shareholderNameForm = parentElement.querySelector('.shareholderNameForm');
    const legalEntityInputs = shareholderNameForm.querySelectorAll('.legal-entity');
    const individualInputs = shareholderNameForm.querySelectorAll('.individual');

    (legalEntityInputs || []).forEach((item) => {
      item.value = '';
      item.querySelector('.is-danger')?.classList.remove('is-danger');
      this.removeErrorMsg(item);
      item.classList.add('d-none');
    });
    (individualInputs || []).forEach((item) => {
      item.value = '';
      item.querySelector('.is-danger')?.classList.remove('is-danger');
      this.removeErrorMsg(item);
      item.classList.add('d-none');
    });
  }

  showShareholderNameForm(rfcInput) {
    const parentElement = rfcInput.closest('.shareholderFormContainer');
    const shareholderNameForm = parentElement.querySelector('.shareholderNameForm');
    const legalEntityInputs = shareholderNameForm.querySelectorAll('.legal-entity');
    const individualInputs = shareholderNameForm.querySelectorAll('.individual');
    const legalEntity = rfcInput.value.length === 12;

    legalEntityInputs.forEach((item) => {
      item.classList.toggle('d-none', !legalEntity);
    })
    individualInputs.forEach((item) => {
      item.classList.toggle('d-none', legalEntity);
    })
  }

  validateRFC(e) {
    if(!this.rfcRegex.test(e.target.value)) {
      this.showErrorMsg("El RFC no es valido", e)
      return false
    }
    return true
  }

  validatesClientRFC(e) {
    document.querySelectorAll("[data-field='rfc']").forEach(element => { this.removeErrorMsg(element) });
    e.target.setAttribute("value", e.target.value.toUpperCase());

    const elRfc = document.querySelector('[data-rfc-report]');
    const rfcClient = elRfc.dataset.rfcReport

    if(rfcClient.toUpperCase() === e.target.value.toUpperCase()) {
      e.target.classList.add("is-danger");
      this.showErrorMsg('No puedes ingresar el RFC del reporte como un accionista', e);
      return false
    }

    return true
  }

  validateRecaptcha() {
    const recaptchaResponse = document.getElementById('g-recaptcha-response')
    const recaptchaContainer = document.getElementById('report-recaptcha')
    const recaptchaError = document.getElementById('recaptcha-error')

    if (!recaptchaResponse && !recaptchaContainer) return;

    if (document.contains(recaptchaError)){
      recaptchaError.remove();
    }

    if (recaptchaResponse.value.length != 0 ) return;

    let recaptchaErrorElement = document.createElement('p');
    recaptchaErrorElement.setAttribute('id', 'recaptcha-error');
    recaptchaErrorElement.textContent = 'Recaptcha obligatorio';
    recaptchaErrorElement.setAttribute('class', 'help is-danger');

    recaptchaContainer.appendChild(recaptchaErrorElement)
  }

  showErrorMsg(msg, element) {
    let $parent = element.target.parentNode
    const messageElement = $parent.lastChild
    const input = element.target

    input.classList.add("is-danger")

    if(messageElement.tagName == "P"){
      messageElement.classList.add("help")
      messageElement.classList.add("is-danger")
      messageElement.classList.remove("is-hidden")
      $parent.lastChild.innerHTML = msg
      return
    }

    let help = document.createElement("p")
    help.setAttribute("class", "help is-danger")
    help.innerHTML = msg
    $parent.appendChild(help);
  }

  removeErrorMsg(e) {
    let $parent = e.parentNode;
    e.classList.remove("is-danger")
    $parent.querySelectorAll(".help").forEach(e => { e.remove () });
    e.classList.remove("is-danger");
  }

  validateLegalRfc(e){
    let shareholdersInputs = e.target.form.querySelectorAll('input[id*="shareholder"]')

    if(e.target.value.length == 12){
      this.shareholderDisabledSectionTarget.classList.add('is-hidden')
      this.addShareholderSectionTarget.classList.remove('is-hidden')
    }else{
      this.shareholderDisabledSectionTarget.classList.remove('is-hidden')
      this.addShareholderSectionTarget.classList.add('is-hidden')

      shareholdersInputs.forEach(element => {
        element.value = ''
      });
    }
  }
}
