import { Controller } from "stimulus"
import TopFiveLineChart from './../charts/top-five-line-chart'

export default class extends Controller {
  connect() {
    // Top five chart
    const TopFiveLineCharts = document.querySelectorAll('.top-five-line-chart')
    TopFiveLineCharts.forEach(function (topFiveLineChart) {
      const id = topFiveLineChart.getAttribute('id')
      const chart = window[id]
      var obj = new TopFiveLineChart(chart)
      obj.init()
    })
  }
}