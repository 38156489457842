import { Controller } from 'stimulus';
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["container", "email", "checkbox", "submit"]

  append(event) {
    const [data, _status, _xhr] = event.detail;

    let resultList = document.getElementById("operational-users-result")
    resultList.innerHTML = data.html_content

    let operationalUserCount = document.getElementById("operational-users-count")

    if (operationalUserCount) {
      operationalUserCount.innerText = data.operational_users_count
    }

    if (data.not_available) {
      this.containerTarget.innerHTML = data.html_not_available
    } else {
      this.clearInputs()
      this.submitTarget.classList.remove("is-loading")
    }

    this.notification(data.message, true)
  }

  showError(event) {
    const [data, status, xhr] = event.detail;
    this.submitTarget.classList.remove("is-loading")

    const errorMessage = data.error || 'Ocurrió un error al guardar el usuario'
    this.notification(errorMessage, false)
  }

  onSubmit(e) {
    e.preventDefault()
    e.stopPropagation()
    
    if (this.hasEmailTarget) {
      this.emailTarget.focus();
      this.emailTarget.blur();
    }

    let errors = this.containerTarget.querySelectorAll("input.is-danger");
    if (errors.length > 0) {
      errors[0].focus()
    } else {
      this.submitTarget.classList.add("is-loading")
      Rails.fire(e.target.form, 'submit')
    }
  }

  enableRemoveSubmit(e){
    const removeSubmit = document.getElementById('remove-operational-user-submit')
    if (!removeSubmit) return

    removeSubmit.disabled = false
  }

  clearInputs() {
    if (this.hasEmailTarget) {
      this.emailTarget.value = ""
    }

    if (this.hasCheckboxTarget) {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.checked = false
      })
    }
  }

  notification(message, success) {
    const addClass = (success ? "is-success" : "is-danger")
    const removeClass = (success ? "is-danger" : "is-success")
    const iconClass = (success ? "excellent" : "alert")

    const notification = document.getElementById("sidebar-notification")
    const icon = document.getElementById("sidebar-notification-icon")
    icon.classList.add(iconClass)
    notification.parentElement.classList.remove(removeClass)
    notification.parentElement.classList.add(addClass)
    notification.parentElement.classList.remove(removeClass)
    notification.innerHTML = message
    notification.parentElement.classList.remove("is-hidden")

    setTimeout(() => {
      notification.parentElement.classList.add("is-hidden")
    }, 5000)
  }
}
