import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'filename', 'delete'];
  
  connect(){
    this.selectLogo()
  }

  selectLogo() {
    const hasFile = this.inputTarget.files.length > 0;
    this.updateFilename(hasFile);
    this.toggleFilenameStyle(hasFile);
    if (hasFile) {
      const previewId = this.inputTarget.dataset.preview;
      this.showPreview(previewId);
      this.showDeleteButton();
    }
  }

  clearImage(event) {
    event.preventDefault();

    const { defaultLogo, preview: previewId } = this.inputTarget.dataset;
    this.resetInput();
    this.resetFilename();
    this.resetPreview(previewId, defaultLogo);
    this.resetFilenameStyle();
    this.hideDeleteButton();
  }

  updateFilename(hasFile) {
    const fileName = hasFile ? this.inputTarget.files[0].name : 'Subir Logotipo';
    this.filenameTarget.textContent = fileName;
  }

  toggleFilenameStyle(hasFile) {
    if (hasFile) {
      this.filenameTarget.classList.add('has-text-black');
    } else {
      this.filenameTarget.classList.remove('has-text-black');
    }
  }

  showPreview(imgId) {
    const file = this.inputTarget.files[0];
    if (file) {
      this.loadImage(file, (src) => {
        const previewImg = document.getElementById(imgId);
        previewImg.src = src;
      });
    }
  }

  loadImage(file, callback) {
    const reader = new FileReader();
    reader.onload = function (e) {
      callback(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  showDeleteButton() {
    this.deleteTarget.classList.remove('is-hidden');
  }

  hideDeleteButton() {
    this.deleteTarget.classList.add('is-hidden');
  }

  resetInput() {
    this.inputTarget.value = '';
  }

  resetFilename() {
    this.filenameTarget.textContent = 'Subir Logotipo';
  }

  resetPreview(imgId, defaultSrc) {
    const previewImg = document.getElementById(imgId);
    previewImg.src = defaultSrc;
  }

  resetFilenameStyle() {
    this.filenameTarget.classList.remove('has-text-black');
  }
}