import { Controller } from "stimulus";
import addonsManagementResources from './utils/addons-management-resources.js';

export default class extends Controller {
  static targets = ['resource', 'managementSubmitBtn', 'sidebarNotification', 'selectorRow', 'discount', 'cost', 'addonsItem'];

  connect() {
    if(!this.targets.find('selectorRow')) return

    const currentAddon = this.addonsItemTargets.find(i => i.checked == true)
    this.selectorRowTarget.dataset.actualOption = currentAddon.id

    this.setAddon(true);
  }

  setAddon(isOnLoad) {
    if (!this.hasAddonsItemTarget) return;
    
    const currentAddon = this.addonsItemTargets.find(i => i.checked == true)
    const disableSubmitBtn = isOnLoad && this.selectorRowTarget.dataset.actualOption == currentAddon.id

    if(currentAddon){
      const addon = JSON.parse(currentAddon.dataset.addon)
      this.resourceTarget.textContent = addon.quantity
      this.discountTarget.textContent = `${addon.discount} %`
      this.costTarget.textContent = `$ ${addon.cost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    }

    this.managementSubmitBtnTarget.disabled = disableSubmitBtn
  }

  sendManagmentForm(e){ 
    addonsManagementResources.sendManagmentForm(e, 
      'pre_verifications', 
      this.sidebarNotificationTarget, 
      this.selectorRowTarget
    ) 
  }
}
