import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['menuOption'];

  highlightOption(e){
    this.resetHighlightedOptions()

    e.target.classList.add('is-active')
  }

  resetHighlightedOptions(){
    this.menuOptionTargets.forEach(option => {
      option.classList.remove('is-active')
    });
  }
}
