import { Controller } from "stimulus"
import ExternalPayrollChart from './../charts/external-payroll-chart'

export default class extends Controller {
  connect() {
    const ExternalPayrollCharts = document.querySelectorAll('.external-payroll-chart')
    ExternalPayrollCharts.forEach(function (externalPayrollChart) {
      const id = externalPayrollChart.getAttribute('id');
      const chart = window[id];
      var obj = new ExternalPayrollChart(chart);
      window[`${id}_obj`] = obj;
      window.externalPayrollChart = obj;
      obj.init();
    })
  }
}