import { Controller } from "stimulus"
import PayrollDistributionChart from './../charts/payroll-distribution-chart'

export default class extends Controller {
  connect() {
    // Payroll Distribution Chart
    const PayrollDistributionCharts = document.querySelectorAll('.payroll-distribution-chart')
    PayrollDistributionCharts.forEach(function (payrollDistributionChart) {
      const id = payrollDistributionChart.getAttribute('id')
      if(!window[`${id}_obj`]) {
        const chart = window[id]
        const obj = new PayrollDistributionChart(chart)
        window[`${id}_obj`] = obj;
        obj.init()
      }
    })
  }
}