import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class TaxBreakdownChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
    this.seriesMapping = chart.seriesMapping
    this.colorMapping = chart.colorMapping
  }

  init () {
    const _this = this
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data
    var scrollbarX = new am4charts.XYChartScrollbar();

    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 50

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.numberFormatter = new am4core.NumberFormatter()
    valueAxis.numberFormatter.numberFormat = '$#,###'

    this.series.forEach((serieName) => {
      const columnSerie = this.chart.series.push(new am4charts.ColumnSeries())
      columnSerie.dataFields.valueY = serieName
      columnSerie.dataFields.dateX = "date"

      if(this.seriesMapping) {
        columnSerie.name = this.seriesMapping[serieName];
      } else {
        columnSerie.name = serieName;
      }

      if(this.colorMapping) {
        columnSerie.columns.template.fill =am4core.color(this.colorMapping[serieName])
      }

      columnSerie.strokeWidth = 0
      columnSerie.stacked = true
      columnSerie.tooltipText = "{name}: [bold]{valueY.formatNumber('$#,###')}[/]";

      scrollbarX.series.push(columnSerie)
    })

    this.chart.scrollbarX = scrollbarX;
    this.chart.scrollbarY = new am4core.Scrollbar();

    // Add legend
    this.chart.legend = new am4charts.Legend()
    this.chart.legend.maxHeight = 150
    this.chart.legend.scrollable = true

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()

    dateAxis.events.on("startchanged", _this.dateAxisChanged);
    dateAxis.events.on("endchanged", _this.dateAxisChanged);

    this.chart.zoomOutButton.events.on("hit", function() {
      let dilutedElement = document.querySelector("[data-diluted-percentage]")
      let canceledElement = document.querySelector("[data-canceled-percentage]")
      let creditNotesElement = document.querySelector("[data-credit-notes-percentage]")
      let payrollAvgElement = document.querySelector("[data-payroll-avg]")
      let monthlyAvgElement = document.querySelector("[data-monthly-avg]")
      let monthsWithPayrollElement = document.querySelector("[data-months-with-payroll]")
      let participationElement = document.querySelector("[data-participation]")
      let totalInvoicesElement = document.querySelector("[data-total-invoices]")
      let totalInvoicesAvgElement = document.querySelector("[data-total-invoices-avg]")
      let totalCanceledInvoicesElement = document.querySelector("[data-total-canceled-invoices]")
      let totalCreditNotesElement = document.querySelector("[data-total-credits-notes]")
      let selectedRange = document.querySelector("[data-range-selected]")

      dilutedElement.innerText = dilutedElement.dataset.dilutedPercentage
      canceledElement.innerText = canceledElement.dataset.canceledPercentage
      creditNotesElement.innerText = creditNotesElement.dataset.creditNotesPercentage
      payrollAvgElement.innerText = payrollAvgElement.dataset.payrollAvg
      monthlyAvgElement.innerText = monthlyAvgElement.dataset.monthlyAvg
      monthsWithPayrollElement.innerText = monthsWithPayrollElement.dataset.monthsWithPayroll
      participationElement.innerText = participationElement.dataset.participation
      totalInvoicesElement.innerText = totalInvoicesElement.dataset.totalInvoices
      totalInvoicesAvgElement.innerText = totalInvoicesAvgElement.dataset.totalInvoicesAvg
      totalCanceledInvoicesElement.innerText = totalCanceledInvoicesElement.dataset.totalCanceledInvoices
      totalCreditNotesElement.innerText = totalCreditNotesElement.dataset.totalCreditNotes
      selectedRange.innerText = `De ${selectedRange.dataset.startDate} a ${selectedRange.dataset.endDate}, meses totales: ${selectedRange.dataset.totalMonths}`
    })
  }

  dateAxisChanged(e) {
    let dilutedElement = document.querySelector("[data-diluted-percentage]")
    let canceledElement = document.querySelector("[data-canceled-percentage]")
    let creditNotesElement = document.querySelector("[data-credit-notes-percentage]")
    let payrollAvgElement = document.querySelector("[data-payroll-avg]")
    let monthlyAvgElement = document.querySelector("[data-monthly-avg]")
    let monthsWithPayrollElement = document.querySelector("[data-months-with-payroll]")
    let participationElement = document.querySelector("[data-participation]")
    let totalInvoicesElement = document.querySelector("[data-total-invoices]")
    let totalInvoicesAvgElement = document.querySelector("[data-total-invoices-avg]")
    let totalCanceledInvoicesElement = document.querySelector("[data-total-canceled-invoices]")
    let totalCreditNotesInvoicesElement = document.querySelector("[data-total-credits-notes]")
    let selectedRange = document.querySelector("[data-range-selected]")
    let totalMonths = 0

    let start = new Date(e.target.minZoomed);
    let end = new Date(e.target.maxZoomed);
    let startDate = new Date(start.getFullYear(), start.getMonth(), 1)
    let endDate = new Date(end.getFullYear(), end.getMonth() + 1, 0)
    let zoomedEndDate = endDate
    let today = new Date()
    let values = window.chart_tax_breakdown_income_obj["data"].filter( data => new Date(data["date"]) >= startDate && new Date(data["date"]) <= endDate)
    let real = 0
    let total = 0
    let canceled = 0
    let discounted = 0
    let creditNotes = 0
    let globalReal = 0
    let current = 0
    let totalInvoices = 0
    let totalMonthsWithPayroll = 0
    let totalCanceledInvoices = 0
    let totalCreditNotesInvoices = 0

    values.forEach((datum) => {
      real += datum["real"]
      total += datum["total"]
      canceled += datum["canceled"]
      discounted += datum["discounted"]
      creditNotes += datum["credit_note"]
      current += datum["current"]
      totalInvoices += datum["total_invoices"]
      globalReal += datum["global_real"]
      totalCanceledInvoices += datum["total_canceled_invoices"]
      totalCreditNotesInvoices += datum["total_credit_notes_invoices"]
      if(datum["current"] > 0) {
        totalMonthsWithPayroll += 1
      }
    })

     let dilutedPercentage = ((canceled + discounted) / total) * 100
     let canceledPercentage = (canceled / total) * 100
     let creditNotesPercentage = (creditNotes / total) * 100
     let participationPercentage = (real / globalReal) * 100
     let payrollAvg = current / totalInvoices
     let monthlyAvg = current / totalMonthsWithPayroll

     dilutedElement.innerText = isNaN(dilutedPercentage) ? "0%" : `${dilutedPercentage.toFixed(1)}%`
     canceledElement.innerText = isNaN(canceledPercentage) ? "0%" : `${canceledPercentage.toFixed(1)}%`
     creditNotesElement.innerText = isNaN(creditNotesPercentage) ? "0%" : `${creditNotesPercentage.toFixed(1)}%`
     participationElement.innerText = isNaN(participationPercentage) ? "0%" : `${(participationPercentage).toFixed(0)}%`
     payrollAvgElement.innerText = isNaN(payrollAvg) ? "$0" : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(payrollAvg)
     monthlyAvgElement.innerText = isNaN(monthlyAvg) ? "$0" : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(monthlyAvg)
     monthsWithPayrollElement.innerText = isNaN(totalMonthsWithPayroll) ? 0 : totalMonthsWithPayroll
    totalInvoicesElement.innerText = `(${totalInvoices} Facturas)`
    totalInvoicesAvgElement.innerText = totalMonthsWithPayroll > 0 ?  `(${(totalInvoices / totalMonthsWithPayroll).toFixed(1)} Facturas)` : "(0 Facturas)"
    totalMonths = (endDate.getFullYear() - startDate.getFullYear())*12 + (endDate.getMonth() - startDate.getMonth())
    totalCanceledInvoicesElement.innerText = `(${totalCanceledInvoices} Facturas Canceladas)`

    if(totalCreditNotesInvoicesElement) {
      totalCreditNotesInvoicesElement.innerText = `(${totalCreditNotesInvoices} Notas de Crédito)`
    }


    if(today.getFullYear() == endDate.getFullYear() && today.getMonth() == endDate.getMonth()) {
      zoomedEndDate = new Date(today.getFullYear(), today.getMonth(), 0)
    }

    selectedRange.innerText = `De ${startDate.toLocaleDateString()} a ${zoomedEndDate.toLocaleDateString()}, meses seleccionados en rango: ${totalMonths}`
  }
}

export default TaxBreakdownChart;


