import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'colorCode'];

  connect() {
    this.selectColor()
  }

  selectColor() {
    const { preview } = this.inputTarget.dataset;
    const previewCard = document.getElementById(preview);

    if (this.inputTarget.value) {
      previewCard.style.backgroundColor = this.inputTarget.value;
      this.colorCodeTarget.textContent = this.inputTarget.value;
    }
  }
}