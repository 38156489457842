import { Controller } from 'stimulus'
import TimelineChart from '../charts/timeline-chart'

export default class extends Controller {
  connect() {
    const chart = window['chart_timeline']
    if (chart) {
      var obj = new TimelineChart(chart)
      obj.init()
    }
  }
}