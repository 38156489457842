import { Controller } from "stimulus";
import addonsManagementResources from './utils/addons-management-resources.js';

export default class extends Controller {
  static targets = ['selectorRow', 'sidebarNotification', 'managementSubmitBtn', 'liveScoresBox', 'discountBox', 'costBox']

  connect(){
    if(!this.targets.find('selectorRow')) return
  
    const currentAddon = document.querySelector('input[name="addons_live_scores_update[id]"]:checked')
    this.selectorRowTarget.dataset.actualOption = currentAddon.id

    this.setAddon(true);
  }

  setAddon(isOnLoad){
    const currentAddon = document.querySelector('input[name="addons_live_scores_update[id]"]:checked')
    const disableSubmitBtn = isOnLoad && this.selectorRowTarget.dataset.actualOption == currentAddon.id
    const addon = JSON.parse(currentAddon.dataset.addon)

    this.liveScoresBoxTarget.textContent = addon.quantity
    this.discountBoxTarget.textContent = `${addon.discount} %`
    this.costBoxTarget.textContent = `$ ${addon.cost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`

    this.managementSubmitBtnTarget.disabled = disableSubmitBtn
  }

  sendManagmentForm(e){ 
    addonsManagementResources.sendManagmentForm(e, 
      'live_scores', 
      this.sidebarNotificationTarget, 
      this.selectorRowTarget
    ) 
  }
}
