import axios from "../../packs/setupCSRFToken"

export function createMonthSlider(initialstate) {
  const state = {
    totalMonthsShow: 3,
    months: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    daysInMonths: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
    monthLabels: [],
    monthYearLabels: [],
    monthData: [],
    lastDaysData: [],
    dates: [],
    firstDate: "",
    lastDate: "",
    ...initialstate
  }

  function init() {
    setMonthLabels()

    noUiSlider.create(state.sliderElement, {
      start: [0, state.totalMonthsShow - 1],
      step: 1,
      range: { 'min': 0, 'max': state.totalMonthsShow - 1},
      tooltips: true,
      connect: true,
      animate: true,
      animationDuration: 600,
      pips: {
        mode: 'steps',
        density: state.totalMonthsShow - 1,
        filter: function(value, _type) {
          return Number.isInteger(value) ? 1 : 0
        }
      }
    })

    setLabelsAndTooltip()
    state.firstDate = `${state.monthData[0]}-1`
    state.lastDate = `${state.monthData[state.totalMonthsShow - 1]}-${state.lastDaysData[state.totalMonthsShow - 1]}`

    state.sliderElement.noUiSlider.on("end", (values, _handle) => {
      const firstMonthIndex = parseInt(values[0])
      const lastMonthIndex = parseInt(values[1])
      const firstDate = `${state.monthData[firstMonthIndex]}-1`
      const lastDate = `${state.monthData[lastMonthIndex]}-${state.lastDaysData[lastMonthIndex]}`
      const search = document.querySelector("#search").value
      const totalClients = document.querySelector("[data-total-clients]").dataset.totalClients
      state.firstDate = firstDate;
      state.lastDate = lastDate;

      axios.defaults.withCredentials = true;
      axios.get(state.url, { params: { from: firstDate, to: lastDate, search: search } })
      .then(({ data }) => {
        let resultList = document.querySelector(state.containerElement)
        let totalRecordsElement = document.querySelector("[data-filtered-records]")
        let dateRangeElement = document.querySelector("[data-filtered-range");
        resultList.innerHTML = data.html_content
        if(parseInt(totalClients) == parseInt(data.total_records)) {
          totalRecordsElement.innerText = ""
          dateRangeElement.innerText = ""
        } else {
          totalRecordsElement.innerText = `${data.total_records} ${data.record_type} con los filtros seleccionados`;
          dateRangeElement.innerText = `${data.record_type} con facturacion en el rango ${firstDate} a ${lastDate}`
        }
      })
    })
  }

  function updateSlider(totalMonthsShow) {
    state.totalMonthsShow = totalMonthsShow
    state.sliderElement.noUiSlider.destroy()
    init()
  }

  function setLabelsAndTooltip() {
    state.sliderElement.noUiSlider.on("update", (values, handle) => {
      const monthIndex = parseInt(values[handle])
      const prefixes = ["Desde", "Hasta"]
      const handleElement = document.querySelector(`.noUi-handle[data-handle='${handle}']`)
      const tooltip = handleElement.querySelector(".noUi-tooltip")
      let day = 0

      day = handle == 0 ? 1 : state.lastDaysData[monthIndex]
      tooltip.innerHTML = `${prefixes[handle]} <strong>${day} ${state.monthYearLabels[monthIndex]}</strong>`
    })

    document.querySelectorAll(".noUi-pips .noUi-value").forEach((e) => {
      let index = parseInt(e.innerText)
      if(typeof index == "number") { e.innerHTML = state.monthLabels[index] }
    })
  }

  function setMonthLabels() {
    let today = new Date()
    today.setMonth(today.getMonth() - 1)

    const todayMonthIndex = today.getMonth()
    const todayYear = today.getFullYear()

    let thisMonth = todayMonthIndex
    let thisYear = todayYear

    for(let i = (state.totalMonthsShow - 1); i >= 0; i--){
      state.monthYearLabels[i] = `${state.months[thisMonth]} ${thisYear}`
      state.monthLabels[i] = state.months[thisMonth]
      state.monthData[i] = `${thisYear}-${(thisMonth + 1)}`

      if(thisMonth == 1 && leapYear(thisYear)) {
        state.lastDaysData[i] = 29
      } else {
        state.lastDaysData[i] = state.daysInMonths[thisMonth]
      }

      if(thisMonth == 0) {
        thisMonth = 11
        thisYear--
      } else {
        thisMonth--
      }
    }
  }

  function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)
  }

  function getDateRange() {
    return [state.firstDate, state.lastDate];
  }

  return {
    init,
    updateSlider,
    getDateRange
  }
}