import { Controller } from "stimulus";
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import axios from 'axios';

export default class extends Controller {
  static targets = ["termsAndConditionsAlert", "submit", "tagInput", 'counters']

  connect() {
    this.loadCounters();
    this.delayTimer
    this.bindEmailInput()
    this.resetSelection();
    this.messagePhoneNUmbers();
    this.hideDashboardType()
  }

  messagePhoneNUmbers(){
    let notificationForwardingForm = document.querySelector("#notification-forwarding-form")
    if(notificationForwardingForm == null || notificationForwardingForm.length == 0) { return }

    const contact_by_phone_numbers = notificationForwardingForm.getAttribute('data-contact-by-phone-numbers')

    if (contact_by_phone_numbers == "true"){
      const message = document.querySelector('#has_phone_numbers_message')
      const select = document.querySelector('#phone-numbers-wrapper-resend-notification')
      select.classList.add('is-active')
      setTimeout(() => {
        message.remove()
      }, 15000);
    }
  }

  submit(e) {
    e.preventDefault()
    let errors = document.querySelectorAll(".help.is-danger");
    let rfcInput = document.getElementById("report_form_rfc")
    let ciecInput = document.getElementById("report_form_ciec")

    if (ciecInput == null) ciecInput = document.getElementById("external_report_update_form_ciec")
    if (rfcInput == null) rfcInput = document.getElementById("external_report_update_form_rfc")
    e.target.classList.add("is-loading")
    const reportType = document.getElementById("external-report-type")
    reportType.value = "report_ciec"

    if(errors.length || rfcInput.value.trim().length == 0 || ciecInput.value.trim().length == 0) {
      e.stopPropagation();
      if (rfcInput) {
        rfcInput.focus();
        rfcInput.blur();
      }

      if (ciecInput) {
        ciecInput.focus();
        ciecInput.blur();
      }

      if (rfcInput) rfcInput.focus();
      e.target.classList.remove("is-loading")
      return;
    }

    const step = e.target.dataset.step;
    const $container = document.querySelector(".box");
    const $title = document.querySelector("h2");
    const $waiting = document.querySelector("[data-waiting]");
    const rfc = document.querySelector("[data-target='clientRfc']").value;
    const ciec = btoa(document.querySelector("[data-target='clientCiec']").value);
    const reference = Math.random().toString(36).substr(2);
    let reportData = document.querySelector("[data-target='reportData']")
    let email = reportData.dataset.email
    let reportId = reportData.dataset.reportId
    let $currentStep = document.querySelector("[data-current-step")
    let counter = 0;
    let termsAndConditions = document.querySelector("#terms-of-service")

    if(termsAndConditions.checked == false) {
      this.termsAndConditionsAlertTarget.classList.remove("is-hidden")
      return
    }

    if(step !== "client_credentials_step" && step !== "client_ciec_step") { e.target.form.submit() }

    if($title) { $title.remove() }
    e.target.form.dataset["reference"] = reference

    $currentStep.value = "processing_step";
    e.target.form.submit();
  }

  removeToken() {
    let tokens = JSON.parse(localStorage["tokens"])
    let tokenIndex = tokens.indexOf(document.querySelector('meta[name=csrf-token]').content)
    tokens.splice(tokenIndex, 1)
    localStorage.setItem("tokens", JSON.stringify(tokens))
  }

  filterMyReports(e) {
    let url = new URL(window.location);
    let params = new URLSearchParams(url.search.slice(1));
    if(e.target.checked && !params.has("only_my_reports")) { params.append("only_my_reports", true); }
    if(!e.target.checked) { params.delete("only_my_reports"); }

    url.search = params.toString();
    window.location.href = url.href;
  }

  termsOFServiceSelected(e) {
    if(e.target.checked) {
      this.validateRfcAndCiecPresence()
    } else {
      this.submitTarget.setAttribute("disabled", "disabled")
    }
  }

  bindEmailInput() {
    let _this = this
    this.tagInputTargets.forEach((realInput) => {
      const tagInput = new BulmaTagsInput(realInput)

      if(realInput.dataset.emails != undefined) { tagInput.add(realInput.dataset.emails) }

      tagInput.input.onblur = function () {
        if(_this.validateEmailFormat(tagInput, tagInput.input.value)) {
          tagInput.add(tagInput.input.value)
          tagInput.input.value = ""
          _this.validateEmailPresence(tagInput)
        }
      };

      tagInput.input.onkeydown = function (e) {
        if(e.key === "Enter") {
          if(!_this.validateEmailFormat(tagInput, tagInput.input.value)) {
            tagInput.remove(tagInput.input.value);
            tagInput.input.value = "";
          }
        }
      };
    })
  }

  validateRfcAndCiecPresence() {
    const container = document.getElementById("report-ciec")
    let rfcInput = container.querySelector("#report_form_rfc");
    let ciecInput = container.querySelector("#report_form_ciec");
    let emailInput = container.querySelector("[data-id='external_report_registration_form_email']")

    if (!rfcInput) { rfcInput = container.querySelector("#external_report_registration_form_rfc") }

    if(rfcInput) {
      rfcInput.focus();
      rfcInput.blur();
    }

    if(emailInput) {
      emailInput.focus();
      emailInput.blur();
    }

    if (ciecInput) {
      ciecInput.focus();
      ciecInput.blur();
    }

    let errors = container.querySelectorAll("input.is-danger");
    if (errors.length > 0) {
      errors[0].focus();
      this.submitTarget.setAttribute("disabled", "disabled")
    } else {
      this.submitTarget.removeAttribute("disabled", false)
    }
  }

  validateEmailPresence(target) {
    let input = target.input
    let $parent = input.parentNode.parentNode;
    let invalidIcon = $parent.querySelector("span.icon")
    let invalidMsg = $parent.querySelector("p.is-danger")

    input.classList.remove("is-danger");
    if(invalidIcon) { invalidIcon.classList.add("is-hidden") }

    if(invalidMsg) { invalidMsg.remove() }

    if(target.items.length) { return }

    input.classList.add("is-danger");
    let help = document.createElement("p")
    let error = "Email no puede estar vacío"
    help.setAttribute("class", "help is-danger")
    help.innerHTML = error;
    if(invalidIcon) { invalidIcon.classList.remove("is-hidden") }
    $parent.appendChild(help);
  }

  validateEmailFormat(target, email) {
    if(!email.trim().length) { return true }

    let input = target.input
    let $parent = input.parentNode.parentNode;
    let invalidIcon = $parent.querySelector("span.icon")
    let invalidMsg = $parent.querySelector("p.is-danger")

    input.classList.remove("is-danger");
    if(invalidIcon) { invalidIcon.classList.add("is-hidden") }

    if(invalidMsg) { invalidMsg.remove() }

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email)) { return true }

    input.classList.add("is-danger");
    let help = document.createElement("p")
    let error = "Formato de email no es valido"
    help.setAttribute("class", "help is-danger")
    help.innerHTML = error;
    if(invalidIcon) { invalidIcon.classList.remove("is-hidden") }
    $parent.appendChild(help);
  }

  loadCounters() {
    let countersUrl = window.location.pathname + "/counters"
    var counters = this.countersTargets;

    if((/^\/users\/\d{1,}\/reports\/counters$/).test(countersUrl)) {
      axios.get(countersUrl).then((response) => {
        counters.forEach((counter) => {
          let counterName = counter.dataset["counterName"];
          let counterContent = counter.innerHTML;
          let counterValue = response.data.table[counterName];
          counter.innerHTML = counterContent + " (" + counterValue + ")";
        });
      })
    }
  }

  submitExternalReport(e) {
    e.preventDefault()
    let errors = document.querySelectorAll(".input.is-danger");
    const contact_by_phone_numbers = document.querySelector("#notification_forwarding_form").getAttribute('data-contact-by-phone-numbers')

    if(errors.length > 0) { return }

    if(!document.querySelector('#sidebar.is-active') && contact_by_phone_numbers == "true"){
      let input_phone_numbers = document.querySelector("input[type='hidden']#phone_numbers")
      let input_phone_numbers_showed = document.querySelector("#phone_number_resend-notification")
      let numbers_wrapper = document.querySelector('#phone-numbers-wrapper-resend-notification')
      let select_options = numbers_wrapper.querySelectorAll('option:not(.fixed)')

      input_phone_numbers.value = ''

      if(input_phone_numbers_showed.value != '')
        input_phone_numbers.value = input_phone_numbers_showed.value


      select_options.forEach(element => {
        if(!input_phone_numbers.value.includes(element.textContent)){
          if(input_phone_numbers.value != '')
            input_phone_numbers.value += ","

          input_phone_numbers.value += element.textContent
        }
      });
    }

    e.target.form.submit();
  }

  resetSelection(){
    const header = document.getElementById("header-selected");
    if (!header) { return }
    const form = header.querySelector('form')

    if(form.querySelectorAll('[data-report-id]').length === 0){
      document.querySelectorAll('[data-check-id]').forEach(el => el.checked = false)
    }
  }

  changecontactMedium(e){
    const input = document.querySelector('#contact_medium_resend_notification')
    const option = document.querySelector('#select_contact_medium_resend_notification')[e.target.selectedIndex]
    input.value = option.textContent.toLowerCase()
  }

  selectReport(e) {
    const id = e.target.dataset.checkId;
    const isSelected = e.target.checked;
    const counter = document.getElementById("counter-selected");
    const header = document.getElementById("header-selected");
    const form = header.querySelector('form')

    if(isSelected){
      form.appendChild(this.inputSelected(id))
    } else {
      const removeInput = form.querySelector(`[data-report-id="${id}"]`)
      if (removeInput) form.removeChild(removeInput);
    }

    const reportsSelected = form.querySelectorAll('[data-report-id]');

    if(reportsSelected.length > 0) {
      header.classList.remove("is-none")
      header.classList.add("is-block")
    } else {
      header.classList.remove("is-block")
      header.classList.add("is-none")
    }

    counter.textContent = ` (${reportsSelected.length})`
  }

  resendRequest(e) {
    e.preventDefault()
    e.target.classList.add("is-loading")
    e.target.form.submit();
  }

  inputSelected(id) {
    const input = document.createElement("input");
    input.type  = "hidden";
    input.name  = "reports[]";
    input.value = id;
    input.setAttribute("data-report-id", id);
    return input;
  }

  retrieveView(typeView){
    const reportsViewUrl = window.location.href
    const dataContainer = document.querySelector('#reports_data')
    const opposite = (typeView == 'grid' ? 'list' : 'grid' )

    if(dataContainer.querySelector(`#reports_data_${typeView}`) == undefined){
      dataContainer.querySelector(`#reports_data_${opposite}`)?.classList.add('is-hidden')

      axios.get(reportsViewUrl).then((response) => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(response.data, 'text/html');
        let reportsData = htmlDoc.querySelector('#reports_data')
        dataContainer.append(reportsData)
      });
    }else{
      dataContainer.querySelector(`#reports_data_${typeView}`).classList.remove('is-hidden')
      dataContainer.querySelector(`#reports_data_${opposite}`)?.classList.add('is-hidden')
    }
  }

  hideDashboardType(){
    const dashboards = document.querySelectorAll('[id^="reports_data_"]')
    const actualType = (document.cookie.includes(`dashboard_type=grid`) ? 'grid' : 'list')

    dashboards.forEach(element => {
      if(!element.getAttribute('id').includes(actualType))
        element.classList.add('is-hidden')
    });
  }

  switch_dashboard_view(e){
    const view_type = e.target.getAttribute("data-view-type")
    const grid_buttons = document.querySelectorAll(".grid_view_btn")
    const list_buttons = document.querySelectorAll(".list_view_btn")

    if(document.cookie.includes(`dashboard_type=${view_type}`)) return

    switch (view_type) {
      case "list":
        list_buttons.forEach(btn => {
          btn.classList.add("is-active", "is-primary")
          btn.classList.remove("has-text-primary")
        });

        grid_buttons.forEach(btn => {
          btn.classList.remove("is-active", "is-primary")
          btn.classList.add("has-text-primary")
        });

        this.retrieveView('list')
        document.cookie = "dashboard_type=list"
      break;

      case "grid":
        grid_buttons.forEach(btn => {
          btn.classList.add("is-active", "is-primary")
          btn.classList.remove("has-text-primary")
        });

        list_buttons.forEach(btn => {
          btn.classList.remove("is-active", "is-primary")
          btn.classList.add("has-text-primary")
        });

        this.retrieveView('grid')
        document.cookie = "dashboard_type=grid"
      break;
    }
  }
}
