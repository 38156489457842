import { Controller } from "stimulus";
import { Validation } from "../validators";

export class FormValidationController extends Controller {
  connect() {
    this.validation = new Validation();
    this.validatableInputs = [];
    this.bindInputs();
    this.bindForm();
    this.errors = {};
  }

  disconnect() {
    this.validatableInputs.forEach(($input) => $input.removeEventListener("blur", this.validate));
    this.element.removeEventListener("submit", this.validateAll);
  }

  bindForm() {
    this.element.addEventListener("submit", this.validateAll.bind(this));
  }

  bindInputs() {
    let $inputs = this.element.querySelectorAll("input");

    $inputs.forEach(($input) => {
      let inputName = $input.getAttribute("id");
      if(inputName) {
        let validate = this.validation.validatable(inputName);
        if(validate) {
          $input.addEventListener("blur", this.validate.bind(this));
          this.validatableInputs.push($input);
        }
      }
    })
  }

  inputValue(input) {
    return input.value;
  }

  validForm() {
    let valid = Object.values(this.errors).some((value) => value);
    return !valid;
  }

  validateAll(event) {
    this.validatableInputs.forEach(($input) => this.validate({ target: $input }));

    if(!this.validForm()) {
      const submitter = event.submitter
      
      event.preventDefault();
      event.stopPropagation();

      let submit = event.target.querySelector('[type="submit"]');
      if(submit) {
        submit.disabled = false
      }

      if(submitter) {
        submitter.classList.remove("is-loading")
      }
    }
  }

  validate(event) {
    let inputValue = this.inputValue(event.target);
    let inputId = event.target.getAttribute("id");
    let validationResult = this.validation.validate(inputValue, inputId);

    if(event.target.dataset.skipValidation == "blank" && inputValue == "") {
      validationResult = validationResult.filter((validator) => {
        validator.validator != "presence" || validator.validator != "length"
      })
    }

    let validator = validationResult.find((validator) => !validator.result.valid);
    this.errors[inputId] = !!validator;

    this.render(event.target, validationResult);
  }

  render(target, validation) {
    console.log("Not implemented")
  }
}
