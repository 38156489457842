import BaseValidator from "./base_validator";

export default class ConfirmationValidator extends BaseValidator {
  validate() {
    let valid = true;
    let errorMessage = "";

    if(this.value && this.options.validates_with == "password") {
      const password = document.getElementById("user_password").value
      valid = this.value === password
    }

    if(!valid) {
      errorMessage = this.errorMessages["confirmation"];
    }

    return { valid: valid, message: errorMessage };
  }
}

