import { Controller } from "stimulus"
import IncomePieChart from './../charts/income-pie-chart'

export default class extends Controller {
  connect() {
    // Black list income chart
    const IncomePieCharts = document.querySelectorAll('.income-pie-chart')
    IncomePieCharts.forEach(function (incomePieChart) {
      const id = incomePieChart.getAttribute('id')
      const chart = window[id]
      if(!window[`${id}_obj`]) {
        var obj = new IncomePieChart(chart)
        window[`${id}_obj`] = obj;
        obj.init()
      }
    })
  }
}