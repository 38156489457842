import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import Chart from './chart'

class BarChart extends Chart {
  init () {
    // State validation
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data
    this.chart.fontSize = 11

    // Create axes
    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = this.key
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 20
    categoryAxis.renderer.cellStartLocation = 0.1
    categoryAxis.renderer.cellEndLocation = 0.9

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = this.x_axis_title

    const self = this
    this.series.forEach(function (serie) {
      if (serie.length === 2) {
        self.createSeries(serie[0], serie[1], true, false)
      } else if (serie.length === 3) {
        self.createSeries(serie[0], serie[1], serie[2], false)
      } else {
        self.createSeries(serie[0], serie[1], serie[2], serie[3])
      }
    })

    // Add legend
    this.chart.legend = new am4charts.Legend()
    if(this.reverseOrder) {
      this.chart.legend.reverseOrder = true
    }

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }

  createSeries (field, name, stacked, area) {
    if (area === true) {
      const lineSeries = this.chart.series.push(new am4charts.LineSeries())
      lineSeries.fillOpacity = 0.1
      lineSeries.dataFields.valueY = field
      lineSeries.dataFields.categoryX = this.key
      lineSeries.name = name
      lineSeries.tooltipText = `${name}: {valueY}`
      if(this.colorMapping) {
        lineSeries.stroke = am4core.color(this.colorMapping[field])
        lineSeries.fill = this.colorMapping[field]
      }
    } else {
      const columnSeries = this.chart.series.push(new am4charts.ColumnSeries())
      columnSeries.dataFields.valueY = field
      columnSeries.dataFields.categoryX = this.key
      columnSeries.name = name
      columnSeries.tooltipText = `${name}: [bold]{valueY}[/]`
      columnSeries.stacked = stacked
      columnSeries.columns.template.width = am4core.percent(85)

      if(this.colorMapping) {
        columnSeries.stroke = am4core.color(this.colorMapping[field])
        columnSeries.columns.template.fill = new am4core.color(this.colorMapping[field])
      }
    }
  }
}

export default BarChart
