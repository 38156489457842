import { Controller } from 'stimulus'
import CreditBureauScoreChart from '../charts/credit-bureua-score-chart'
import TimelineChart from '../charts/timeline-chart'
import AreaChart from '../charts/area-chart'

export default class extends Controller {
  connect() {
    this.initScoreChart()
    this.initTimelineChart()
    this.initHistoricalBalanceChart()
    this.initMap()
  }

  initScoreChart () {
    const chart = window['chart_credit_bureau_score']
    if (chart) {
      var obj = new CreditBureauScoreChart(chart)
      obj.init()
    }
  }

  initTimelineChart () {
    const chart = window['chart_timeline']
    if (chart) {
      var obj = new TimelineChart(chart)
      obj.init()
    }
  }

  initHistoricalBalanceChart () {
    const chart = window['chart_historical_balance']

    if (chart) {
      var obj = new AreaChart(chart)
      obj.init()
    }
  }

  initMap () {
    let addressStreetView = document.getElementById('address-streetview');
    let mapElement = document.getElementById('map-wrapper')
    const querySearch = mapElement.dataset.mapQuery;

    if (mapElement) {
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode( { 'address': querySearch}, function(results, status) {
        if (status == 'OK') {
          var placeResult = results[0].geometry.location
          const  map = new google.maps.Map(
            mapElement,
            {
              center: placeResult,
              zoom: 16,
              disableDefaultUI: true,
            }
          );
          const marker = new google.maps.Marker({
            position: placeResult,
            map: map,
          })
        } else {
          addressStreetView.classList.add("d-none")
          addressWrapper.classList.remove('d-none')
        }
      });
    }
  }
}