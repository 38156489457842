import { Controller } from 'stimulus';

export default class extends Controller {
  showChart(e) {
    e.preventDefault();
    (document.querySelectorAll("[data-chart]") || []).forEach((element) => { element.classList.add("is-hidden") });

    const chartContainer = document.querySelector(`[data-chart="${e.target.dataset.chartToShow}"]`);
    chartContainer.classList.remove("is-hidden");
  }
}