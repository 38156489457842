import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "legalContainer" , "bureauConfirm", "authContainer", "termsCiecInput"]
  static values = { skip: Boolean }



  filled_inputs(){
    let inputs = this.legalContainerTarget.querySelectorAll('input')
    let filledInputs = 0

    inputs.forEach(element => {
      if(element.value.trim() != '' && !element.classList.contains("is-danger")) 
        filledInputs++
    })

    if(filledInputs == inputs.length)
      this.bureauConfirmTarget.disabled = false
    else
      this.bureauConfirmTarget.disabled = true
  }

  filled_terms_inputs (){
    let terms_inputs = this.authContainerTarget.querySelectorAll('input')
    let terms_checkbox = this.authContainerTarget.querySelectorAll("input[type='checkbox']")
    let filledInputs = 0

    terms_inputs.forEach(element => {
      if(element.value.trim() != '') filledInputs++
    })

    terms_checkbox.forEach(element => {
      if(element.checked) filledInputs++
    })

    if(filledInputs == (terms_inputs.length + terms_checkbox.length))
      this.bureauConfirmTarget.disabled = false
    else
      this.bureauConfirmTarget.disabled = true
  }

  legalRfcInput(e) {
    const target = e.target
    let errorElement = target.parentElement.parentElement.lastElementChild
    let rfcInput = document.getElementById("external_report_update_form_rfc")
    const rfcRegex = /(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})/
        
    if (target.value.length <= 0){
      target.classList.add("is-danger")
      errorElement.classList.add("help", "is-danger")
      errorElement.textContent = "No puede estar vacío"
      this.filled_inputs()
      return
    } 

    if(!rfcRegex.test(target.value)) {
      target.classList.add("is-danger")
      errorElement.classList.add("help", "is-danger")
      errorElement.textContent = "formato de RFC no válido"
      this.filled_inputs()
      return;
    }

    if(target.value == rfcInput.value) {
      target.classList.add("is-danger")
      errorElement.classList.add("help", "is-danger")
      errorElement.textContent = "El RFC del representante legal no puede ser el mismo"
      this.filled_inputs()
      return;
    }

    target.classList.remove("is-danger")
    errorElement.classList.remove("help", "is-danger")
    errorElement.textContent = ""
    this.filled_inputs()
  }

  legalInput(e) {
    const target = e.target
    let errorElement = target.parentElement.parentElement.lastElementChild
    
    if (target.value.length <= 0){
      target.classList.add("is-danger")
      errorElement.classList.add("help", "is-danger")
      errorElement.textContent = "No puede estar vacío"
      this.filled_inputs()
      return
    }
    
    target.classList.remove("is-danger")
    errorElement.classList.remove("help", "is-danger")
    errorElement.textContent = ""
    this.filled_inputs()
  }

  removeUnmatchedAlert(e){
    this.termsCiecInputTarget.classList.remove("is-danger")
    this.termsCiecInputTarget.nextElementSibling.textContent = ''
  }
}
