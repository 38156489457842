import "javascripts/twitter.typeahead";
import Bloodhound from "javascripts/bloodhound"

export default class RfcSearchTypeahead {
  start (url) {
    $('.js-rfc-issuing-typeahead-input').typeahead({
      minLength: 3,
      highlight: true
    },
    {
      name: 'my-dataset',
      display: 'value',
      source: this.fetchRfcList(`${url}?rfc_issuing`),
      limit: 50,
      templates: {
        empty: [
          `<div class="empty-message">No se encontro el RFC</div>`
        ],
        suggestion: (data) => { return `<div>${data.value} <br />${data.name}</div>`}
      }
    }).on("typeahead:asyncrequest", function() {
      document.querySelector("[data-hook='rfc-issuing-filter']").classList.add("is-loading")
    }).on("typeahead:asynccancel typeahead:asyncreceive", function() {
      document.querySelector("[data-hook='rfc-issuing-filter']").classList.remove("is-loading")
    });

    $('.js-rfc-receiver-typeahead-input').typeahead({
      minLength: 3,
      highlight: true
    },
    {
      name: 'my-dataset',
      display: 'value',
      source: this.fetchRfcList(`${url}?rfc_receiver`),
      limit: 50,
      templates: {
        empty: [
          `<div class="empty-message">No se encontro el RFC</div>`
        ],
        suggestion: (data) => { return `<div>${data.value} <br />${data.name}</div>`}
      }
    }).on("typeahead:asyncrequest", function() {
      document.querySelector("[data-hook='rfc-receiver-filter']").classList.add("is-loading")
    }).on("typeahead:asynccancel typeahead:asyncreceive", function() {
      document.querySelector("[data-hook='rfc-receiver-filter']").classList.remove("is-loading")
    });
  }

  fetchRfcList(url) {
    return new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        wildcard: '%QUERY',
        url: `${url}=%QUERY`,
        transform: response => $.map(response, data => ({
          value: data.rfc, name: data.name
        }))
      }
    })
  }
}
