import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {

  showDiversificationTableByRange(e) {
    const dateRange = document.querySelector(`[data-range-value='${e.target.value}']`).dataset.dateRange;
    let diversificationType = e.target.closest("[data-diversification-type]").dataset.diversificationType;
    const chartID = `diversificationOf${diversificationType}Chart`
    diversificationType = diversificationType.toLowerCase();
    const outcomesListData = document.querySelector(`[data-${diversificationType}-date-range='${dateRange}']`);
    const chartContainer = document.querySelector(`#chart_diversification_of_${diversificationType}`).closest(".chart");
    const chartDataset = window[chartID].data[dateRange]
    const emptyReportMsg = document.querySelector(`[data-empty-${diversificationType}-report-msg]`);

    (document.querySelectorAll(`[data-${diversificationType}-date-range]`) || []).forEach(element => {
      element.classList.add("is-hidden");
    });
    e.target.parentElement.querySelectorAll("[data-date-range-label]").forEach((label) => { label.classList.remove("selected") });
    e.target.parentElement.querySelector(`[data-range-value="${e.target.value}"]`).classList.add("selected");

    if(chartDataset) { outcomesListData.classList.remove("is-hidden") }

    setTimeout(() => {
      window[chartID].chart.invalidateData();
      if(chartDataset) {
        chartContainer.classList.remove("is-hidden");
        emptyReportMsg.classList.add("is-hidden");
        window[chartID].chart.data = chartDataset;
        window[chartID].range = dateRange;
      } else {
        emptyReportMsg.classList.remove("is-hidden");
        chartContainer.classList.add("is-hidden");
      }
    }, 10)
  }

  progresDownloadXlsx(e){
    e.target.classList.add('is-loading')
    e.target.childNodes[1].classList.add('is-hidden')

    const client_id = e.target.getAttribute('data-client-id')
    const user_id = e.target.getAttribute('data-user-id')
    let type_download = ''
    let name_file = ''

    if (e.target.getAttribute('data-type-download') == 'incomes'){
      type_download = 'customer_reports'
      name_file = 'diversificacion_de_ingresos.xlsx'
    }else{
      type_download = 'supplier_reports'
      name_file = 'diversificacion_de_egresos.xlsx'
    }

    const url = `/users/${user_id}/reports/${client_id}/${type_download}/${client_id}?format=xlsx`

    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', name_file);
      document.body.appendChild(link);
      link.click();

      e.target.classList.remove('is-loading')
      e.target.childNodes[1].classList.remove('is-hidden')

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

}
