import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class DsoDpoTimeChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.average = chart.average;
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.XYChart);
    this.chart.data = this.data;
    const scrollbarX = new am4charts.XYChartScrollbar();

    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.title.text = "Fecha de emisión";

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Días";

    const axisRange = valueAxis.axisRanges.create();
    axisRange.value = this.average;
    axisRange.grid.strokeOpacity = 1;
    axisRange.grid.stroke = am4core.color("#0000FF");
    axisRange.grid.strokeWidth = 1;
    axisRange.grid.strokeDasharray = "3,3";
    axisRange.grid.above = true;

    const series = this.chart.series.push(new am4charts.ColumnSeries());

    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.name = "Días";
    series.strokeWidth = 0
    series.columns.template.tooltipText = "{dateX}: [bold]{valueY.formatNumber('#,###.##')}[/]";
    series.columns.template.fill =am4core.color("#00cc00")
    
    scrollbarX.series.push(series)

    this.chart.scrollbarX = scrollbarX;
    this.chart.cursor = new am4charts.XYCursor()

  }
}

export default DsoDpoTimeChart;

