import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.services = this.setServices()
    this.forms = this.setForms()
  }

  setServices() {
    let services = document.querySelectorAll("[data-uuid]")
    return Array.from(services)
  }

  setForms() {
    let forms = document.querySelectorAll('[data-form]')
    return Array.from(forms)
  }

  changeForm(e) {
    const target = e.currentTarget
    const uuid = target.dataset.uuid
    this.services.forEach(service => {
      if (service == target) {
        target.parentElement.classList.add("is-active")
      } else {
        service.parentElement.classList.remove("is-active")
      }
    })

    this.forms.forEach(form=>{
      if (form.id == uuid) {
        form.classList.remove("hidden")
      } else {
        form.classList.add("hidden")
      }
    })
  }
}
