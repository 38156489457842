import ReportForm from "./report_form_controller";
import axios from "axios"

export default class ESignatureReportForm extends ReportForm {
  static targets = ["tof", "submit"]

  connect() {
    this.rfcRegex = /(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})/
  }

  submit(e) {
    e.preventDefault()

    const step = e.target.dataset.step;
    let $currentStep = document.getElementById("e-signature-current-step")
  
    if(this.tofTarget.checked == false) {
      this.termsAndConditionsAlertTarget.classList.remove("is-hidden")
      return
    }


    if(step !== "client_credentials_step" && step !== "client_ciec_step") { 
      this.sendForm() // NOTA*
    }

    $currentStep.value = "processing_step";
    this.sendForm()
  }

  termsOFServiceSelected(e) {
    if(e.target.checked) {
      this.validateRfcAndESignaturePresence()
    } else {
      this.submitTarget.disabled = true
    }
  }

  validateRfcAndESignaturePresence() {
    const container = document.getElementById("report-by-e-signature")
    const inputs = container.querySelectorAll("input.input, input.file-input")

    inputs.forEach(input=>{
      input.focus()
      input.blur()
    })

    const errors = container.querySelectorAll("input.is-danger, div.file.is-danger")
    if (errors.length > 0) {
      errors[0].focus();
      if (errors[0].tagName == "DIV") {
        const inputFile = errors[0].querySelector("input") 
        if (inputFile) inputFile.click();
      } 
      this.tofTarget.checked = false
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }


  validateShareholders(e) {
    e.preventDefault()
    const container = document.getElementById("report-by-e-signature")

    const areShareholdersValid = this.searchShareholders(container)
    if(!areShareholdersValid) return 
    
    this.sendShareholders(container)
  }

  sendForm() {
    const reportForm = document.getElementById("e-signature-report-form")
    const formData = new FormData(reportForm)
    const data = Object.fromEntries(formData.entries())
    
    this.sendCredencials(data)
  }

  async sendCredencials(data){
    try {
      let base64File = await this.toBase64(data["report_form[e_signature]"])

      const body = {
        user_id: data["report_form[user_id]"],
        e_signature: base64File,
        current_step: data["report_form[current_step]"],
        form_id: data["report_form[form_id]"],
        rfc: data["report_form[rfc]"],
        base_64: true
      }

      this.submitTarget.classList.add("is-loading")
      const response = await axios.post("/v1/reports", body)
      const container = document.getElementById("e-signature-report-container")
      
      container.innerHTML = response.data
    } catch (error) {
      this.notification("Ha ocurrido un error", false)
      this.submitTarget.classList.remove("is-loading")
    }
  }
  
  skipShareholders(e) {
    e.preventDefault()
    const reportForm = document.getElementById("e-signature-report-form")
    const formData = new FormData(reportForm)
    const data = Object.fromEntries(formData.entries())

    const body = {
      user_id: data["report_form[user_id]"],
      form_id: data["report_form[form_id]"],
      e_signature: data["report_form[es]"],
      current_step: data["report_form[current_step]"],
      rfc: data["report_form[rfc]"],
      skip_shareholder: true,
      encrypted: true,
      shareholders_attributes: []
    }

    this.sendRequest(body)
  }

  sendShareholders() {
    const reportForm = document.getElementById("e-signature-report-form")
    const shareholders = reportForm.querySelectorAll("[data-form-id]")
    const formData = new FormData(reportForm)
    const data = Object.fromEntries(formData.entries())
    const shareholdersData = this.mapShareholders(shareholders)

    const body = {
      user_id: data["report_form[user_id]"],
      form_id: data["report_form[form_id]"],
      e_signature: data["report_form[es]"],
      current_step: data["report_form[current_step]"],
      rfc: data["report_form[rfc]"],
      skip_shareholder: false,
      encrypted: true,
      shareholders_attributes: shareholdersData
    }

    this.sendRequest(body)
  }

  inputFile(e){
    const fileName = document.getElementById("e-signature-name")
    
    if(e.target.files.length == 0){
      fileName.innerHTML = "No hay archivo seleccionado"
      return this.showError(e.target, "La E-Firma no puede estar vacío", true)
    }
    const file = e.target.files[0]
    fileName.innerHTML = file.name
    this.removeError(e.target, true)
  }

  inputRFC(e){
    const value = e.target.value
    this.validateRFCformat(e.target, value)
  }

  validateRFCformat(target, value){
    if (value.trim().length === 0 ){
      this.tofTarget.checked = false
      this.submitTarget.disabled = true;
      return this.showError(target, "El RFC no puede estar vacío")
    }

    if(!this.rfcRegex.test(value)) {
      this.tofTarget.checked = false 
      this.submitTarget.disabled = true;
      return this.showError(target, "El RFC no es valido")
    }

    this.removeError(target)
  }

  removeError(target, isFile = false){
    const dangerElement = isFile ? target.parentElement.parentElement : target
    dangerElement.classList.remove("is-danger")
    const parent = isFile ? target.parentElement.parentElement.parentElement : target.parentElement
    
    const messageElement = parent.lastChild
    
    if(messageElement.tagName != "P"){
      return
    }

    messageElement.classList.remove("help")
    messageElement.classList.remove("is-danger")
    messageElement.classList.add("is-hidden")
  }

  showError(target, message, isFile = false){
    const dangerElement = isFile ? target.parentElement.parentElement : target
    dangerElement.classList.add("is-danger")
    const parent = isFile ? target.parentElement.parentElement.parentElement : target.parentElement
    const messageElement = parent.lastChild
    if(messageElement.tagName == "P"){
      messageElement.classList.add("help")
      messageElement.classList.add("is-danger")
      messageElement.classList.remove("is-hidden")
      parent.lastChild.innerHTML = message
      return
    }
    const help = document.createElement("p")
    help.setAttribute("class", "help is-danger")
    help.innerHTML = message
    parent.appendChild(help)
  }

  toBase64(file){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}