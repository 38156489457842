import { Controller } from "stimulus";

export default class extends Controller {

  closeModal() {
    const modalInfo = document.getElementById("modal-info")
    modalInfo.classList.remove("is-active")
  }

  showModal(e) {
    e.preventDefault();

    const modal = document.querySelector(e.target.dataset.showModal);

    modal.classList.add('is-active');
  }
}
