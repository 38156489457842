import { Controller } from "stimulus";

export default class extends Controller {
  
  submit(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    e.target.classList.add("is-loading");
    e.target.disabled = true;
    e.target.form.submit();
  }
}
