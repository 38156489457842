import { Controller } from "stimulus";

export default class extends Controller {
  selectItemDropUp(e){
    let options = document.querySelectorAll('#dropup-assign-report a.has-background-grey-lighter')
    options.forEach(opt_select => opt_select.classList.remove('has-background-grey-lighter'))
    e.target.classList.add('has-background-grey-lighter')
  }

  ShowHideDropUpAssign(e){
    const drop_menu = document.getElementById(e.target.getAttribute('data-drop'))
    if(drop_menu.style.display == "none" || drop_menu.style.display == "")
      drop_menu.style.display = "block"
    else
      drop_menu.style.display = "none"
  }
}