import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class ColumnsChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.valueX = chart.valueX
    this.valueY = chart.valueY
    this.showLegend = chart.showLegend == true;
    this.isXCurrency = chart.isXCurrency == true;
  }

  init () {
    const _this = this
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data

    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = this.valueX
    categoryAxis.renderer.minGridDistance = 50
    if(this.isXCurrency) {
      categoryAxis.numberFormatter = new am4core.NumberFormatter()
      categoryAxis.numberFormatter.numberFormat = '$#,###'
    }

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.min = 0
    valueAxis.numberFormatter = new am4core.NumberFormatter()
    valueAxis.numberFormatter.numberFormat = '#'

    if(this.valueY == "frequency" && this.data && this.data.length < 10) {
      let prevValue, frequency;
      valueAxis.strictMinMax = true
      valueAxis.adjustLabelPrecision = false
      valueAxis.renderer.grid.template.disabled = true
      valueAxis.renderer.labels.template.disabled = true
      this.data.forEach((value) => {
        frequency = value.frequency

        if(prevValue != frequency) {
          prevValue = frequency
          var range = valueAxis.axisRanges.create()
          range.value = frequency;
          range.label.text = "{value}"
        }
      })
    }

    const columnSerie = this.chart.series.push(new am4charts.ColumnSeries())
    columnSerie.dataFields.valueY = this.valueY
    columnSerie.dataFields.categoryX = this.valueX
    columnSerie.columns.template.fill = am4core.color('#0099f8')
    columnSerie.strokeWidth = 0
    columnSerie.tooltipText = "{categoryX}: [bold]{valueY}[/]";

    // Add legend
    if(this.showLegend) {
      this.chart.legend = new am4charts.Legend()
      this.chart.legend.maxHeight = 150
      this.chart.legend.scrollable = true
    }

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }
}

export default ColumnsChart;

