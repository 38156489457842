import { Controller } from "stimulus"
import FinantialProductsChart from './../charts/finantial-products-chart'

export default class extends Controller {
  connect() {
    const FinantialProductsCharts = document.querySelectorAll('.finantial-products-chart')
    FinantialProductsCharts.forEach(function(finantialProductChart) {
      const id = finantialProductChart.getAttribute('id')
      if(!window[`${id}_obj`]) {
        const chart = window[id]

        const initial_data = [
          { title: 'Ingreso Vigente', amount: chart.data[`net_income_${chart.range}`] },
          { title: 'Gasto Financiero', amount: (chart.data[`total_${chart.range}_sales`] || chart.data[`${chart.range}_total_invoices`]) }
        ];

        chart.initial_data = initial_data
        var obj = new FinantialProductsChart(chart)
        window[`${id}_obj`] = obj;
        obj.init()
      }
    })
  }
}