import { Controller } from "stimulus";
import axios from "../packs/setupCSRFToken";

export default class extends Controller {
  static targets = ["score"]

  connect() {
   this.getScores()
   this.getNames()
  }

  getScores() {
    (document.querySelectorAll("[data-score-row]") || []).forEach((row) => {
      axios.get(row.dataset.scoreUrl)
      .then(({ data }) => {
        let scoreProgress = document.querySelector(`[data-score-row='${data.report_id}']`)
        let displayScore = document.querySelector(`[data-display-score-row='${data.report_id}']`)
        scoreProgress.innerHTML = data.score_bar
        displayScore.innerHTML = data.score
      })
    })
  }

  getNames() {
    const rows =  Array.from(document.querySelectorAll("[data-name-row]")) || []
    const list_users = [...new Set(rows.map(row  => row.dataset.nameRow ))];
    if(list_users.length > 0){
      let baseUrl = rows[0].dataset.nameUrl;
      list_users.forEach(row => {
        let url = baseUrl.replace("$", row)
        axios.get(url)
        .then(({ data }) => {
          let elements = document.getElementsByClassName(`registered_by_${data.user_id}`)

          for(var i = 0; i < elements.length; i++){
            elements[i].innerHTML = data.name;
          }

        })
      })
    }
  }

}
