import { Controller } from "stimulus";
import axios from "../packs/setupCSRFToken";
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';

export default class extends Controller {
  static targets = ["search", "tagsInput", "clearFilter", "searchOptions"];

  connect() {
    this.initializeInputTags();
  }

  initializeInputTags() {
    if (!this.hasTagsInputTarget) return;

    this.instanceBulma = new BulmaTagsInput(this.tagsInputTarget, {
      maxTags: 10,
      maxChars: 15,
      minChars: 2,
      caseSensitive: false,
      closeDropdownOnItemSelect: false,
      highlightDuplicate: true,
      noResultsLabel: 'No se encontraron etiquetas',
    })

    this.instanceBulma.container.classList.add('is-hidden')
  }

  handleEnterKey(e) {
    if (e.key === 'Enter') e.preventDefault();

    const classList = this.instanceBulma.container.classList
    if (!classList.contains('is-active')) {
      this.instanceBulma.container.classList.add('is-active')
    }
  }

  changeSearchOptions(e) {
    const currentOption = e.target.value;
    switch (currentOption) {
      case "client":
        this.searchTarget.classList.remove('is-hidden')
        this.instanceBulma.container.classList.add('is-hidden')
        this.instanceBulma.input.classList.add('is-hidden')
        this.instanceBulma.removeAll()
        break;
      case "tags":
        this.searchTarget.classList.add('is-hidden')
        this.instanceBulma.container.classList.remove('is-hidden')
        this.instanceBulma.input.classList.remove('is-hidden')
        this.searchTarget.value = ''
        break;
      default:
    }
  }

  searchByName(e) {
    e.preventDefault()
    const textToSearch = this.searchTarget.value
    this.sendRequest(textToSearch, [], e.target.form);
  }

  searchByTags(e) {
    e.preventDefault()
    const tagsToSearch = this.instanceBulma.items.map(i => i.value)
    this.sendRequest('', tagsToSearch, e.target.form);
  }

  advanceSearch(e) {
    e.preventDefault()
    const currentOption = this.searchOptionsTarget.value

    switch (currentOption) {
      case "client":
        this.searchByName(e);
        break;
      case "tags":
        this.searchByTags(e);
        break;
      default:
    }
  }

  clearFilter(e) {
    e.preventDefault()

    this.searchTarget.value = ""
    if (this.hasSearchTagTarget) {
      this.searchTagTarget.value = ""
    }
    let url = new URL(window.location);
    url.searchParams.delete('search');
    url.searchParams.delete('tags[]');
    window.history.pushState({}, document.title, url);

    if (this.instanceBulma) {
      this.instanceBulma.removeAll()
    }
    this.sendRequest("", "", e.target.closest("form"));
  }

  sendRequest(textToSearch, tagToSearch, form) {
    if (textToSearch.trim().length == 0 && tagToSearch.length == 0 && this.clearFilterTarget.classList.contains('hidden')) {
      form.elements.search.value = ''
      return
    }

    const url = new URL(window.location)
    let searchParams = new URLSearchParams(url.search.slice(1))
    let params = {}
    let reportsUrl = form.action

    this.searchTarget.parentNode.classList.add("is-loading")

    if (textToSearch != '') {
      params["search"] = textToSearch
      url.searchParams.set("search", textToSearch)
      url.searchParams.delete('tags[]');
    }

    if (tagToSearch.length != 0) {
      params['tags'] = tagToSearch
      url.searchParams.set("tags[]", tagToSearch)
      url.searchParams.delete('search');
    }

    url.searchParams.delete('page');
    window.history.pushState({}, '', url);

    searchParams.forEach(function (value, key) {
      if (key != "page" && key != 'search' && key != 'tags[]') { params[key] = value }
    });

    axios.get(reportsUrl, { params: params })
      .then(({ data }) => {
        let resultList = document.querySelector(".result-list")
        resultList.innerHTML = data.html_content

        const downloadElement = document.getElementById('xlsx_download_url')
        if (downloadElement) {
          downloadElement.setAttribute("href", data.download_url);
        }

        if (data.paginate) {
          document.querySelectorAll("[data-paginate]").forEach((element) => {
            element.innerHTML = data.paginate
          })
        } else {
          document.querySelectorAll("[data-paginate]").forEach((element) => {
            element.innerHTML = ""
          })
        }
        this.searchTarget.parentNode.classList.remove("is-loading");
        this.clearFilterTarget.text = `Eliminar filtro: ${textToSearch} ${tagToSearch}`;
        let filters = textToSearch.length == 0 && tagToSearch.length == 0
        this.clearFilterTarget.classList.toggle("hidden", filters);
      })
  }
}
