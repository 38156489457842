import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {}

  showModal() {
    const modal = document.querySelector("#purchase-loading")
    modal.classList.add("is-active")
  }

  showSidebarModal(e){
    let formId = e.target.form.id
    const modal = document.getElementById(`sidebar-${formId}`)
    modal.classList.add("is-active")
  }

  updateAmount(e){
    e.target.form.amount.value = e.target.dataset.amount
  }
}
