import { Controller } from "stimulus";
import { CreditCardValidator } from "./utils/credit_card_validator";
import axios from '../packs/setupCSRFToken';

export default class extends Controller {
  static targets = [
    'billingInformation',
    'cardNumberElement',
    'cardExpiryElement',
    'cardCvvElement',
    'cardZipElement',
    'cardErrors',
    'cardExpiryErrors',
    'cardCvvErrors',
    'cardZipErrors',
    'form',
    'requestInvoice',
    'disclaimerInvoice'
  ]

  connect() {
    this.stripe = Stripe(this.data.get('key'), { locale: 'es-419' });
    let elements = this.stripe.elements();

    const style = {
      base: {
          color: '#32325d',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
              color: '#aab7c4'
          }
      },
      invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
      }
    };

    this.cardNumberElement = elements.create('cardNumber', {
      style: style,
      showIcon: true,
    });
    this.cardNumberElement.mount(this.cardNumberElementTarget);

    this.cardExpiryElement = elements.create('cardExpiry', {
      style: style
    });
    this.cardExpiryElement.mount(this.cardExpiryElementTarget);

    this.cardCvvElement = elements.create('cardCvc', {
      style: style
    });
    this.cardCvvElement.mount(this.cardCvvElementTarget);

    this.cardPostalElement = elements.create('postalCode', {
      style: style
    });
    this.cardPostalElement.mount(this.cardZipElementTarget);

    let controller = this;
    this.cardNumberElement.addEventListener('change', function (event) {
      let displayError = controller.cardErrorsTarget;
      if (event.error) {
          displayError.textContent = event.error.message;
      } else {
          displayError.textContent = '';
      }
    });

    this.cardExpiryElement.addEventListener('change', function (event) {
      let displayError = controller.cardExpiryErrorsTarget;
      if (event.error) {
          displayError.textContent = event.error.message;
      } else {
          displayError.textContent = '';
      }
    });

    this.cardCvvElement.addEventListener('change', function (event) {
      let displayError = controller.cardCvvErrorsTarget;
      if (event.error) {
          displayError.textContent = event.error.message;
      } else {
          displayError.textContent = '';
      }
    });
    this.cardPostalElement.addEventListener('change', function (event) {
      let displayError = controller.cardZipErrorsTarget;
      if (event.error) {
          displayError.textContent = event.error.message;
      } else {
          displayError.textContent = '';
      }
    });
  }

  toggleBillingInformation(e) {
    this.billingInformationTarget.classList.toggle("is-hidden", !e.target.checked);
    if(this.hasDisclaimerInvoiceTarget){
      this.disclaimerInvoiceTarget.classList.toggle("is-hidden", e.target.checked);
    }
  }

  enableInputs(e){
    let inputs = e.target.form.querySelectorAll("input, select, checkbox")
    inputs.forEach(function(input) { input.disabled = false; })
    e.target.classList.add("is-loading");
  }

  submitFormStripeTransfer(e) {
    e.target.classList.add("is-loading");
    this.enableInputs(e)

    if(!this.requestInvoiceTarget.checked){
      e.preventDefault();
      e.target.form.submit();
    }
  }

  submitForm(e) {
    e.preventDefault();
    e.target.classList.add("is-loading");
    let controller = this;
    this.enableInputs(e)
    this.stripe.createToken(this.cardNumberElement).then(function (result) {
      if (result.error) {
        e.target.classList.remove("is-loading");
      } else {
        controller.stripeTokenHandler(result);
        e.target.form.submit();
      }
    });
  }

  submitTransferForm(e) {
    e.preventDefault();
    e.target.classList.add("is-loading");
    e.target.form.submit();
  }

  saveNewCard(e){
    e.preventDefault();
    e.target.classList.add("is-loading");
    let controller = this;
    this.stripe.createToken(this.cardNumberElement).then(function (result) {
      if (result.error) {
        e.target.classList.remove("is-loading");
      } else {
        let params = controller.paramsNewCard(result.token);
        controller.saveCard(e, params);
      }
    });
  }

  stripeTokenHandler(result) {
    var form = this.formTarget;
    let stripe_token = this.newHiddenInput('stripe_token', result.token.id);
    let last4 = this.newHiddenInput('last4', result.token.card.last4);
    let brand = this.newHiddenInput('brand', result.token.card.brand);

    form.appendChild(stripe_token);
    form.appendChild(last4);
    form.appendChild(brand);
  }

  newHiddenInput(name, value){

    if (document.contains(document.getElementById(name))) {
      document.getElementById(name).remove();
    }

    let hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('id', name);
    hiddenInput.setAttribute('name', `sign_up_form[${name}]`);
    hiddenInput.setAttribute('value', value);
    return hiddenInput;
  }

  paramsNewCard(result){
    return{
      stripe_token: result.id,
      last4: result.card.last4,
      brand: result.card.brand
    }
  }

  saveCard(e, data){
    let action = e.target.form.action;
    const errorMsg = document.querySelector("#credit_card_error");
    const innerError = document.querySelector("#inner-error");

    axios.post(action, { credit_card: data })
      .then(({ data }) => {
        window.location.href = window.location.href;
      })
      .catch((error) =>  {
        e.target.classList.remove("is-loading");
        errorMsg.classList.remove("is-hidden");
        innerError.innerHTML  = ""
        let errorMessage = error.response.data.error_message;
        if (errorMessage !== undefined) {
          innerError.innerHTML += `<p>${errorMessage}</p>`;
        }
    });
  }

}
