import { Controller } from "stimulus"
import CustomerMetricsChart from '../charts/customer-metrics-chart'


export default class extends Controller {
  connect() {
    // Customer Metrics Chart
    const customerMetricsCharts = document.querySelectorAll('.customer-metrics-chart')
    customerMetricsCharts.forEach(function (customerMetricsChart) {
      const id = customerMetricsChart.getAttribute('id')
      const chart = window[id]
      const obj = new CustomerMetricsChart(chart)
      obj.init()
    })
  }
}