class Chart {
  constructor (chart) {
    this.suscriptions = []
    this.data = chart.data
    this.id = chart.id
    this.series = chart.series
    this.key = chart.key
    this.x_axis_title = chart.x_axis_title
    this.requiredState = chart.state
    this.colorMapping = chart.colorMapping
    this.reverseOrder = chart.reverseOrder
    this.setState()
  }

  subscribe (obj) {
    this.suscriptions.push(obj)
  }

  setState () {
    const states = []
    this.suscriptions.forEach(function (suscription) {
      states.push(suscription.state)
    })
    if (states.sort().toString() === this.requiredState.sort().toString()) {
      const self = this
      window.setTimeout(function () {
        self.init()
      }, 250)
    } else {
      this.clear()
    }
  }

  clear () {
    const self = this
    if (typeof this.chart !== 'undefined') {
      window.setTimeout(function () {
        self.chart.dispose()
      }, 500)
    }
  }

  update (obj = null) {
    this.setState()
  }
}

export default Chart
