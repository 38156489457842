import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class CreditBureauScoreChart {
  constructor (chart) {
    this.id = chart.id
    this.data = +chart.data
    this.min = +chart.min
    this.max = +chart.max
  }

  init () {
    try {
      var chart = am4core.create(this.id, am4charts.GaugeChart);
      chart.innerRadius = -15;

      var axis = chart.xAxes.push(new am4charts.ValueAxis());
      axis.min = this.min;
      axis.max = this.max;
      axis.strictMinMax = true;
      axis.renderer.labels.template.disabled = true;
      axis.renderer.ticks.template.disabled = true;
      axis.renderer.grid.template.disabled = true;
      axis.renderer.grid.template.disabled = true;

      var rangeMin = axis.axisRanges.create();
      rangeMin.value = axis.min;
      rangeMin.label.text = "" + axis.min;

      var rangeMax = axis.axisRanges.create();
      rangeMax.value = axis.max;
      rangeMax.label.text = "" + axis.max;

      var colorSet = new am4core.ColorSet();

      var gradient = new am4core.LinearGradient();
      gradient.stops.push({color:am4core.color("red")})
      gradient.stops.push({color:am4core.color("yellow")})
      gradient.stops.push({color:am4core.color("green")})

      axis.renderer.line.stroke = gradient;
      axis.renderer.line.strokeWidth = 10;
      axis.renderer.line.strokeOpacity = 1;

      var hand = chart.hands.push(new am4charts.ClockHand());
      hand.radius = am4core.percent(107);
      hand.parent.zIndex = 100;
      hand.innerRadius = am4core.percent(45);
      hand.startWidth = 3;
      hand.pin.disabled = true;
      hand.value = this.data;

      var label = chart.radarContainer.createChild(am4core.Label);
      label.isMeasured = false;
      label.fontSize = 14;
      label.fontWeight = "bold";
      label.x = am4core.percent(50);
      label.y = am4core.percent(100);
      label.horizontalCenter = "middle";
      label.verticalCenter = "bottom";
      label.text = this.data;
    } catch(error) {
      console.error('Credit Bureau score chart error: ', error);
    }
  }
}

export default CreditBureauScoreChart;