import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import es_ES from '@amcharts/amcharts4/lang/es_ES';

class updatesHistoryChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
  }

  init () {
    const chartContainer = document.getElementById(this.id)
    const mainReportsRows = document.querySelectorAll('[data-main-report-row]')

    // Chart instance
    var chart = am4core.create(this.id, am4charts.XYChart)

    //change language
    chart.language.locale = es_ES

    // Add data
    chart.data = JSON.parse(chartContainer.dataset.updatesHistory)

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    //Add format to range values 
    valueAxis.numberFormatter = new am4core.NumberFormatter()
    valueAxis.numberFormatter.numberFormat = '####'

    // Create series
    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "value";
    lineSeries.dataFields.dateX = "date";
    lineSeries.name = "Sales";
    lineSeries.strokeWidth = 3;
    lineSeries.stroke = am4core.color("#0099f8");

    //Set range values
    chart.yAxes.getIndex(0).min = 0;
    chart.yAxes.getIndex(0).max = parseInt(chartContainer.dataset.scoreAverage);
    
    //Set YAxis range distance
    chart.yAxes.getIndex(0).renderer.minGridDistance = 15

    // Add YAxisScrollbar
    var scrollbar = new am4charts.XYChartScrollbar();
    scrollbar.series.push(lineSeries)
    chart.scrollbarY = scrollbar;

    // Add simple bullet
    var bullet = lineSeries.bullets.push(new am4charts.Bullet());
    var image = bullet.createChild(am4core.Image);
    image.href = "https://freesvg.org/img/mono-point.png";
    image.width = 30;
    image.height = 30;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";

    // Popover Bullet
    bullet.events.on("over", function(ev) {
      const reportId = ev.target.dataItem.dataContext.report_id
      const popoverHtml = ev.target.dataItem.dataContext.popover_html 
      
      mainReportsRows.forEach(row => {row.classList.remove('has-background-light')});
      mainReportsRows.forEach(row => {
        if(row.dataset.reportId == reportId)
          row.classList.toggle('has-background-light')
      })

      ev.target.tooltip.getFillFromObject = false
      ev.target.tooltip.background.fill = am4core.color("#fafafa")
      ev.target.tooltip.background.strokeWidth = 1
      ev.target.tooltipHTML = popoverHtml
    });

    bullet.events.on("out", function() {
      mainReportsRows.forEach(row => {row.classList.remove('has-background-light')});
    })
  }
}

export default updatesHistoryChart
