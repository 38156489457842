import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["showFullList"];j

  showFinantialDataByRange(e) {
    const dateRange = document.querySelector(`[data-range-value='${e.target.value}']`).dataset.dateRange;
    const section = e.target.closest('.card-body').dataset.section;
    const tableData = document.querySelector(`[data-for='${section}'] tbody[data-date-range='${dateRange}']`);
    let chartData = "";
    
    (document.querySelectorAll(`[data-for='${section}'] .chart-wrapper`) || []).forEach((e) => {
      const all_data = window[`chart_${e.dataset.chart}_obj`].data;
      let chartId = "";

      if(e.dataset.chart === "finantial_products_diversification"){
        chartId = `finantial_product_diversification_${dateRange}`;
        chartData = all_data[chartId];
      } else {
        chartData = this.buildChartData(all_data, dateRange)
      }
      
      
      window[`chart_${e.dataset.chart}_obj`].chart.data = chartData
    });

    (document.querySelectorAll(`[data-for='${section}'] tbody[data-date-range]`) || []).forEach(element => {
      element.classList.add("is-hidden");
    });
    e.target.parentElement.querySelectorAll("[data-date-range-label]").forEach((label) => { label.classList.remove("selected") });
    e.target.parentElement.querySelector(`[data-range-value="${e.target.value}"]`).classList.add("selected");

    tableData.classList.remove("is-hidden");
    this.handleShowFullListLink(dateRange)
  }

  handleShowFullListLink(dateRange) {
    const linkLocation = this.showFullListTarget.href.substring(0, this.showFullListTarget.href.lastIndexOf("/"));
    this.showFullListTarget.href = `${linkLocation}/${dateRange}`;
  }

  buildChartData(data, range){
    return [
      { title: 'Ingreso Vigente', amount: data[`net_income_${range}`] },
      { title: 'Gasto Financiero', amount: (data[`total_${range}_sales`] || data[`${range}_total_invoices`]) }
    ];
  }
}

