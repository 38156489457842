import { Controller } from "stimulus";
import axios from 'axios';
import RfcSearchTypeahead from "../packs/rfc_search_typeahead"

export default class extends Controller {
  static targets = ["url"]

  connect() {
    new RfcSearchTypeahead().start(this.urlTarget.dataset.url);

    this.disable_timeout()
  }

  fetchConcepts(e) {
    const parent = e.target.parentElement;
    const invoiceId = parent.dataset.invoiceId;
    let conceptsURL = parent.dataset.conceptsUrl;
    let conceptsWrapper = document.querySelector(`[data-concepts-for-invoice-id="${invoiceId}"]`)
    let detailedData = conceptsWrapper.parentElement;

    if (detailedData.classList.contains("concepts-loaded")) { return }

    conceptsWrapper.innerHTML = "";

    this.fetchComplements(conceptsURL, conceptsWrapper, detailedData).then(() => {
      if(parent.dataset.effectVoucher === 'I') {
        conceptsURL =  parent.dataset.complementsUrl
        detailedData = conceptsWrapper.parentElement;

        this.fetchComplements(conceptsURL, conceptsWrapper, detailedData);
      }
    });
  }

  fetchComplements(conceptsURL, conceptsWrapper, detailedData) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        axios.get(conceptsURL).then(({ data }) => {
          conceptsWrapper.innerHTML += data.html_content;
          const tableHeight = conceptsWrapper.offsetHeight;
          detailedData.classList.add("concepts-loaded")

          detailedData.style.height = `calc(${tableHeight}px + 3em)`;

          resolve(true);
        }).catch(() => {
          conceptsWrapper.innerHTML += `<table class="table is-fullwidth">
            <tbody>
              <tr>
                <td class="has-text-centered has-text-weight-bold">Ocurrió un error al obtener la lista de conceptos</td>
              </tr>
            </tbody>
          </table>`;
          const tableHeight = conceptsWrapper.offsetHeight;
          detailedData.classList.add("concepts-loaded")

          detailedData.style.height = `calc(${tableHeight}px + 3em)`; 
          resolve(true);
        })

      }, 200)
    })
  }

  active_timeout(btn){
    setTimeout(() => {
      btn.disabled = false
      btn.querySelector('span').textContent = 'Generar Reporte' 
    }, 30000);
  }

  disable_timeout(e){
    const btnGenerateReport = document.querySelector('#btn_generate_report')
    const listElements = document.querySelectorAll("#download_invoices_list tbody tr")
    const last = listElements.length-1

    if (listElements.length > 0){
      const lastClass = listElements[last].querySelector('span').className;
      const firstClass = listElements[0].querySelector('span').className;

      if (lastClass.includes('yellow') || firstClass.includes('yellow')){
        btnGenerateReport.querySelector('span').textContent = 'Reporte en proceso' 
        btnGenerateReport.disabled = true
        this.active_timeout(btnGenerateReport)
      }else{
        btnGenerateReport.disabled = false
      }
    }else{
      if(btnGenerateReport.textContent.includes('Generar'))
        btnGenerateReport.disabled = false
      else
        this.active_timeout(btnGenerateReport)
    }
  }

}