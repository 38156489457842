import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class ExternalPayrollChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
    this.total_payroll = chart.total_payroll
  }

  init () {
    const _this = this
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data
    var scrollbarX = new am4charts.XYChartScrollbar();

    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 50

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())

    this.series.forEach((serieName) => {
      const columnSerie = this.chart.series.push(new am4charts.ColumnSeries())
      const name = serieName === 'nil' ? '' : serieName
      columnSerie.dataFields.valueY = name
      columnSerie.dataFields.dateX = "date"
      columnSerie.name = serieName;
      columnSerie.strokeWidth = 1
      columnSerie.tooltipText = "{name}: [bold]{valueY}[/]";
      columnSerie.stacked = true
      columnSerie.columns.template.width = am4core.percent(95)
      if(serieName === 'nil') {
        columnSerie.hiddenInLegend = true;
      }

      // Add scrollbar
      scrollbarX.series.push(columnSerie)
    })

    this.chart.scrollbarX = scrollbarX;
    this.chart.scrollbarY = new am4core.Scrollbar();

    // Add legend
    this.chart.legend = new am4charts.Legend()
    this.chart.legend.maxHeight = 150
    this.chart.legend.scrollable = true

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()

    dateAxis.events.on("startchanged", _this.dateAxisChanged);
    dateAxis.events.on("endchanged", _this.dateAxisChanged);

    this.chart.zoomOutButton.events.on("hit", function() {
      let totalPayrollEl = document.querySelector("[data-total-external-payroll]")
      totalPayrollEl.innerText = _this.total_payroll
    })
  }

   dateAxisChanged(e) {
    let totalPayrollEl = document.querySelector("[data-total-external-payroll]")
    let start = new Date(e.target.minZoomed);
    let end = new Date(e.target.maxZoomed);
    let startDate = new Date(start.getFullYear(), start.getMonth(), 1)
    let endDate = new Date(end.getFullYear(), end.getMonth() + 1, 0)
    let values = window.chart_external_payroll["data"].filter( data => new Date(data["date"]) >= startDate && new Date(data["date"]) <= endDate)
    let amount = values.reduce(function(accumulator, currentValue) {return accumulator + Object.values(currentValue)[4] }, 0);

    totalPayrollEl.innerText = isNaN(amount) ? "$0" : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(amount);
  }
}

export default ExternalPayrollChart;
