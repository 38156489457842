import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class LineSeriesChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
  }

  init () {
    const _this = this
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.data

    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 50

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())

    // TODO iterate data to create series
    this.series.forEach((serieName) => {
      const lineSeries = this.chart.series.push(new am4charts.LineSeries())
      lineSeries.dataFields.valueY = serieName
      lineSeries.dataFields.dateX = "date"
      lineSeries.name = serieName;
      lineSeries.tooltipText = "{name}: {valueY.formatNumber('$#,###.##')}"
      lineSeries.strokeWidth = 1

      // Add scrollbar
      this.chart.scrollbarX = new am4charts.XYChartScrollbar();
      this.chart.scrollbarX.series.push(lineSeries);
    })

    // Add legend
    this.chart.legend = new am4charts.Legend()
    this.chart.legend.maxHeight = 150;
    this.chart.legend.scrollable = true;

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }
}

export default LineSeriesChart;

