import { Controller } from "stimulus";
import axios from 'axios';

export default class extends Controller {

    connect () {
        this.fetchWalletCurrentUser()
    }
    
  

    fetchWalletCurrentUser() {
        const url = document.querySelector("#nav-wallet").dataset.userWalletUrl;
        const currentPlan = JSON.parse(document.querySelector("#nav-wallet").dataset.currentPlan);

        let counter = 0;
        let charged = false
        let _this = this
        
        if ( currentPlan ) {

            this.setHeader(currentPlan);

        } else {

            const intervalId = setInterval(() => {

                counter += 1;
        
                if (!charged) {
                    
                    axios.get(url)
                    .then(({ data }) => {
                        
                        if( data.user_id ) {
                            charged = true;
                            _this.setHeader(data);
                            clearInterval(intervalId);
                        }
                    })
                }

                if(counter === 10 || charged ) {
                    
                    clearInterval(intervalId);
                    (document.querySelectorAll(".is-loading") || []).forEach((e) => {
                    e.classList.remove("button", "is-small", "is-white", "is-loading")
                })
                }
            }, 10000);
        }
        
    }

    showMenu(currentPlan) {
        const preverificationMenu = document.querySelector("#menu-preverification")
        const urlPreverification = preverificationMenu.dataset.url;
        const textPreverification = preverificationMenu.dataset.text;
        
        if ( currentPlan.pre_verifications != 0 ){
            preverificationMenu.innerHTML = this.menuItemHTML(urlPreverification, textPreverification, 'preverification');
        }

        const usersMenu = document.querySelector("#menu-users")

        if ( usersMenu ) {

            const urlUsers = usersMenu.dataset.url;
            const textUsers = usersMenu.dataset.text;
            
            if( currentPlan.max_operational_users > 0 ) {
                usersMenu.innerHTML = this.menuItemHTML(urlUsers, textUsers, 'users');
            }

        }
    }

    menuItemHTML(url, text, icon){
        return ` 
        <a class='has-text-black' href='${url}'>
            <span class="icon ${icon} icon-primary is-small"></span>
            <span>${text}</span>
        </a>
        `;
    }

    setHeader(data){

        let walletIcon = document.querySelector("[data-wallet-icon]");
        const livesScoresEl = document.querySelector("#progress-livereports");

        const total = data.subscription.base_live_scores + (data.extra_reports || 0)

        const active = total - data.live_scores

        
        let liveScores = document.querySelector("#header-livescores")
        liveScores.classList.remove("button", "is-small", "is-white", "is-loading")
        
        liveScores.innerText =  `${active} / ${total}`
        let livescorePercentage = data.live_scores === 0 ? 100 : (active * 100) / total;

        livesScoresEl.value = livescorePercentage

        let balance = document.querySelector("#header-balance")
        balance.classList.remove("button", "is-small", "is-white", "is-loading")
        
        if(data.balance < 0) {

            balance.classList.add("has-text-danger")
            walletIcon.classList.remove("icon-black")
            walletIcon.classList.add("icon-red")

        }

        balance.innerText = data.balance
        let creditPercentage = (data.balance * 100) / data.subscription.base_credits;
        document.querySelector("#progress-balance").value = creditPercentage
        
        this.showMenu(data.subscription)

    }
}
