// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import JQuery from 'jquery';
window.$ = window.JQuery = window.jQuery = JQuery;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('reports/report')
require('home')
require('home/scrollTo')
require('home/footerScroll')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
import "@fortawesome/fontawesome-free/js/all.js";
import "@hotwired/turbo-rails";
import { Turbo } from "@hotwired/turbo-rails";
import * as am4core from '@amcharts/amcharts4/core'

Turbo.session.drive = false
am4core.addLicense("CH422611578");

document.addEventListener('turbolinks:load', () => {
  (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    let $notification = $delete.parentNode;
    $delete.addEventListener('click', () => {
      $notification.parentNode.removeChild($notification)
    });
  });


  (document.querySelectorAll('.modal-close, .modal-cancel') || []).forEach(($close) => {
    $close.addEventListener('click', (e) => {
      e.preventDefault();
      let modal = e.target.closest('.modal');
      modal.querySelectorAll(".help.is-danger").forEach((element) => element.remove());
      modal.querySelectorAll(".is-danger").forEach((element) => element.classList.remove("is-danger"));
      modal.querySelectorAll(".is-danger").forEach((element) => element.classList.remove("is-danger"));
      modal.querySelectorAll(".has-text-danger").forEach((element) => element.classList.add("is-hidden"));
      modal.classList.remove("is-active");
    })
  });

  (document.querySelectorAll(".modal-background") || []).forEach(($element) => {
    $element.addEventListener('click', (e) => {
      e.target.parentElement.classList.remove('is-active');
      let btn = document.querySelector(".reports-footer .button.is-loading");
      if (btn) { btn.classList.remove("is-loading") }
    });
  });

  (document.querySelectorAll("[data-back-to='top']") || []).forEach(($element) => {
    $element.addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  });

  (document.querySelectorAll("#right-menu li") || []).forEach(($element) => {
    $element.addEventListener('click', (e) => {
      e.preventDefault();
      let anchor = document.querySelector(`#${e.target.dataset.anchor}`);
      const parentElement = e.target.parentElement.parentElement;
      const offsetScroll = +parentElement.dataset.offsetScroll;

      if(anchor) {
        let offsetTop = anchor.offsetTop + offsetScroll;
        if (e.target.dataset.childElement == "1") { offsetTop += anchor.offsetParent.offsetTop }

        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
    });
  });

  const changeEvent = new Event("change");
  (document.querySelectorAll("[data-date-range-label]") || []).forEach(($element) => {
    $element.addEventListener("click", (e) => {
      let $dateRange = e.target.parentElement.previousElementSibling;
      document.querySelectorAll("[data-date-range-label]").forEach((label) => { label.classList.remove("selected") });
      e.target.classList.add("selected");

      $dateRange.value = e.target.dataset.rangeValue;
      $dateRange.dispatchEvent(changeEvent);
    })
  });

  (document.querySelectorAll("[data-field='rfc']") || []).forEach(($element) => {
    $element.onkeydown = function (e) {
      var value =  e.target.value;
      if (e.key && !e.key.match(/[a-zA-Z0-9]/)) {
        e.preventDefault();
      }
    };
  });

  (document.querySelectorAll("[data-close-modal]") || []).forEach(($element) => {
    $element.addEventListener("click", (e) => {
      document.querySelector(".modal-extra").classList.remove("is-active")
    })
  });

  (document.querySelectorAll('[data-action="collapse"]') || []).forEach(($element) => {
    $element.addEventListener("click", function(e) {
      e.preventDefault();
      e.stopPropagation();
      const collapsibleId = e.target.getAttribute("href")
      const icon = e.target.firstElementChild;
      const collapsibleElement = document.querySelector(collapsibleId);

      if (collapsibleElement) {
        icon.classList.toggle("fa-angle-down");
        icon.classList.toggle("fa-angle-up");
        collapsibleElement.classList.toggle("is-active");
      }
    })
  });

  (document.querySelectorAll('[data-action="collapse-menu"]') || []).forEach(($element) => {
    $element.addEventListener("click", function(e) {
      e.preventDefault();
      e.stopPropagation();
      const collapsibleMenu = e.target.parentElement;
      const icon = e.target.firstElementChild;
      const collapsibleWrapper = collapsibleMenu.parentElement;
      const htmlWrapper = document.querySelector("html");

      if (collapsibleMenu) {
        icon.classList.toggle("fa-angle-double-left");
        icon.classList.toggle("fa-angle-double-right");
        collapsibleMenu.classList.toggle("is-active");
        collapsibleWrapper.classList.toggle("is-active");
      }

      if(collapsibleMenu.classList.contains("is-active")) {
        htmlWrapper.style.overflow = "hidden";
      } else {
        htmlWrapper.style.overflow = "initial";
      }
    })
  });

  document.addEventListener('mousedown', (e) => {
    (document.querySelectorAll('.dropdown.is-active') || []).forEach((dropdown) => {
      setTimeout(() => {
        dropdown.classList.remove('is-active');
      }, 200)
    });
  });

  const rightMenu = document.getElementById('right-menu');
  const navItems = (rightMenu?.querySelectorAll("[data-item='navbar-item'] a") || [])
  const navSections = new Array(navItems.length);

  for (let i = 0; i < navItems.length; i++) {
    navSections[i] = document.getElementById(navItems[i].dataset.anchor);
  }

  function isVisible(ele) {
    const r = ele.getBoundingClientRect();
    const h = (window.innerHeight || document.documentElement.clientHeight);
    const w = (window.innerWidth || document.documentElement.clientWidth);

    return (r.top <= 140) &&
        (r.top + r.height >= 140)
  }
  function activateIfVisible() {
    let b = true;
    for (let i = 0; i < navSections.length; i++) {
      if (navSections[i] && b && isVisible(navSections[i])) {
          navItems[i].parentElement.classList.add('is-active');
          b = false;
      } else {
          navItems[i].parentElement.classList.remove('is-active');
          b = true;
      }
    }
  }
  var isTicking = null;
  window.addEventListener('scroll', () => {
      if (!isTicking) {
          window.requestAnimationFrame(() => {
              activateIfVisible();
              isTicking = false;
          });
          isTicking = true;
      }
  }, false);

  var transitionEnd = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend';
  $('#left-menu').on(transitionEnd, function() {
    if ($(this).width() < 100) {
      document.getElementById('myProfile').classList.remove('is-active');
    }
	});

  const exitFullscreeBtn = document.getElementById('exit-fullscreen-btn')
  const enterFullscreeBtn = document.getElementById('enter-fullscreen-btn')

  document.addEventListener("fullscreenchange", () => {
    if (document.fullscreenElement) {
      exitFullscreeBtn.classList.remove('hidden')
      enterFullscreeBtn.classList.add('hidden');
    } else {
      exitFullscreeBtn.classList.add('hidden')
      enterFullscreeBtn.classList.remove('hidden');
    }
  });
});

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
