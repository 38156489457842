import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class FinantialProductsChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.initial_data = chart.initial_data
  }

  init () {
    // State validation
    this.chart = am4core.create(this.id, am4charts.XYChart)
    this.chart.data = this.initial_data
    this.chart.fontSize = 11

    // Create axes
    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = "title"
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 20
    categoryAxis.renderer.cellStartLocation = 0.1
    categoryAxis.renderer.cellEndLocation = 0.9

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.min = 0

    const series = this.chart.series.push(new am4charts.ColumnSeries())

    series.dataFields.valueY = 'amount'
    series.dataFields.categoryX = 'title'
    series.columns.template.fillOpacity = .8
    series.columns.template.strokeWidth = 0
    series.tooltipText = "[{categoryX}: bold]{valueY.formatNumber('$#,###.##')}[/]";

    const finantialProductBullet = series.bullets.push(new am4charts.LabelBullet())
    finantialProductBullet.label.text = "{valueY.formatNumber('$#,###.##')}"
    finantialProductBullet.dy = -10
    finantialProductBullet.fontSize = 10


    series.columns.template.adapter.add("fill", function(fill, target) {
      const dataItem = target.tooltipDataItem
      if(!dataItem) { return }

      const color = dataItem.dataContext.title == "Ingreso Vigente" ? '#00cc00' : '#ff0000'

      return am4core.color(color);
    });

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }
}

export default FinantialProductsChart
