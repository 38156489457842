import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["subscriptionModal", "subscriptionForm"];

  selectSubscription(e) {
    document.querySelectorAll(".plan-box-current").forEach((e) => { e.classList.add("is-hidden") });
    e.target.parentElement.querySelector(".plan-box-current").classList.remove("is-hidden");
  }

  requestSubscriptionChange(e) {
    e.preventDefault();

    const subscriptionName = e.target.parentElement.dataset.subscriptionName;

    this.subscriptionModalTarget.classList.add("is-active");
    document.querySelectorAll("[data-subscription-disclaimer-for]").forEach(($disclaimer) => {
      $disclaimer.classList.add("is-hidden");
    });

    document.querySelector(`[data-subscription-disclaimer-for="${subscriptionName}"]`).classList.remove("is-hidden");
  }

  submitForm(e) {
    e.preventDefault();
    const parentElement = e.target.parentElement;

    if (parentElement.dataset.currentPlan === parentElement.dataset.subscriptionName) {
      return
    }

    const subscriptionName = e.target.parentElement.dataset.subscriptionName;
    const $subscription = document.querySelector(`input[type="radio"][value="${subscriptionName}"]`);
    $subscription.checked = true;
    e.target.disabled = true;
    e.target.classList.add("is-loading")
    this.subscriptionFormTarget.submit();
  }

  cancelAction() {
    this.subscriptionModalTarget.classList.remove("is-active");
  }

  planSelected(e) {
    const priceContainer = document.querySelector('.selected-plan-price');
    const submitContainer = document.querySelector('[data-subscription-name]');
    submitContainer.setAttribute('data-subscription-name', e.target.dataset.name);
    priceContainer.innerHTML = e.target.dataset.price;
  }
}
