import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class  DiversificationChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.range = chart.range
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.TreeMap)
    this.chart.data = this.data[this.range]
    this.chart.colors.step = 2

    // define data fields
    this.chart.dataFields.value = 'amount'
    this.chart.dataFields.name = 'name'

    /* Create top-level series */
    const level1 = this.chart.seriesTemplates.create('0')
    const level1Column = level1.columns.template
    level1Column.fillOpacity = 0
    level1Column.strokeOpacity = 0
    level1Column.column.cornerRadius(2, 2, 2, 2)
    level1Column.fillOpacity = 0.8
    level1Column.stroke = am4core.color('#fff')
    level1Column.strokeWidth = 1
    level1Column.strokeOpacity = 1

    const bullet1 = level1.bullets.push(new am4charts.LabelBullet())
    bullet1.locationY = 0.5
    bullet1.locationX = 0.5
    bullet1.label.text = '{name}'
    bullet1.label.fill = am4core.color('#ffffff')
    bullet1.interactionsEnabled = false
  }
}

export default DiversificationChart;
