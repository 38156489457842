import BaseValidator from "./base_validator";

export default class LengthValidator extends BaseValidator {
  validate() {
    let valid = false;
    let errorMessage = ""

    if((!this.value || this.value === "") && !this.options.allow_blank) {
      valid = false;
      return { valid: false, message: this.errorMessages["not_a_number"] || this.errorMessages["wrong_length"] };
    }

    if(this.options.minimum) { valid = this.validateMinLength() };
    if(this.options.is) { valid = this.validateExactLength() };
    if(this.options.maximum) { valid = this.validateMaxLength() };

    if(!valid) {
      errorMessage = this.errorMessages["wrong_length"];
    }

    return { valid: valid, message: errorMessage };
  }

  validateMinLength() {
    return this.value.length >= this.options.minimum;
  }

  validateExactLength() {
    console.log(this.options.if)
    return this.options.is === this.value.length;
  }

  validateMaxLength() {
    return this.value.length <= this.options.maximum;
  }
}
