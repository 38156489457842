export default class BaseValidator {
  constructor(value, options, errorMessages) {
    this.value = value;
    this.options = options;
    this.errorMessages = errorMessages;
  }

  validate() {
    return { valid: false, message: "Not implemented" };
  }
}
