import BaseValidator from "./base_validator";

export default class PresenceValidator extends BaseValidator {
  validate() {
    let valid = false;
    let errorMessage = "";

    if(this.value) {
      let cleanValue = this.value.trim();
      valid = cleanValue !== "";
    }

    if(!valid) {
      errorMessage = this.errorMessages["blank"];
    }

    return { valid: valid, message: errorMessage };
  }
}
