import { Controller } from "stimulus"

export default class PreVerificationBaseController extends Controller {
  static targets = ["submitBtn"]
  connect() {
    this.rfcRegex = /(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})/
    this.maxlengthName = 50
  }

  removeError(element) {
    let $parent = element.parentNode;
    let help = $parent.querySelector("p.help");

    if(help) { $parent.removeChild(help); }
    element.classList.remove("is-danger");
  }

  handleRFCError(rfc, errorMessage) {
    this.submitBtnTarget.disabled = false;

    if(rfc.value.trim().length == 0) {
      this.removeError(rfc)
      return
    }

    let regex = new RegExp(this.rfcRegex)


    if(!regex.test(rfc.value)) {
      this.showError(errorMessage, rfc);
      return;
    }
  }

  handleEntityNameError(entityName, errorMessage) {
    this.submitBtnTarget.disabled = false;

    if(entityName.value.trim().length == 0) {
      this.removeError(entityName)
      return
    }

    if(50 < entityName.value.trim().length){
      this.showError(errorMessage, entityName);
      return
    }
  }

  showError(errorMessage, element) {
    let help = document.createElement("p")
    let $parent = element.parentNode;

    help.setAttribute("class", "help is-danger")
    help.innerHTML = errorMessage;

    element.classList.add("is-danger")
    $parent.appendChild(help);
    this.submitBtnTarget.disabled = true
  }

  rfcChangeHandler(e) {
    let rfc = e.target
    const entity = document.getElementById("entity-field")
    this.removeError(entity);

    this.validateRfcChange(rfc)
  }

  validateRfcChange(rfc) {
    this.removeError(rfc);
    if(rfc.value.trim().length != 0) {
      this.handleRFCError(rfc, "RFC no valido");
    }
  }

  entityNameChangeHandler(e) {
    let entityName = e.target

    this.validateEntityNameChange(entityName)
  }

  validateEntityNameChange(entityName) {
    this.removeError(entityName);

    const alerts = entityName.form.querySelectorAll('.help.is-danger')

    if (entityName.value.length && entityName.value.length <= this.maxlengthName && !alerts.length) {
      return this.submitBtnTarget.disabled = false;
    }

    if(entityName.value.length == 0) {
      return this.handleEntityNameError(entityName, "Favor de usar por lo menos Nombre y Apellido");
    }

    if (this.maxlengthName < entityName.value.length){
      return this.handleEntityNameError(entityName, "Favor de usar un nombre menor a 50 caracteres");
    }
  }
}
