import { Controller } from "stimulus";
import { createMonthSlider, getDateRange } from "./lib/monthslider";
import axios from "../packs/setupCSRFToken";

export default class extends Controller {
  static targets = ["sliderElement", "historicLink", "search", "form"];

  initialize() {
    const url = this.sliderElementTarget.dataset.url;
    const presetDateRanges = document.querySelector(".preset-date-ranges")
    let totalMonths = this.historicLinkTarget.dataset.months

    if(totalMonths < 0) {
      totalMonths = 12
    }

    this.slider = createMonthSlider({
      sliderElement: this.sliderElementTarget,
      url: url,
      containerElement: ".result-list",
      presetDateRanges: presetDateRanges,
      totalMonthsShow: totalMonths,
    });

    this.slider.init();
    this.bindForm()
  }

  bindForm() {
    addEventListener("ajax:success", (event) => {
      const [data, _status, _xhr] = event.detail;
      let resultList = document.querySelector(".result-list");
      resultList.innerHTML = data.html_content;
      this.updateFilterResults(data.total_records, data.record_type)
    })
  }

  searchByName(e) {
    const textToSearch = e.target.value;
    const url = e.target.closest("form").action;
    const charCode = e.keyCode;
    if((charCode >= 9 && charCode <= 47) || (charCode >= 112 && charCode <= 151) || (charCode >= 91 && charCode <= 95)) { return }
    this.searchClients(url, textToSearch)
  }

  searchByPresetDateRange(e) {
    e.preventDefault()
    const months = e.target.dataset.months;
    const totalClients = document.querySelector("[data-total-clients]").dataset.totlaClients;
    const url = this.searchTarget.closest("form").action;

    e.target.closest("ul").querySelectorAll("li").forEach((element) => {
      element.classList.remove("is-active")
    })

    e.target.parentElement.classList.add("is-active");
    this.slider.updateSlider(months);
    this.searchClients(url, this.searchTarget.value);
  }

  searchClients(url, textToSearch) {
    let dateRange = this.slider.getDateRange();

    axios.get(url, { params: { from: dateRange[0], to: dateRange[1], search: textToSearch } })
    .then(({ data }) => {
      let resultList = document.querySelector(".result-list");
      resultList.innerHTML = data.html_content;
      this.updateFilterResults(data.total_records, data.record_type)
    });
  }

  updateFilterResults(total, recordType) {
    const totalClients = document.querySelector("[data-total-clients]").dataset.totalClients;
    let totalRecordsElement = document.querySelector("[data-filtered-records]")
    let dateRangeElement = document.querySelector("[data-filtered-range");
    let dateRange = this.slider.getDateRange();

    if(parseInt(totalClients) == parseInt(total)) {
      totalRecordsElement.innerText = ""
      dateRangeElement.innerText = ""
    } else {
      totalRecordsElement.innerText = `${total} ${recordType} con los filtros seleccionados`;
      dateRangeElement.innerText = `${recordType} con facturacion en el rango ${dateRange[0]} a ${dateRange[1]}`
    }
  }
}
