import { Controller } from "stimulus"
import SupplierMetricsChart from './../charts/supplier-metrics-chart'

export default class extends Controller {
  connect() {
    // Supplier Metrics Chart
    const supplierMetricsCharts = document.querySelectorAll('.supplier-metrics-chart')
    supplierMetricsCharts.forEach(function (supplierMetricsChart) {
      const id = supplierMetricsChart.getAttribute('id')
      const chart = window[id]
      const obj = new SupplierMetricsChart(chart)
      obj.init()
    })
  }
}