import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class DsoDpoAmountChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.average = chart.average
  }

  init () {

    this.chart = am4core.create(this.id, am4charts.XYChart)

    this.chart.data = this.data

    let first = this.data[0];
    let last = this.data.at(-1);

    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const scrollbarX = new am4charts.XYChartScrollbar();
    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "amount";
    categoryAxis.renderer.minGridDistance = 15;
    categoryAxis.renderer.labels.template.rotation = 90;
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.horizontalCenter = "left";
    categoryAxis.renderer.labels.template.location = 0.5;

    categoryAxis.renderer.labels.template.tooltipText = "{amount}";
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.labels.template.disabled = true;


    categoryAxis.numberFormatter = new am4core.NumberFormatter()
    categoryAxis.numberFormatter.numberFormat = '$#,###.##'
    
    categoryAxis.renderer.labels.template.adapter.add("dx", function(dx, target) {
        return -target.maxRight / 2;
    })

    this.chart.paddingBottom = 40;
    
    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Días"
    
    const axisRange = valueAxis.axisRanges.create();
    axisRange.value = this.average;
    axisRange.grid.strokeOpacity = 1;
    axisRange.grid.stroke = am4core.color("#0000FF");
    axisRange.grid.strokeWidth = 1;
    axisRange.grid.strokeDasharray = "3,3";
    axisRange.grid.above = true;
    
    // Create series
    var series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "amount";
    series.name = `Rango de facturación de ${formatter.format(first.amount)} a ${formatter.format(last.amount)}`;
    series.strokeWidth = 0
    series.columns.template.tooltipText = "{categoryX.formatNumber('$#,###.##')}: [bold]{valueY.formatNumber('#,###.##')}[/]";
    series.columns.template.fill = am4core.color("#00cc00")
    
    
    scrollbarX.series.push(series)
    const scrollAxis = scrollbarX.scrollbarChart.xAxes.getIndex(0);
    scrollAxis.numberFormatter = new am4core.NumberFormatter()
    scrollAxis.numberFormatter.numberFormat = '$#,###.##'
    
    this.chart.scrollbarX = scrollbarX;
    this.chart.legend = new am4charts.Legend();  
    this.chart.cursor = new am4charts.XYCursor()

  }
}

export default DsoDpoAmountChart;

