import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return ['menu', 'bar']
  }

  menuToggle (event) {
    event.preventDefault()
    this.menuTarget.classList.toggle('active')
    this.barTarget.classList.toggle('hidden')
  }
}
