import { Controller } from "stimulus"
import DiversificationOfOutcomesChart from '../charts/diversification-of-outcomes-chart'

export default class extends Controller {
  connect() {
    // Diversification of outcomes chart
    const DiversificationOfOutcomesCharts = document.querySelectorAll('.diversification-of-outcomes-chart')
    DiversificationOfOutcomesCharts.forEach(function (diversificationOfOutcomesChart) {
      const id = diversificationOfOutcomesChart.getAttribute('id')
      if(!window[`${id}_obj`]) {
        const chart = window[id]
        chart.range = "twelve_months"
        var obj = new DiversificationOfOutcomesChart(chart)
        window[`${id}_obj`] = obj;
        window.diversificationOfOutcomesChart = obj;
        obj.init()
      }
    })
  }
}