import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.checkCookie();
  }

  checkCookie() {
    var cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      document.cookie = "ekatena_test";
      cookieEnabled = document.cookie.indexOf("ekatena_test") != -1;
    }
    return cookieEnabled || this.cookieFail();
  }

  cookieFail() {
    const url = this.data.get("urlValue")
    if (url) {
      window.location = url
    }
  }
}
