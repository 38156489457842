import { Controller } from 'stimulus';
import HistoricalPayrollBehaviorChart from '../charts/historical-payroll-behavior-chart'

export default class extends Controller {
  connect() {
    // Historical Payroll Behavior Chart
    setTimeout(function() {
      (document.querySelectorAll('.historical-payroll-behavior-chart') || []).forEach(function (historicalPayrollBehaviorChart) {
        const id = historicalPayrollBehaviorChart.getAttribute('id')
        const chart = window[id]
        const obj = new HistoricalPayrollBehaviorChart(chart)
        obj.init()
      })
    }, 500)
  }
}
