import { Controller } from "stimulus"
import DiversificationChart from './../charts/diversification-chart'

export default class extends Controller {
  connect() {
    const DiversificationCharts = document.querySelectorAll('.diversification-chart')
    DiversificationCharts.forEach(function (diversificationChart) {
      const id = diversificationChart.getAttribute('id');
      if(!window[`${id}_obj`]) {
        const chart = window[id];
        chart.range = `finantial_product_diversification_${chart.range}`
        var obj = new DiversificationChart(chart);
        window[`${id}_obj`] = obj;
        window.diversificationChart = obj;
        obj.init();
      }
    })
  }
}