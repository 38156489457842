import axios from "axios"

const addonsManagementResources = {
  sendManagmentForm(e, addonType, notificationTarget, selectorRowTarget){
    this.toggleSidebarModal(e)

    e.target.disabled = true

    const form = e.target.form 
    const formData = new FormData(form)
    const body = new URLSearchParams(formData).toString()
    const _this = this

    axios.post(form.action, body).then(() => {
      const message = notificationTarget.querySelector('p')
      message.textContent = message.dataset.successMessage

      notificationTarget.classList.remove('is-hidden')
      notificationTarget.classList.add('has-background-ekatena')
      
      const icon = selectorRowTarget.querySelector('.fa-clock')

      if (icon){
        icon.remove()
      }

      _this.updateClockIcon(addonType)
    }).catch(() => {
      const message = notificationTarget.querySelector('p')
      message.innerHTML = message.dataset.failMessage

      notificationTarget.classList.remove('is-hidden')
      notificationTarget.classList.add('is-warning')
    }).finally(() => { 
      e.target.disabled = false 
      this.toggleSidebarModal(e)
    });

    this.scheduleRemover(notificationTarget)
  },

  toggleSidebarModal(e){
    const modalId = e.target.form.dataset.modalId
    const modal = document.getElementById(`sidebar-${modalId}`)
    modal.classList.toggle('is-active')
  },

  updateClockIcon(addonType){
    const currentAddon = document.querySelector(`input[name='addons_${addonType}_update[id]']:checked`)

    let spanElement = document.createElement('span');
    spanElement.className = 'has-text-white fa fa-clock';

    document.querySelector(`label[for=${currentAddon.id}] .box-description`).append(spanElement)
  },

  scheduleRemover(notificationTarget){
    const message = notificationTarget.querySelector('p')

    setTimeout(() => {
      message.innerHTML = ''
      notificationTarget.classList.add('is-hidden')
      notificationTarget.classList.remove('is-warning')
      notificationTarget.classList.remove('has-background-ekatena')
    }, 5000);
  }
}

export default addonsManagementResources
