import { Controller } from "stimulus"
import CashflowChart from './../charts/cashflow-chart'

export default class extends Controller {
  connect() {
    const CashflowCharts = document.querySelectorAll('.cashflow-chart')
    CashflowCharts.forEach(function (cashflowChart) {
      const id = cashflowChart.getAttribute('id');
      const chart = window[id];
      var obj = new CashflowChart(chart);
      window[`${id}_obj`] = obj;
      obj.init();
    })
  }
}
