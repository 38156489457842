import { Controller } from "stimulus";
import Cropper from 'cropperjs';

export default class extends Controller {
  static targets = ["avatar", "fileInput"]
  static cropper = null
  static previewImage = null
  static fileInputHidden = null
  static typeImage = null

  previewAvatar(e) {
    const reader = new FileReader();
    const imgElement = e.target.parentElement.querySelector("img")
    reader.onload = () => {
      imgElement.src = reader.result;
    }

    reader.readAsDataURL(e.target.files[0]);
  }

  changeProfilePicture(e) {
    e.preventDefault();
    this.fileInputTarget.click();
  }

  openModalCropper(e){
    const image = document.querySelector("#image");

    this.previewImage = e.target.parentElement.querySelector("img")
    this.typeImage = e.target.parentElement.querySelector("img").dataset.type
    this.fileInputHidden =  e.target.parentElement.querySelector('input[type="hidden"]')
    const reader = new FileReader();

    let aspectRatio = 0
    switch (this.typeImage){
      case 'logo':
        aspectRatio = 1 / 1
        break;
      case 'header_brand_pdf':
        aspectRatio = 14 / 3
        break;
      default:
        aspectRatio = 63 / 16
        break;
    }

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      image.src = reader.result;
      const modal = document.querySelector("#modal-cropper")
      modal.classList.add("is-active")

      this.cropper = new Cropper(image, {
        dragMode: 'move',
        aspectRatio: aspectRatio,
        autoCropArea: 0.65,
        restore: false,
        guides: false,
        center: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: true,
        toggleDragModeOnDblclick: false,
      });
    }
  }

  closeModalCropper(){

    let maxHeight = 0
    switch (this.typeImage){
      case 'logo':
        maxHeight = 120
        break;
      case 'header_brand_pdf':
        maxHeight = 200
        break;
      default:
        maxHeight = 400
        break;
    }

    if (this.cropper) {

      let canvas = this.cropper.getCroppedCanvas({
        maxHeight: maxHeight
      });

      this.previewImage.src = canvas.toDataURL();
      this.fileInputHidden.value = canvas.toDataURL();
      const modal = document.querySelector("#modal-cropper")
      modal.classList.remove("is-active")

      this.cropper.destroy();
      this.cropper = null;
    }
  }

  cancelModalCropper(){
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
    const modal = document.querySelector("#modal-cropper")
    modal.classList.remove("is-active")
  }
}
