import { Controller } from 'stimulus';
import GaugeChart from '../charts/gauge-chart';

export default class extends Controller {
  static targets = ["score_chart"]

  connect() {
    this.init();
  }

  async init(){
    await this.initGaugeCharts();
  }

  initGaugeCharts() {
    return new Promise( (resolve, reject) => {
      const GaugeCharts = document.querySelectorAll('.gauge-charts')
      GaugeCharts.forEach(function(chartGauge) {

        const chartData = JSON.parse(chartGauge.dataset.chart);

        const chart = {
          id: chartGauge.getAttribute('id'),
          data: chartData
        }
        
        var obj = new GaugeChart(chart, 350, 850);
        console.log(obj)
        resolve(obj.init());
      });
    }); 
  }

}
