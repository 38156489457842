import { Controller } from "stimulus"
import CreditRepairProvidersChart from './../charts/credit-repair-providers-chart'

export default class extends Controller {
  connect() {
    // Credit Repair Providers Chart
    const creditRepairProvidersCharts = document.querySelectorAll('.credit-repair-providers-chart')
    creditRepairProvidersCharts.forEach(function (creditRepairProvidersChart) {
      const id = creditRepairProvidersChart.getAttribute('id')
      const chart = window[id]
      const obj = new CreditRepairProvidersChart(chart)
      obj.init()
    })
  }
}