import { Controller } from "stimulus"
import SeasonalityChart from './../charts/seasonality-chart'

export default class extends Controller {
  connect() {
    // Seasonality Chart
    const seasonalityCharts = document.querySelectorAll('.seasonality-chart')
    seasonalityCharts.forEach(function (seasonalityChart) {
      const id = seasonalityChart.getAttribute('id')
      const chart = window[id]
      const obj = new SeasonalityChart(chart)
      obj.init()
    })
  }
}