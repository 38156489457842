import { Controller } from "stimulus";
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "loadingTagsView",
    "newTagsView",
    "currentTagsView",
    "formTagsView",
    "currentTagsInput",
    "editTagsInput"
  ]

  connect(){
    this.initializeInputTags();
  }

  initializeInputTags(){
    this.instanceBulma = new BulmaTagsInput(this.editTagsInputTarget, {
      maxTags: 10,
      maxChars: 20,
      minChars: 2,
      caseSensitive: false,
      closeDropdownOnItemSelect: false,
      highlightDuplicate: true,
      noResultsLabel: 'Presiona Enter para agregar nueva etiqueta',
    })

    this.instanceBulma.container.querySelector('[role="menu"]').classList.add('detail-view-tags')
  }

  saveTags(e){
    e.preventDefault()
    let modal_view = this.formTagsViewTarget.dataset.modal_view === "true"
    e.target.classList.add("is-loading");
    e.target.disabled = true;
    if(!modal_view){
      this.showView("loadingTagsViewTarget");
    }

    Rails.fire(this.formTagsViewTarget, 'submit')
  }

  handleEnterKey(e){
    if (e.key === 'Enter') {
      e.preventDefault();

      this.instanceBulma.add([
        {"value": e.target.value,"text": e.target.value, "selected": true}
      ]);

      e.target.value = ""
    }
  }

  openForm(){
    this.showView("formTagsViewTarget");
  }

  closeForm(){
    let modal_view = this.formTagsViewTarget.dataset.modal_view === "true"

    if(modal_view) {
      var modal = document.querySelector("#modal-tags")
      modal.classList.toggle("is-active");
    } else {
      this.showView("loadingTagsViewTarget");
      const container = document.getElementById('tags-container');
      const event = new Event('refresh');
      container.dispatchEvent(event)
    }
  }

  showView(view){
    this.newTagsViewTarget.classList.add("is-hidden");
    this.currentTagsViewTarget.classList.add("is-hidden");
    this.formTagsViewTarget.classList.add("is-hidden");

    this[view].classList.remove("is-hidden");
  }

}
