import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

class TopFiveChart {
  constructor (chart) {
    this.id = chart.id
    this.data = chart.data
    this.series = chart.series
  }

  init () {
    this.chart = am4core.create(this.id, am4charts.XYChart)

    const categoryAxis = this.chart.xAxes.push(new am4charts.DateAxis())

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())

    const self = this
    this.series.forEach(function (serie, index) {
      self.createSeries(serie, index)
    })

    // Add legend
    this.chart.legend = new am4charts.Legend()

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor()
  }

  createSeries (name, index) {
    const series = this.chart.series.push(new am4charts.LineSeries())
    series.dataFields.valueY = 'amount'
    series.dataFields.dateX = 'date'
    series.strokeDasharray = 6
    series.name = name
    series.tooltipText = `${name}: {valueY}`
    series.data = this.data[index]
  }
}

export default TopFiveChart

