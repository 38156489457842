import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["password", "icon"];

  toggle() {
    const type = this.passwordTarget.getAttribute('type') === 'password' ? 'text' : 'password';
    this.passwordTarget.setAttribute('type', type);

    const isPassword = type === 'password';
    this.iconTarget.classList.toggle('fa-eye', isPassword);
    this.iconTarget.classList.toggle('fa-eye-slash', !isPassword);
  }
}

